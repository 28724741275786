angular.module('ghRadioButtonModule', [])

.directive('ghRadioButton', [function() {
    var directive = {};

    directive.scope = {
        ghModel: '=',
        ghOptions: '=',
        ghMode: '@',
        editable: '='
    };

    directive.controller = ['$scope', function($scope) {

        // if ($scope.ghModel == null) {
        //     $scope.ghModel = 0;
        // }
        $scope.changeValue = function(newVal) {
            if ($scope.editable) {
                $scope.ghModel = newVal.toString();
            }
        };

        $scope.isSelected = function(val) {
            return $scope.ghModel == val;
        };

    }];

    directive.template =
        '<ul class="gh-radio-button_contain">' +
        '   <li ng-repeat="item in ghOptions | radioButtonValue : ghModel : ghMode" ng-click="changeValue(item.value)" class="gh-radio-button-item" ng-class="{\'selected\': isSelected(item.value), \'with-icon\': item.icon}">' +
        '     <div class="gh-button gh-button-inversed gh-color-grey gh-radio-button-item"  ng-style="isSelected(item.value) && !item.icon && {\'background-color\':item.color, \'border-color\':item.color, \'color\': \'white\', \'font-weight\': \'500\'} || { \'color\': item.color}">{{item.name}}</div>' +
        '   </li>' +
        '</ul>';

    return directive;
}])

.filter('radioButtonValue', function() {
    return function(options, currentValue, interpreterType) {
        if (options.length && interpreterType == 'current_value') {
            return options.filter(function(opt) {
                return Number(opt.value) == currentValue;
            });
        } else {
            return options;
        }
    };
});