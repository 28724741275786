/******************************************************************************************************/
/**********************************    GH-BUTTON CONTROL    *******************************************/
/******************************************************************************************************/

/*  This is main control for entering data, also it used as a part for other controls like:*/

/*   <gh-button autocomplete="fieldsList 23" gh-dropdown="" callback="filter.addFilter(value)" size="large"/>*/
/*   - autocomplete*/
/*   - gh-dropdown=""*/
/*   - callback*/

/****************************************************************************************************/

angular.module('ghButtonModule', [])

.directive('ghButton', ['$compile', function($compile) {
	var directive = {};

	directive.scope = {
    options: '=ghDropdown',
    callback: '&'
  };

	directive.controller = [ '$scope', '$element', '$attrs', function($scope, $element, $attrs) {
		$scope.decoratedFieldModel = {
      data_model: {
        options:  $scope.options
      },
      data_type: 'text_opt'
    };

		if (angular.isUndefined($scope.options)) {
      $scope.options = [];
    }

    $scope.hidden = true;
	}];

	directive.template = function(tElement, tAttrs) {

		var template =
			'<div class="btn-group gh-dropdown" style="clear: both; display: block">' +
			'	<div ng-click="hidden = !hidden" class="gh-btn inline gh-btn-bg gh-btn-blue">Add Filter' +
			'		<span gh-icon="bot_arrow ffffff 18px normal"></span>' +
			'	</div>' +
			'	<ul class="display-block" ng-show="!hidden">' +
			'		<li ng-click="callback({value:option}); $parent.hidden = true" ng-repeat="option in options track by $index">{{option.name}}</li>' +
			'	</ul>' +
			'</div>';
		return template;
	};

	directive.link = function(scope, iElement, iAttrs) {
		if (angular.isDefined(iAttrs.autocomplete)) {
      iAttrs.$observe('autocomplete', function() {
        $compile('<gh-input ng-model="temporary" gh-dropdown="options" autocomplete="' + iAttrs.autocomplete +'" gh-data-type="text_opt"></gh-input>')(scope);
			});
    }
	};
	return directive;
}]);
