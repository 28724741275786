/**
 *  PROPERTIES LIST:
 *  'private' - Container display in containers list
 *  'template_name' - Name container in containers list
 *  'allowed_types' - Indicates which containers can be dragged into this container, it use 'template_id' as 'allowed_types'
 *
 *  'model' - Must have in 'views_list'
 *      'template_id' - Template ID for block identification in 'views_list'
 *      'name' - Use for title container
 *      'icon_id' - Use for icon container
 *      'class' - CSS class for container
 *      'priority' - Use for order container in parent containers
 *      'content' - This children list current container
 *
 *  'container_settings' - Settings for edit container
 *      'name' - Name button
 *      'icon' - Icon button
 *      'function' - Name function that use, when click on button
 *      'arg' - Argument that use with function
 *      'show_if' - Show if container has css_class
 *      'hide_if' - Hide if container has css_class
 *
 *  'html_editor' - HTML template for edit
 *  'html_form' - HTML template for form
 *  'icon' - SVG icon container in containers list
 **/

//==============
import * as Element from './views/element.js';
import * as Flex from './views/flex.js';
import * as Float from './views/float.js';
import * as Tabs from './views/tabs.js';

let Templates = [Element, Flex, Float, Tabs];
//==============

angular.module('GhTemplateModule', [])

.directive("ghView", ['$animate', '$compile', '$filter', function($animate, $compile, $filter) {
    let templatePatternDefault = `{
        'allowed_types': ['gh_float'],
        'template_id': 'gh_flex',
        'name': '',
        'icon_id': '',
        'class': 'gh_flex gh_col_height_0 gh_height_percent vertical_scroll',
        'container_id': 0,
        'priority': 0,
        'content': [{
            'allowed_types': ['gh_float', 'gh_element', 'gh_tabs'],
            'template_id': 'gh_float',
            'name': '',
            'icon_id': '',
            'class': 'gh_float gh_col_width_12 gh_col_height_0 gh_height_percent gh_width_percent vertical_scroll',
            'container_id': 0,
            'priority': 0,
            'content': []
        }]
    }`;

    let templateForEditor =
        `<div class="ghViewContainer ghEditor" ng-repeat="list in views = [model]">
            <div class="ghViewNavigation">
                <input class="ghViewNameInput" type="text" ng-model="list.name" placeholder="View name">
                <span class="ghViewAdd" gh-icon="plus_in_circle 86BEF6 26px normal" ng-click="list.content.unshift(${ templatePatternDefault })"></span>
            </div>

            <ul class="ghViewContainerList" ng-init="lists = list.content"
            dnd-list="lists"
            dnd-allowed-types="['gh_flex']"
            dnd-drop="dropElement(index, item, external, type, lists)" dnd-inserted="insertedElement()">

                <li class="ghViewContainerListItem" ng-class="item.class" ng-repeat="item in lists | orderBy:'priority'"
                dnd-draggable="item"
                dnd-effect-allowed="move"
                dnd-moved="$parent.lists.splice($index, 1)"
                dnd-type="item.template_id"
                ng-include="item.template_id + '_editor'"></li>
            </ul>
        </div>`;

    let directive = {
        template: function(element, attrs) {
            return attrs.editMode == "true" ? templateForEditor : '<div class="ghViewContainer ghForm"><gh-loader type="dots" color="blue"></gh-loader></div>';
        },
        replace: false,
        restrict: 'E',
        controller: controller,
        scope: {
            appId: '@',
            viewId: '@',
            itemId: '@',
            decorator: '=?',
            fields: '=', // Need for add_item_action
            editMode: '@',
            srcForElement: '@'
        },
        link: {
            post: function(scope, element) {
                let newScope = scope.$new();

                function buildTemplate(arr) {
                    let temp = '';
                    let sort = $filter('orderBy')(arr, 'priority');

                    angular.forEach(sort, function(value) {
                        if (value['template_id'] == 'gh_element') {
                            temp += `<gh-element app-id="${ scope.appId || '' }" item-id="${ scope.itemId || '' }" field-id="${ value.element_id || '' }" ${ scope.fields ? 'value="fields[' + value.element_id + ']"' : '' } container-id="${ value.container_id }" elem-src="${ scope.srcForElement }"></gh-element>`;
                        } else {
                            angular.forEach(scope.templates, function(template) {
                                if (template.default.model.template_id == value['template_id']) temp += template.default.html_form(value, buildTemplate);
                            });
                        }
                    });

                    return temp;
                }

                scope.rendering = function(newVal) {
                    if (newVal && newVal['content'] && newVal['content'].length > 0) {

                        newScope.$destroy();
                        angular.element(element[0].querySelector(".ghViewContainer.ghForm")).empty();

                        let sort = $filter('orderBy')(newVal['content'], 'priority');

                        angular.forEach(sort, function(value) {

                            angular.forEach(scope.templates, function(template) {

                                if (template.default.model.template_id == value['template_id']) {
                                    let template_string = template.default.html_form(value, buildTemplate);
                                    let linkFn = $compile(template_string);

                                    newScope = scope.$new(false);
                                    angular.element(element[0].querySelector(".ghViewContainer.ghForm")).append(linkFn(newScope));

                                }

                            });

                        });
                    }
                };
            }
        }
    };

    controller.$inject = ['$scope', 'formEditorService', 'GhDialog', '$element', 'PipeService', 'viewTemplatesStorage'];

    function controller($scope, formEditorService, GhDialog, $element, PipeService, viewTemplatesStorage) {
        if (!$scope.srcForElement) { $scope.srcForElement = 'form'; }

        $scope.templates = viewTemplatesStorage.getContainersTemplates(); /** Templates get for compile views in mode "editMode = true" */

        $animate.enabled(false, $element); /** Disable animation */

        $scope.setIcon = function(item) {
            GhDialog.show({
                position: 'bottom',
                template: {
                    content: '<div class="gh-ui-select-icon-list">' +
                        '<div class="gh-ui-select-icon" ng-click="update(icon.id)" ng-repeat="icon in iconList" gh-icon="{{icon.id}} a0a7ad 90% normal" ng-class="{\'--selected\': icon.id == currentIcon}"></div>' +
                        '</div>'
                },
                locals: {
                    currentIcon: item.icon_id
                },
                controller: ['$scope', 'iconsStorage', 'currentIcon', function($scope, iconsStorage, currentIcon) {
                    /** Current selected icon */
                    $scope.currentIcon = currentIcon;
                    $scope.iconList = iconsStorage.getIconsList('000000');

                    $scope.update = function(iconId) {
                        $scope.hide(iconId);
                    };
                }]
            }).then(function(data) {
                item.icon_id = data || item.icon_id;
            });
        };

        //-----------------------------------------------

        $scope.model = {};

        function update(newVal, int) {
            $scope.model = getView(newVal);

            if (!$scope.editMode) {
                console.log('---> NEW RENDERING! --- (' + int + ')');
                $scope.rendering($scope.model);
            }
        }

        function getView(data) {
            let view = {};

            if ($scope.viewId) {
                angular.forEach(data, function(value) {
                    if (value.view_id == $scope.viewId) view = value;
                });
            } else {
                view = data[0];
            }

            return view;
        }

        let address = {
            app_id: $scope.appId
        };

        if ($scope.appId) {
            PipeService.on('gh_app_views_get', address, function viewsPipe(event, data) {
                PipeService.destroy('gh_app_views_get', address, viewsPipe);

                update(data, 1);
            }).emit('gh_app_views_get', {}, address);

            PipeService.on('gh_app_views_update', address, viewsUpdatePipe);
        }

        function viewsUpdatePipe(event, data) {
            update(data, 2);
        }

        $scope.$on('$destroy', function() {
            console.log('DESTROY');
            PipeService.destroy('gh_app_views_update', address, viewsUpdatePipe);
        });

        $scope.$watch('decorator', function(newDecorator) {
            if (newDecorator) {
                $scope.model = newDecorator;
            }
        }, true);

        //-----------------------------------------------

        /** Return model for gh_element decorator from existing fields */
        $scope.saveModelElement = {};
        $scope.getElementModelT = function(element_id) {
            if (element_id) {
                angular.copy(formEditorService.getElementModel(element_id), $scope.saveModelElement);

                return $scope.saveModelElement;
            }
        };

        /** Add priority all container elements, when element drop on "views_list" */
        $scope.dropElement = function(index, item, external, type, lists) {
            formEditorService.dropElement();

            return item;
        };

        $scope.insertedElement = function() {
            formEditorService.AddAllowedTypes($scope.model);
        };
    }

    return directive;
}])





/*======================================================================================================|
|======================================   FIELD TEMPLATES STORAGE   ====================================|
|=======================================================================================================|
|-- here we store all fields templates
*/
.service('viewTemplatesStorage', ['GHConstructor', '$q', 'storage', '$templateCache', function(GHConstructor, $q, storage, $templateCache) {



    /* ------------------------------- LOAD TEMPLATES AND CACHE HTML ------------------------------- */
    /*-- Here we load and cache containers "Templates" for gh-view */
    (function loadTemplates() {
        angular.forEach(Templates, function(value) {
            $templateCache.put(value.default.model.template_id + '_editor', value.default.html_editor);
        });
    }());

    /*-- Return "Templates" list */
    this.getContainersTemplates = function() {
        return Templates;
    };
}]);