/**********************************************************************************************/
/************************************   GH TOAST    *******************************************/
/**********************************************************************************************/
// This module need for show info/error toasts messages for user
/**********************************************************************************************/

angular.module('ghToastModule', [])

.service('ghToastService', ['$mdToast', function($mdToast){

  this.simple = function(text){

    // If not text - not showing toast
    if(!text) return;

    var toast = $mdToast
      .simple()
      .textContent(text);

    return show(toast);
  };

  // Function to showing toast. Returning promise after close.
  // A promise that can be resolved with $mdToast.hide() or rejected with $mdToast.cancel().
  function show(toast){
    return $mdToast.show(toast);
  }

  return this;
}]);
