angular.module('ghJsonSchemeCompilerModule', [])
.factory('ghJsonSchemeCompilerService', ['PipeService', 'filterPreparationFactory', 'advancedFiltering', function(PipeService, filterPreparationFactory, advancedFiltering) {
	
	const compiler = function () {
		this.initSchemeReader = async function (scheme) {
			return await schemeCompiler(scheme);
		};

		async function schemeCompiler(scheme, item, app) {
			let value = null;

			if (scheme.type === 'array') { // get properties value for array childs for each application item
				if (Number(scheme.is_static)) {
					value = new Array(await getChildsPropertiesObject(scheme.childs, item, app));
				} else {
					value = await getArrayOfItemsWithProperties(scheme, item, app);
				}
			}

			if (scheme.type === 'object') { // get properties value for object childs
				value = await getChildsPropertiesObject(scheme.childs, item, app);
			};

			if (scheme.type === 'property') { // get properties value
				value = await getFieldValue(scheme, item, app);
			} 

			return { [scheme.property_name] : value }
		}

		/* Get properties value for array childs in scheme, for each application filtered item. */
		async function getArrayOfItemsWithProperties(scheme, item, recievedApp) {
			const app = await getApp(Number(scheme.app_id));
			const filteredItems = await filterItems(angular.copy(scheme.filter), app.items_list, recievedApp, item);
			const arrayOfItemsWithProperties = await filteredItems.map(async (item) => {
				const propertiesObject = await getChildsPropertiesObject(scheme.childs, item, app);
				return propertiesObject;
			});
			const resolvedArrayOfItemsWithProperties = await Promise.all(arrayOfItemsWithProperties);
			return resolvedArrayOfItemsWithProperties;
		};

		/* Get properties value for object childs in scheme. */
		async function getChildsPropertiesObject(properties, item, app) {
			const propertiesArray = await properties.map( async (child) => {
				const propertyObject = await schemeCompiler(child, item, app);
				return propertyObject;
			});
			const resolvedPropertiesArray = await Promise.all(propertiesArray);
			const propertiesObject = resolvedPropertiesArray.reduce((acc, object) => {
				return { ...acc, ...object };
			}, {});
			return propertiesObject;
		} 

		function getApp (appId) {
			return new Promise(resolve => {
				PipeService.on('gh_app_get', { app_id : appId }, function getApp(event, app) {
					PipeService.destroy('gh_app_get', { app_id : appId }, getApp);
					resolve(app);
				})
				.emit('gh_app_get', {}, { app_id : appId });
			})
		};
			
		/* Get property value based on interpretation value */
		function getFieldValue (scheme, item, app) {
			return new Promise((resolve, reject) => {
				if(Number(scheme.is_static)) {
					resolve(scheme.static_field_value);
				}

				let options = {
					app_id : Number(app.app_id),
					item_id : Number(item.item_id),
					field_id : Number(scheme.field_id),
				};
				if(Boolean(Number(scheme.interpretation))) {
					PipeService.on('gh_interpreted_value_get', options, function getInterpretedValue (event, interpretedFieldValue) {
						PipeService.destroy('gh_interpreted_value_get', options, getInterpretedValue);
						resolve(interpretedFieldValue);
						reject(new Error('Field value is missing'));
					})
					.emit('gh_interpreted_value_get', {}, options);
				} else {
					PipeService.on('gh_value_get', options, function getValue (event, fieldValue) {
						PipeService.destroy('gh_value_get', options, getValue);
						resolve(fieldValue);
						reject(new Error('Field value is missing'));
					})
					.emit('gh_value_get', {}, options);
				};
			});
		};

		/* Filter items by scheme filter */
		async function filterItems (filter = [], itemsList = [], app, item) {
			let appId = app ? Number(app.app_id) : null;
			let itemId = item ? Number(item.item_id) : null;
			let filteredItemsList = [ ...itemsList ];
			if(filter.length > 0) {
				const modifiedFiltersList = await filterPreparationFactory.filterPreparation([ ...filter ], appId, appId, itemId);
				filteredItemsList =	await advancedFiltering(itemsList, [...(modifiedFiltersList || [])]);
			} 
			return filteredItemsList;
		};
	};
	return compiler;
}])

