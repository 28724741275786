require("./gh_loader.scss");

angular
  .module("loaderModule", [])

  // COLOR LOADER
  // $blue: #0893d2;
  // $light-blue: #EDF2F6;
  // $sky-blue: #ddf0f9;
  // $green: #10cfbd;
  // $red: #FF3333;
  // $pure-white: #fff;
  // $white: #fafdfd;
  // $black: #575757;
  // $light-grey: #e7e7ee;
  // $grey: #d9d9d9;
  // $dark-grey: #bbbcc5;

  .directive("ghLoader", [
    "$compile",
    function($compile) {
      return {
        replace: false,
        restrict: "E",
        scope: {
          color: "@",
          type: "@?"
        },

        link: function(scope, element, attrs) {
          let template;
          switch (scope.type) {
            case "dots":
              template = `<div class="wrap_loader_dots">
                            <div class="loading">
                              <div class="bounceball {{color}} one"></div>
                              <div class="bounceball {{color}} two"></div>
                              <div class="bounceball {{color}} three"></div>
                            </div>
                          </div>`;
              break;
            
            case "dual-ring":
                template = '<div class="lds-dual-ring {{color}}"></div>';
              break;

            default:
              template = '<div class="lds-dual-ring {{color}}"></div>';
          }

          const elm = angular.element(template);
          const compiled = $compile(elm);
          element.empty();
          element.append(elm);
          compiled(scope);
        }
      };
    }
  ]);
