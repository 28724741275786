angular.module('homePageMod', [
])



/*=======================================================================================================*/
/*==============================   APPLICATIONS MANAGER CONTROLLER   ====================================*/
/*=======================================================================================================*/
/*--------------------- APPS LIST CONTROLLER --------------------                                */
.controller('HomePageCtrl', ['$location', '$scope', 'cnfg', '$templateCache', '$routeParams', function($location, $scope, cnfg, $templateCache, $routeParams) {
	$scope.changePath = function(path){
		$location.path(path);
	}
	$routeParams.industry ? $scope.industry = $routeParams.industry : $scope.industry = 'wally_fabricator';
}])


/*==========================================================================================================*/
/*=======================================    Wally DIRECTIVE   =============================================*/
/*==========================================================================================================*/
.directive('ghWally', ['$templateCache', '$compile', function($templateCache, $compile, $interval) {
	return function(scope, elem, attr) {
		// var loaderTemplate = '<div loader-file ></div>';
		var loaderTemplate = '<div></div>';

		/*----------  wally Rendering ------------*/
		var wallyRendering = function( template  ) {
		/*-- rendering to html*/
		var el = angular.element( template );
		var compiled = $compile(el);
		// elem.empty();
		elem.prepend(el);
			scope.hideLoader = true;
		compiled(scope);
		};

		var checkTemplate = function( req ){
		if( $templateCache.get(  attr.scene  + '.html' ) ){
		var template = $templateCache.get(attr.scene + '.html');
		wallyRendering( template );
			clearInterval(stop);
		} else {
			wallyRendering( loaderTemplate );
			stop = setInterval(checkTemplate.bind(null, attr.scene), 1000);
		}
		};
		checkTemplate(attr.scene);
	};

}]);
