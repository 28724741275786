import Rete from "rete";
export class MainControl extends Rete.Control {
  constructor(emitter, key, readonly, node, settings, template) {
    super(key);
    this.render = "js";
    this.key = key;
    this.node = node;
    this.settings = settings;
    this.template = template;
  }

  handler(el, editor, $scope, $compile) {
    let dataLink =  "ghModel.nodes[\'" + this.node.id + "\'].data";
    let settingsLink =  "settingsModels[\'" + this.node.id + "\']";


    //-- Creating Settings Options In a Scope --//
    //-- Each Node have their own settings options model.
    $scope.settingsModels[this.node.id] = this.settings;



    //-- Container for controls
    var conteiner = document.createElement("div");
    conteiner.className = "gh-node-content";
    el.appendChild(conteiner);



    //-- Controls (Icon with edit button )
    let elem = angular.element(`<gh-element elem-src="form" elem-src="form"
                                                    decorator="{data_model: {appId: appId, elementId: elementId, settings: ${settingsLink}, color: 'fff'}, data_type: 'additional_settings'}"
                                                    value="${dataLink}"/>
                                <span class="gh-delete-node" ng-click="deleteNode(${this.node.id})" gh-icon="rubbish ffffff 24px normal"></span>
                                <span class="automation-settings-icon" gh-icon="${this.template.icon} ffffff 84px normal"></span>`
              );


    //-- appending angular element
    setTimeout(() => {  // using timeout because datalink no available in time
      let compiled = $compile(elem);
      angular.element(conteiner).append(elem);
      compiled($scope);
    }, 0)
  }
}