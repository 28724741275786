/********************************************************************************************************/
/***********************************   GH INFOGRAPHIC COUNTER    ****************************************/
/********************************************************************************************************/


angular.module('ghInfographicCounterModule', [
  'pipeModule',
  'ghConstructor',
  'tableFilter'
])

.directive('ghInfographicCounter', ['$compile', '$filter', 'advancedFiltering', 'ghInfographicCounterService', 'PipeService', function ($compile, $filter, advancedFiltering, ghInfographicCounterService, PipeService) {

  return {
    restrict: 'E',
    scope:{
      ghAppId: '@',
      ghFieldModel: '=',
      itemsToCount: '=' // If set items to 'itemsToCount', we will get they to filtering
    },
    controller: ['$scope', '$element', function($scope, $element){

      var appId = $scope.ghFieldModel.app_id || $scope.ghAppId;

      if(!appId || !$scope.ghFieldModel){
        return;
      }

      // Counter value
      $scope.value = {
        total: 0,
        current: 0
      };
      $scope.Math = Math;


      function setValue(items) {
        advancedFiltering(items, $scope.ghFieldModel.data_model.filters_list).then(function(res) {
          angular.extend($scope.value, {
            total: items.length,
            current: (res || []).length
          });

          var template =
            '<p class="graphic-name">{{ghFieldModel.field_name}}</p>' +
            ghInfographicCounterService.getTemplate($scope.ghFieldModel.data_model.graphic_type);
          $element.append($compile(template)($scope));
        });
      }

      if($scope.itemsToCount && $scope.itemsToCount.length){
        setValue($scope.itemsToCount);
      } else {
        PipeService.on('gh_items_get', { app_id: appId }, function itemsPipe(event, itemsList){
          PipeService.destroy('gh_items_get', { app_id: appId }, itemsPipe);
          setValue(itemsList);
        }, $scope).emit('gh_items_get', {}, { app_id: appId });
      }

    }],
    link: function($scope){}
  };

}])

.service('ghInfographicCounterService', [function () {

  this.getTemplate = function (templateType) {
    return  this.template[templateType] || '<span></span>';
  };

  this.template = {
    circle:
      '<svg id="svg" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">'+
      ' <circle r="35" cx="50" cy="50" fill="transparent" stroke-dasharray="219.911" stroke-dashoffset="0"></circle>'+
      ' <circle id="bar" r="35" cx="50" cy="50" fill="transparent" stroke-dasharray="219.911" stroke-dashoffset="0" '+
      '   ng-style="{\'stroke-dashoffset\': (1-(+value.current)/(+value.total)) * Math.PI*(35*2) + \'px\'}"></circle>'+
      '<text fill="#000000" stroke-width="0" text-anchor="middle" x="50" y="50" stroke-miterlimit="10" style="font-size:25px;">{{value.current}}</text>'+
      '<text fill="#000000" stroke-width="0" text-anchor="middle" x="50" y="65" stroke-miterlimit="10" style="font-size:13px;">slabs</text>'+
      '</svg>',

    slabs:
      '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" ' +
      'style="enable-background:new 0 0 512 512;" xml:space="preserve">' +
      '<g id="slab">' +
        '	<polygon fill="#A8B6BA" points="65.6,185.5 95,180.5 112.3,172.8 132.1,171.8 144.8,163.9 175.6,153 186.2,156.6 230.4,155.1 ' +
        '		246.4,148 254.9,150.4 276.6,142.9 288.4,138.7 301.3,138.7 316.9,138.4 330.1,133.8 338.9,135.9 352.9,130 371.9,131.1 ' +
        '		390.3,127.2 401,121.8 412.5,125.4 416.6,133.2 418.4,145 415.6,150.9 419.4,157 417.9,172.2 419.2,189.2 420.4,216.2 413.9,239 ' +
        '		415.1,270.2 411.3,293.7 414.5,311.5 414.5,317.2 405.9,318.6 400.9,325.8 381.1,332.9 359.2,334.1 327.3,347.7 306.4,346.7 ' +
        '		262.8,353.5 241.9,362.6 221,366.1 199.1,361.7 190.2,367.1 167.7,371.1 142.1,380.5 121,382.3 95.8,379.5 82.3,384.4 68.1,381.4 ' +
        '		60,367.5 69.7,336.5 69.9,306.2 72,300.8 66,298.1 67.6,289.9 64.2,274.7 62.8,267.3 66.8,255.3 60.8,241.9 67.4,236.6 63.1,230 ' +
        '		61.1,201.8 	"/>' +
        '	<polygon fill="#D9E0E3" points="71.5,188.6 100.9,183.6 118.2,175.8 138,174.9 150.6,167 181.4,156.1 192.1,159.7 236.3,158.1 ' +
        '		252.3,151 260.7,153.4 282.5,145.9 294.3,141.8 307.2,141.7 322.8,141.5 336,136.9 344.8,138.9 358.8,133 377.7,134.2 396.2,130.2 ' +
        '		406.9,124.9 418.4,128.5 422.5,136.2 424.3,148.1 421.5,153.9 425.3,160.1 423.8,175.3 425.1,192.3 426.3,219.2 419.8,242.1 ' +
        '		420.9,273.2 417.2,296.7 420.4,314.6 420.4,320.2 411.8,321.7 406.8,328.9 387,336 365.1,337.2 333.2,350.8 312.3,349.8 ' +
        '		268.7,356.5 247.7,365.6 226.9,369.2 204.9,364.8 196.1,370.1 173.6,374.2 148,383.5 126.9,385.4 101.7,382.6 88.2,387.4 74,384.5 ' +
        '		65.9,370.6 75.6,339.6 75.8,309.3 77.9,303.8 71.8,301.2 73.5,293 70,277.8 68.7,270.4 72.6,258.4 66.7,245 73.3,239.6 69,233 ' +
        '		67,204.8 	"/>' +
        '</g>' +
        '<g id="slab_2_">' +
        '	<polygon fill="#A8B6BA" points="84.6,191.5 114,186.5 131.3,178.8 151.1,177.8 163.8,169.9 194.6,159 205.2,162.6 249.4,161.1 ' +
        '		265.4,154 273.9,156.4 295.6,148.9 307.4,144.7 320.3,144.7 335.9,144.4 349.1,139.8 357.9,141.9 371.9,136 390.9,137.1 ' +
        '		409.3,133.2 420,127.8 431.5,131.4 435.6,139.2 437.4,151 434.6,156.9 438.4,163 436.9,178.2 438.2,195.2 439.4,222.2 432.9,245 ' +
        '		434.1,276.2 430.3,299.7 433.5,317.5 433.5,323.2 424.9,324.6 419.9,331.8 400.1,338.9 378.2,340.1 346.3,353.7 325.4,352.7 ' +
        '		281.8,359.5 260.9,368.6 240,372.1 218.1,367.7 209.2,373.1 186.7,377.1 161.1,386.5 140,388.3 114.8,385.5 101.3,390.4 ' +
        '		87.1,387.4 79,373.5 88.7,342.5 88.9,312.2 91,306.8 85,304.1 86.6,295.9 83.2,280.7 81.8,273.3 85.8,261.3 79.8,247.9 86.4,242.6 ' +
        '		82.1,236 80.1,207.8 	"/>' +
        '	<polygon fill="#D9E0E3" points="90.5,194.6 119.9,189.6 137.2,181.8 157,180.9 169.6,173 200.4,162.1 211.1,165.7 255.3,164.1 ' +
        '		271.3,157 279.7,159.4 301.5,151.9 313.3,147.8 326.2,147.7 341.8,147.5 355,142.9 363.8,144.9 377.8,139 396.7,140.2 415.2,136.2 ' +
        '		425.9,130.9 437.4,134.5 441.5,142.2 443.3,154.1 440.5,159.9 444.3,166.1 442.8,181.3 444.1,198.3 445.3,225.2 438.8,248.1 ' +
        '		439.9,279.2 436.2,302.7 439.4,320.6 439.4,326.2 430.8,327.7 425.8,334.9 406,342 384.1,343.2 352.2,356.8 331.3,355.8 ' +
        '		287.7,362.5 266.7,371.6 245.9,375.2 223.9,370.8 215.1,376.1 192.6,380.2 167,389.5 145.9,391.4 120.7,388.6 107.2,393.4 ' +
        '		93,390.5 84.9,376.6 94.6,345.6 94.8,315.3 96.9,309.8 90.8,307.2 92.5,299 89,283.8 87.7,276.4 91.6,264.4 85.7,251 92.3,245.6 ' +
        '		88,239 86,210.8 	"/>' +
        '</g>' +
        '<g id="slab_1_">' +
        '	<polygon fill="#A8B6BA" points="96.6,207.5 126,202.5 143.3,194.8 163.1,193.8 175.8,185.9 206.6,175 217.2,178.6 261.4,177.1 ' +
        '		277.4,170 285.9,172.4 307.6,164.9 319.4,160.7 332.3,160.7 347.9,160.4 361.1,155.8 369.9,157.9 383.9,152 402.9,153.1 ' +
        '		421.3,149.2 432,143.8 443.5,147.4 447.6,155.2 449.4,167 446.6,172.9 450.4,179 448.9,194.2 450.2,211.2 451.4,238.2 444.9,261 ' +
        '		446.1,292.2 442.3,315.7 445.5,333.5 445.5,339.2 436.9,340.6 431.9,347.8 412.1,354.9 390.2,356.1 358.3,369.7 337.4,368.7 ' +
        '		293.8,375.5 272.9,384.6 252,388.1 230.1,383.7 221.2,389.1 198.7,393.1 173.1,402.5 152,404.3 126.8,401.5 113.3,406.4 ' +
        '		99.1,403.4 91,389.5 100.7,358.5 100.9,328.2 103,322.8 97,320.1 98.6,311.9 95.2,296.7 93.8,289.3 97.8,277.3 91.8,263.9 ' +
        '		98.4,258.6 94.1,252 92.1,223.8 	"/>' +
        '	<polygon fill="#D9E0E3" points="102.5,210.6 131.9,205.6 149.2,197.8 169,196.9 181.6,189 212.4,178.1 223.1,181.7 267.3,180.1 ' +
        '		283.3,173 291.7,175.4 313.5,167.9 325.3,163.8 338.2,163.7 353.8,163.5 367,158.9 375.8,160.9 389.8,155 408.7,156.2 427.2,152.2 ' +
        '		437.9,146.9 449.4,150.5 453.5,158.2 455.3,170.1 452.5,175.9 456.3,182.1 454.8,197.3 456.1,214.3 457.3,241.2 450.8,264.1 ' +
        '		451.9,295.2 448.2,318.7 451.4,336.6 451.4,342.2 442.8,343.7 437.8,350.9 418,358 396.1,359.2 364.2,372.8 343.3,371.8 ' +
        '		299.7,378.5 278.7,387.6 257.9,391.2 235.9,386.8 227.1,392.1 204.6,396.2 179,405.5 157.9,407.4 132.7,404.6 119.2,409.4 ' +
        '		105,406.5 96.9,392.6 106.6,361.6 106.8,331.3 108.9,325.8 102.8,323.2 104.5,315 101,299.8 99.7,292.4 103.6,280.4 97.7,267 ' +
        '		104.3,261.6 100,255 98,226.8 	"/>' +
        '</g>' +
        '<text fill="#FFFFFF" stroke="#A8B6BA" stroke-width="3" text-anchor="middle" x="280" y="336" stroke-miterlimit="10" style="font-size:154.1239px;font-weight:600">{{value.current}}</text>'+
      '</svg>'
  };

  return this;
}]);



