
angular.module('ghDurationModule', [])

.directive('ghDurationInput', [function() {
  return {
    replace: false,
    restrict: 'E',
    scope: {
      type: '@',
      step: '@',
      model: '='
    },

    controller: ['$scope', function ($scope) {
      $scope.$watch('model', function (newValue) {
        $scope.value = newValue;
      });

      $scope.$watch('value', function (newValue) {
        if(newValue || newValue == 0){
          switch ($scope.type) {
            case "hours":
              if(newValue >= 100){
                $scope.value = 0;
              } else if(newValue < 0){
                $scope.value = 100 - Number($scope.step);
              }
              break;
            case "minutes":
              if(newValue >= 60){
                $scope.value = 0;
              } else if(newValue < 0){
                $scope.value = 60 - Number($scope.step);
              }
              break;
          }
          $scope.model = $scope.value;
        }
      });

      $scope.changeValue = function (step){
        $scope.value = Number($scope.value) + Number(step);
      };

      $scope.checkSymbol = function (model) {
        $scope.model = model.replace(/[^0-9]/g, 0);
      };
    }],

    template:
    '<div>'+
      '<a ng-click="changeValue(step)"><div><svg viewBox="0 0 512 512" height="25px" width="25px" y="0px" x="0px" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" version="1.1"><polygon fill="none" stroke="#0791d0" stroke-width="20" stroke-miterlimit="4" points="256,159.3 77.5,343.2 87.3,352.7 256,178.9 424.7,352.7 434.5,343.2"></polygon></svg></div></a>'+
      '<input class="durationInput" ng-model="model" ng-change="checkSymbol(model)" type="text" maxlength="2">'+
      '<a ng-click="changeValue(-step)"><div style="transform: rotate(180deg)"><svg viewBox="0 0 512 512" height="25px" width="25px" y="0px" x="0px" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" version="1.1"><polygon fill="none" stroke="#0791d0" stroke-width="20" stroke-miterlimit="4" points="256,159.3 77.5,343.2 87.3,352.7 256,178.9 424.7,352.7 434.5,343.2 "></polygon></svg></div></a>'+
    '</div>'
  };
}])

.directive('ghDuration', ['$document', function($document){
  return {

    scope: {
      ghModel: '=',
      editable: '='
    },

    controller: ['$scope', function($scope){
      $scope.position = {
        'top': '0px',
        'left': '0px'
      };

      //-----------------------------
      $scope.arr = [0, 0];

      $scope.$watch('ghModel', function (newValue) {
        $scope.selectShow = newValue > 0;

        if(newValue){
          var hours = Math.floor(newValue / 3600000);
          var hours_ms = hours * 3600000;
          var minutes_ms = newValue - hours_ms;
          var minutes = minutes_ms / 60000;

          $scope.arr = [hours, minutes];
        }
      });

      $scope.clear = function () {
        $scope.arr = [0, 0];
        $scope.save();
      };

      $scope.close = function (event) {
        $scope.save();
        $scope.durationVisible = false;
        event.stopPropagation();
      };

      $scope.save = function() {
        var hours = $scope.arr[0] * 3600000;
        var minutes = $scope.arr[1] * 60000;

        $scope.ghModel = hours + minutes;
      }
    }],

    link: function(scope, iElement) {
      scope.durationVisibleOpen = function (event) {
        // DO TO change number to find element height
        let height_element = 222;
        let height_window = window.innerHeight;

        if(event.clientY + height_element < height_window){
          scope.position.top = event.clientY + "px";
          scope.position.left = event.clientX + "px";
        } else {
          scope.position.top = height_window - height_element + "px";
          scope.position.left = event.clientX + "px";
        }
        //-----------------------------------------------------
        scope.durationVisible = true;
      };

    },

    template:
      '<div ng-show="!editable">'+
        '<div ng-show="selectShow" class="right-side__item"><span gh-icon="duration 0791d0 25px auto normal"></span><span>{{ghModel | timeFormat}}</span></div>'+
      '</div>'+
      '<div ng-show="editable">'+
        '<div ng-click="durationVisibleOpen($event)" ng-show="!selectShow"><p>Select duration</p></div>'+
        '<div class="duration-icon" ng-click="durationVisibleOpen($event)" ng-show="selectShow"><span gh-icon="duration 0791d0 25px auto normal"></span><span>{{ghModel | timeFormat}}</span></div>'+
        '<div class="duration-picker" ng-style="position" ng-show="durationVisible">'+
          '<div>'+
            '<div class="duration-picker-container">'+
              '<gh-duration-input step="1" type="hours" model="arr[0]"></gh-duration-input>'+
              '<span class="twoDots">:</span>'+
              '<gh-duration-input step="5" type="minutes" model="arr[1]"></gh-duration-input>'+
            '</div>'+
            '<div class="datepicker-buttons">'+
              '<button class="datepicker-buttons__datepicker-button datepicker-button_clear" ng-click="clear()"><div gh-icon="rubbish 0791d0 20px auto normal" class="alignleft input-icon"></div>Clear</button>'+
              '<button class="datepicker-buttons__datepicker-button datepicker-button_close" ng-click="close($event)"><div gh-icon="cross 0791d0 20px auto normal" class="alignleft input-icon"></div>Close</button>'+
            '</div>'+
          '</div>'+
        '</div>'+
      '</div>'
  };
}])
.directive('ghDurationText', [function(){
    return {
        scope: {
            ghModel: '='
        },

        controller: ['$scope', function($scope){
            $scope.$watch('ghModel', function (newValue) {
                $scope.selectShow = newValue > 0;
            });
        }],

        template: '<div ng-show="selectShow" class="right-side__item gh-item-ref"><span>{{ghModel | timeFormat}}</span></div>'
    };
}])
.filter('timeFormat', function() {
  return function(value) {
    var hours = Math.floor(value / 3600000);
    var hours_ms = hours * 3600000;
    var minutes_ms = value - hours_ms;
    var minutes = minutes_ms / 60000;

    return (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes);
  };
});
