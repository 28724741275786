//gh-element-container reset css
require('./css/gh_element-container_reset_style.scss')


require('./config.js');
require('./storage/main_storage.js');
require('./storage/websocket.js');
require('./core/authorization_contrl.js');
require('./apps_manager/applications_list/applications_contrl.js');
require('./apps_manager/appCtrl.js');
require('./user/user_settings.Ctrl.js');
require('./gui/gh-bind-html.js');
require('./gui/gh_auth_form.js');

require('./gui/gh_icon/gh_icon.js');
// Web
require('./help/help.js');
require('./web/home_page_contrl.js');
require('./web/investors/investors.js');
require('./web/team/team.js');

require('./web/contact_us/contact_us.js');
// Dialog
require('./gui/dialog/gh_dialog.js');


require('./gui/loader/loader.js');

require('./css/style.scss');
require('./css/app.scss');

require('./css/form.scss');
require('./gui/gui.css');
require('./css/gui_settings.scss');
require('./css/gh-style.scss');
require('./gh_elements/document/plain/gh_text_editor.scss');


require('./apps_manager/applications_list/applications_list.html');
require('./apps_manager/app.html');
require('./web/home.html');

require('./gh_elements/edit_form/edit_template_service.js');
require('./gh_elements/edit_form/gh_view.scss');
// require('./gh_elements/code_editor/code_editor.js');

//--------- GUI
require('./gui/gh_button.js');
require('./gui/gh_avatar/gh_avatar.js');
require('./gui/gh_input/gh_input.js');
require('./gui/gh_input_list/gh_input_list.js');
require('./gui/gh-doc-repeat.js');
require('./gui/gh_checkbox_list.js');
require('./gui/gh_duration.js');
require('./gui/gh_radio_button.js');
require('./gui/gh_toast.js');
require('./gui/gh_filter/gh_filter.js');
require('./gui/gh_switch/gh_switch.js');
require('./gui/gh_boolean/gh-boolean.js');
require('./gui/gh_embed/gh_embed.js');
require('./gui/gh_user_selection.js');
require('./gui/infographic/gh_infographic_counter.js');
require('./gui/infographic/gh_infographic_value.js');
require('./gh_elements/table/table_service.js');
require('./gui/gh_option_table/gh_option_table.js');
require('./gui/gh_range.js');
require('./gui/gh_loader/gh_loader.js');
require('./gui/gh_internet_connection_lost/lostConnectionDirective.js');

//--------- UTILITES
require('./utils/ghJsonSchemeCompiler.js');

//--------- CORE
require('./core/app_processor.js');
require('./core/field_processor.js');
require('./core/item_processor.js');
require('./core/data_interpreter.js');
require('./core/document_manager.js');
require('./core/file_manager.js');
require('./core/filter/filter.js');
require('./core/send_email_service.js');
require('./core/filter/groupe_service.js');
require('./core/gh_constructor.js');
require('./core/pipe.js');
require('./core/gh_element/gh_element.scss');
require('./core/gh_view/gh_view.js');
require('./core/sharing_api.js');
require('./core/gh_element/gh_element.js');
require('./core/gh_window/gh_window.js');
require('./core/gh_element/gh_element_editor.js');

require('./gh_elements/calculator/gh-calculator.js');

/*Test*/
require('./gh_elements/timeline/gh_timeline.scss');
require('./gh_elements/color_data/gh_color.scss');


/*====================================================================================================*/
/*=========================================== GUDHUB LIBS   ==========================================*/
/*====================================================================================================*/
/*

  It used for two reasons:

  1) Connecting GUDHUB modules to main.js
  2) Using GUDHUB functionality for other websites especialy for wiggets

*/

angular.module('gudhubLibs', [
  'config',
  'ghElement',
  'ghWindowModule',
  /* External modules: */
  'ngAnimate',
  'ngRoute',
  'ngCookies',
  'ngAria',
  'ngMessages',
  'ngMaterial',
  /* GUI: */
  'ghAvatarModule',
  'ghInputModule',
  'loaderModule',
  'ghInputListModule',
  'ghDocumentRepeat',
  'ghCheckboxListModule',
  'ghToastModule',
  'ghFilterModule',
  'ghSwitchModule',
  'ghBooleanModule',
  'ghUserSelectingModule',
  'ghInfographicCounterModule',
  'ghInfographicValueModule',
  'ghButtonModule',
  'tableServiceModule',
  'ghOptionTableModule',
  'cssLoaderModule',
  'ghDurationModule',
  'ghRadioButtonModule',
	'ghRangeModule',
	'connectionModule',
  /* Our modules: */
  'authorizationMod',
  'homePageMod',
  'applicationsCtrlMod',
  'appCtrlMod',
  'userSettingsMod',
  // 'appMod',
  'ghBindHtml',
  'mainStorage',
  'ghAuthFormModule', /* Template auth for login/signup/update user */
  'oc.lazyLoad',
  'iconsGenerator',
  'pipeModule',
  'GhDialogModule',
  'GhTemplateModule',
  'editTemplateService',
  'helpModul',
  'sharingApiModule',
  // UTILITES
  'ghJsonSchemeCompilerModule',
  // CORE
  'appDataProcessor',
  'fieldDataProcessor',
  'sendingEmailModule',
  'itemDataProcessor',
  'groupeProcessor',
  'dataInterpreter',
  'fileManagerModule',
  'tableFilter',
  'ghEmbedModule',
  'ghConstructor',
  'ghElementEditorModule',
  'documentManagerModule',
  'ghCalculatorModule',
  // WEB
	'investorsModul',
	'teamModul',
  'contactusModule',
]);






/*====================================================================================================*/
/*=========================================== GUDHUB APP   ===========================================*/
/*====================================================================================================*/
var app = angular.module('gudhub', [
  'gudhubLibs'
]);



/*===========================================  MAIN CONTROLLER   ==========================================*/
app.controller('MainCtrl', [ 'cnfg', function(cnfg) {
  // Set edit data to 'true' for edit views and field data
	cnfg.show_context_menu = true;
}])



/*==========================================  APP INITIALIZATION    =======================================*/
app.run(['cnfg', '$templateRequest', '$compile', '$location', '$window', '$ocLazyLoad', 'authApi', 'storage', '$route', '$rootScope','appDataProcesingService', function(cnfg, $templateRequest, $compile, $location, $window, $ocLazyLoad, authApi, storage, $route, $rootScope, appDataProcesingService) {
  cnfg.initLogMessages();
  $templateRequest('/web/wally_templates.html', true).then(function(html) {
    var compiled = $compile(html);
  });


  // Send tracker google analitic for dunamic url
  $rootScope.$on('$viewContentLoaded', function(event) {
    if (!$window.ga) {
      return;
    }
    $window.ga('tracker.send', 'pageview', {page: $location.url()});
  });

  /* -- Check authentification of user and stop route on firt load template*/
  function routeChange(event, current, next) {
    /* Stop change route */
    event.preventDefault();

    if ($window.localStorage.auth_key) {

      authApi.updateToken($window.localStorage.auth_key).then(function(res ) {
        storage.setUser(res.data);
        appDataProcesingService.getAppsList();
        //$location.path($location.path() === '/' ? '/apps' : $location.path());
        return $location.path() === '/' ? '/apps' : $location.path();
      }, function(err) {

        return '/login';
      }).then(function(routePath) {
        // Destroy listener on $routeChangeStart
        routeChangeOff();
        // Reload rout

        if (routePath == $location.path()) {
          $location.path(routePath);
          // Reload rout
          $route.reload();
        } else {
          $location.path(routePath);
        }


      });

    } else {
      routeChangeOff();
      $route.reload();
    }
  }

  /* Listener on app init(load) */
  var routeChangeOff = $rootScope.$on('$routeChangeStart', routeChange);


}]);


/*========================================  ROUTING CONFIGURATION    ======================================*/
app.config([ '$routeProvider', '$locationProvider', '$httpProvider', '$compileProvider', '$mdGestureProvider',function($routeProvider, $locationProvider, $httpProvider, $compileProvider, $mdGestureProvider) {

  // add to white list blob urls
  $compileProvider.aHrefSanitizationWhitelist(/^\s*(blob|http|https|mailto|tel|slack|viber):/);

  $routeProvider
    .when('/', {
      templateUrl: 'web/home.html',
      theme: 'white'
    })
    .when('/home/:industry', {
      templateUrl: 'web/home.html',
      theme: 'white'
    })
    .when('/index.html', {
      redirectTo: '/'
    })
    .when('/app/:appId?/:itemId?/:edit?/:field?', {
      templateUrl: 'apps_manager/app.html'
    })
    .when('/act/:actionType?/:param_1?/:param_2?/:param_3?', {
      templateUrl: 'apps_manager/app.html',
      reloadOnSearch: false
    })
    .when('/apps', {
      templateUrl: 'apps_manager/applications_list/applications_list.html'
    })
    .when('/login', {
      templateUrl: '/user/login.html'
    })
    .when('/signup/:email?', {
      templateUrl: '/user/signup.html'
    })
    .when('/usersettings', {
      templateUrl: '/user/user_settings.html'
    })
    .when('/help', {
      templateUrl: '/help/help.html'
    })
    .when('/no-such-page', {
      templateUrl: '/web/no_such_page.html'
    })
    .when('/gh-style', {
      templateUrl: '/web/gh-style.html'
    })
    .when('/help/:id?', {
      templateUrl: '/help/help.html'
    })
    .when('/investors/pitch-deck', {
      templateUrl: '/web/investors/pitch-deck.html'
		})
		.when('/team', {
			templateUrl: '/web/team/team.html'
		})
		.when('/contact_us', {
      templateUrl: '/web/contact_us/contact_us.html'
    })
    .otherwise({
      redirectTo: '/no-such-page'
    });

  $httpProvider.interceptors.push(function() {
    return {
      request: function(config) {
        /* add token or auth_key*/
        return config;
      }
    };
  });

  $locationProvider.html5Mode(true);
  /* $locationProvider.hashPrefix('!');*/

  // For mobile devices without jQuery loaded, do not
  // intercept click events during the capture phase.
  $mdGestureProvider.skipClickHijack();
}]);
