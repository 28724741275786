export default {
  private: true,
  template_name: 'Flex',
  allowed_types: [
    'gh_float'
  ],
  model: {
    template_id: 'gh_flex',
    name: '',
    icon_id: '',
    class: 'gh_flex gh_col_height_0 gh_height_percent',
    priority: 0,
    container_id: 0,
    content: []
  },
  container_settings: [{
    name: 'Add Container',
    icon: 'plus',
    function: 'addContainer',
    arg: '{"allowed_types": ["gh_float", "gh_element", "gh_tabs"], "template_id": "gh_float", "name": "", "icon_id": "", "class": "gh_float gh_col_width_12 gh_col_height_0 gh_height_percent gh_width_percent vertical_scroll", "container_id": 0, "priority": 0, "content": []}'
  }, {
    name: 'Delete Wrap',
    icon: 'rubbish',
    function: 'deleteContainer'
  }, {
    name: 'Height PX',
    icon: 'plus',
    hide_if: 'gh_height_px',
    function: 'replaceClass',
    arg: '{"addClass" : "gh_height_px", "deleteClass" : ["gh_height_percent"]}'
  }, {
    name: 'Height %',
    icon: 'plus',
    hide_if: 'gh_height_percent',
    function: 'replaceClass',
    arg: '{"addClass" : "gh_height_percent", "deleteClass" : ["gh_height_px"]}'
  }, {
    name: 'Height +',
    icon: 'plus',
    function: 'sizeChange',
    arg: 'height plus'
  }, {
    name: 'Height Clear',
    icon: 'cancel',
    function: 'sizeChange',
    arg: 'height clear'
  }, {
    name: 'Height -',
    icon: 'cancel',
    function: 'sizeChange',
    arg: 'height minus'
  },{
    name: 'Bottom shadow on',
    icon: 'side',
    hide_if: 'bottom_shadow_on',
    function: 'replaceClass',
    arg: '{"addClass" : "bottom_shadow_on"}'
  },{
    name: 'Bottom shadow off',
    icon: 'side',
    show_if: 'bottom_shadow_on',
    function: 'replaceClass',
    arg: '{"deleteClass" : ["bottom_shadow_on"]}'
  }],
  html_editor:
    '<div class="gh_flex_block">'+
      //-----------------------------------
      '<div class="edit_template_container_form">'+
        '<pencil-container-settings parent="$parent.lists" lists="item.content" item="item"></pencil-container-settings>'+
      '</div>'+
      //-----------------------------------
      '<div class="gh_flex_block_list" ng-init="lists = item.content"'+
      'dnd-list="lists" dnd-allowed-types="item.allowed_types" dnd-drop="dropElement(index, item, external, type, lists)" dnd-inserted="insertedElement()">'+
        '<div class="gh_flex_block_list_item" ng-class="item.class" ng-repeat="item in lists"'+
        'dnd-draggable="item"'+
        'dnd-effect-allowed="move"'+
        'dnd-moved="$parent.lists.splice($index, 1)"'+
        'dnd-type="item.template_id"'+
        'ng-include="item.template_id + \'_editor\'"></div>'+
      '</div>'+
    '</div>',
  html_form: function (value, func) {
    return `<div class="${ value.class }">${ func(value.content) }</div>`;
  },
  icon: ''
};
