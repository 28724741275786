import {MainControl} from "../controls/main_control.js"
import Rete from "rete";
import {items} from "../controls/sockets.js"
import {ifNoConnections} from "../controls/utilities.js"
import {act} from "../../actions/actions.js"






export class GetItems extends Rete.Component {

  constructor(){
      super("Get Items");
  }

  builder(node) {
    var outItems = new Rete.Output('outItems', "Items", items);
    var inpItems = new Rete.Input('inpItems',"Items", items, true);
    var cntr = new MainControl( this.editor, 'text', false, node, this.getSettings(), this.getTemplate() );

    return node
        .addControl(cntr)
        .addInput(inpItems)
        .addOutput(outItems);
  }

  async worker(node, inputs, outputs, triggeredData, resolve, gudhub) {
    let app_id = node.data.app_id

    for(let i=0; inputs['inpItems'].length>i; i++){
      let inputItems = inputs['inpItems'][i];
      let receivedItems = [];
      
      //-- We send request for items update on server if we have items
      if(inputItems.length != 0){
        receivedItems = await act.apiItemsGet(gudhub, app_id);
        console.log("receivedItems",receivedItems);
      }

      outputs['outItems'] = receivedItems;
      

      //-- checking if there are output connections
      //-- If there no outputs then we return value
      if( ifNoConnections(node.outputs) )
      resolve(receivedItems);
    }
  }

  getTemplate() {
    return {
      name: 'Get Items',
      icon: 'dropdown_home',
      constructor: 'node',
      data_model: { 
        app_id: ''
      }
    }
  }

  getSettings(){
    return [{
            title: 'Options',
            type: 'general_setting',
            icon: 'menu',
            columns_list: [[
              {
                title: 'Default Settings',
                type: 'header'
              },{
                type: 'ghElement',
                property: 'app_id',
                data_model: function (fieldModel) {
                    return {
                        field_name: 'Application',
                        data_type: 'app',
                        data_model: {
                            current_app: false,
                            interpretation: [{
                                src: 'form',
                                id: 'with_text',
                                settings: {
                                    editable: 1,
                                    show_field_name: 1,
                                    show_field: 1
                                }
                            }]
                        }
                    };
                }
            }]]
      }]

  }
}