// Inport dialog css
require('./style_gh_dialog.scss');

/************************************************************************************************************|
|*********************************************    GH-DIALOG    *********************************************|
|************************************************************************************************************|
|  ┌──────────────────────────────────────────────────────────────────────────────────────────────────────┐
|  │                               GhDialog.show({                                                        |
|  │        													position: 'full',			<--  dialog type                              |
|  │        													toolbar: false, 			<--  show/hide toolbar                        |
|  │        													template: {                                                         |
|  │        														toolbar: '',  			<--  html for toolbar                         |
|  │        														resizable: false,                                                 |
|  │        														content: `` 				<--  use html                                 |
|  │        													},                                                                  |
|  │        													locals: {                                                           |
|  │        														exmpl : exmpl   <-- use local property for deliver it to $scope   |
|  │        													},                                                                  |
|  │        													controller: ['$scope', function ($scope) {    |
|  │        													                                     																|
|  │        													}]                                                                  |
|  │        												}).then(function (data) {}, function () {});                          │
|  └──────────────────────────────────────────────────────────────────────────────────────────────────────┘
|
|  parameters:
|      ◆ toolbar - show/hide toolbar
|      ◆ position: 
|							• bottom - dialog goes from bottom
|							• center - shows dialog like pop-up
|							• full - shows dialog in fullscreen
|
|**********************************************************************************************************|
*/

const DEFAULT_DIALOG_OPTIONS = {
  position: 'center',
  locals: {},
  mobile_position: 'center_mobile',
  template: {
    toolbar: '',
    content: '<div></div>'
  },
  resizable: true,
  arrows: ['top_arrow', 'bot_arrow'],
  abs_position: {},
  clickOutsideToClose: true,  
  controller: function () {}
};

angular.module('GhDialogModule', [])
  .service('GhDialog', ['$controller', '$rootScope', '$templateCache', '$q', '$compile',
    function ($controller, $rootScope, $templateCache, $q, $compile) {

      this.show = function(optObj){

        let deferred = $q.defer();
        let positionCalc = {
            'top': '0px',
            'left': '0px'
        };

        let height_window = window.innerHeight*50/100;


        let width_window = window.innerWidth*50/100;

        positionCalc.top = height_window + "px";
        positionCalc.left = width_window + "px";
        let options = Object.assign({}, DEFAULT_DIALOG_OPTIONS, optObj);
        // Create template of slider window and append it to document body
        let template =
					`<div class="gudhub gh-ui-dialog --${options.position} --${options.mobile_position} {{dialogSize}}" 
              style="${options.position === 'full_event_position' ? '' : options.abs_position}" ng-init="dialogSize = \'\'">
              <div class="gh-ui-dialog-backdrop" ${options.clickOutsideToClose ? 'ng-click="cancel()"' : ''} ></div>
            <div class="gh-ui-dialog-content-wrap" style="${options.position === 'full_event_position' ? options.abs_position : ''}">
              <div class="toolbar" ng-hide="${options.toolbar == false}">
                <div class="toolbar-content">${(options.template || {}).toolbar || ''}</div>
                <div class="toolbar-controls">
                    <div ng-show="${options.position == 'center' || options.position == 'center_event_position' ? 'false' : 'true'}" class="resize" gh-icon="{{dialogSize != \'full_screen\' ? \'${options.arrows[0]}\' : \'${options.arrows[1]}' }} 86BEF6 32px normal"
                      ng-click="dialogSize = dialogSize == \'full_screen\' ? \'\' : \'full_screen\'">{{dialogSize}}</div>
                    <div class="cross" gh-icon="cross 86BEF6 32px normal" ng-click="cancel()"></div>
                </div>
              </div>
              <div class="content">${(options.template || {}).content || ''}</div>
            </div>
          </div>`;

        let htmlElementTemplate = angular.element(template);
        let dialogScope = $rootScope.$new();
        dialogScope.dialogSize = "";  
        function closeDialog() {
          // Add close class
          htmlElementTemplate.addClass('--close');
          htmlElementTemplate.remove();
          angular.element(document.body).removeClass('gh-ui-dialog-is-showing');
          dialogScope.$destroy();
        }

        // Add destroy(hide) event
        dialogScope.hide = function(data) {
          closeDialog();
          deferred.resolve(data || null);
        };

        // Add destroy(hide) event
        dialogScope.cancel = function() {
          closeDialog();
          deferred.reject();
        }; 

        // Create dialog controller
        $controller(
          options.controller,
          Object.assign({ $scope: dialogScope, $element: template, elementNode: htmlElementTemplate}, options.locals)
        );

        angular.element(document.body)
          .append($compile(htmlElementTemplate)(dialogScope))
          // Set slider showing class to body
          .addClass('gh-ui-dialog-is-showing');

        return deferred.promise;
      };

      this.showSettings = function(optObj){

        let deferred = $q.defer();

        let options = Object.assign({}, DEFAULT_DIALOG_OPTIONS, optObj);

        // Create template of slider window and append it to document body
        let template =
          `<div class="gudhub gh-ui-dialog-settings --${options.position} {{dialogSize}}" ng-init="dialogSize = \'\'; currentTab = 0">
            <div class="gh-ui-dialog-backdrop" ${options.clickOutsideToClose ? 'ng-click="cancel()"' : ''} ></div>
            <div class="gh-ui-dialog-content-wrap">

              <div class="toolbar" ng-hide="${options.toolbar == false}">
                <div class="apply gh-btn inline gh-btn-blue gh-btn-bg" ng-click="hide(fieldModel)"><p>Apply</p></div>
                <div class="toolbar-content">${(options.template || {}).toolbar || ''}</div>
                <div class="toolbar-controls">
                    <div class="resize" gh-icon="{{dialogSize != \'full_screen\' ? \'top_arrow\' : \'bot_arrow\'}} 86BEF6 32px normal"
                        ng-click="dialogSize = dialogSize == \'full_screen\' ? \'\' : \'full_screen\'"></div>
                    <div class="cross" gh-icon="cross 86BEF6 32px normal" ng-click="cancel()"></div>
                </div>
              </div>

              <div class="content">${(options.template || {}).content || ''}</div>

            </div>
          </div>`;

        let htmlElementTemplate = angular.element(template);
        let dialogScope = $rootScope.$new();

        function closeDialog() {
          // Add close class
          htmlElementTemplate.addClass('--close');
          // Delay for animation
          const timer = setTimeout(function () {
            htmlElementTemplate.remove();
            angular.element(document.body).removeClass('gh-ui-dialog-is-showing');
            clearTimeout(timer)
          }, 700);
          dialogScope.$destroy();
        }

        // Add destroy(hide) event
        dialogScope.hide = function(data) {
          closeDialog();
          deferred.resolve(data || null);
        };

        // Add destroy(hide) event
        dialogScope.cancel = function() {
          closeDialog();
          deferred.reject();
        };

        // Create dialog controller
        $controller(
          options.controller,
          Object.assign({ $scope: dialogScope, $element: template}, options.locals)
        );

        angular.element(document.body)
          .append($compile(htmlElementTemplate)(dialogScope))
          // Set slider showing class to body
          .addClass('gh-ui-dialog-is-showing');

        return deferred.promise;
      };

      return this;
    }]);
