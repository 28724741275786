require('./../config.js');

angular.module('itemDataProcessor', [
'mainStorage',
'config',
'appDataProcessor',
'ghConstructor'
])


.run(['PipeService', 'itemsDataProcessing', function (PipeService, itemsDataProcessing) {
  /* ---- FIELD VALUE GET LISTENING ---- */
  PipeService.onRoot('gh_items_get', {}, function (event, data) {
    itemsDataProcessing.getItems(data.app_id).then(function (items) {
      PipeService.emit('gh_items_get', data, items);
    }, function () {
      PipeService.emit('gh_items_get', data, []);
    });
  });

  PipeService.onRoot('gh_items_add', {}, function (event, data) {
    itemsDataProcessing.addNewItems(data.app_id, data.items).then(function (items) {
      
      PipeService.emit('gh_items_add', data, items);
    }, function () {
      PipeService.emit('gh_items_add', data, []);
    });
  });

  /* ---- FIELD VALUE GET LISTENING ---- */
  PipeService.onRoot('gh_item_get', {}, function (event, data) {
    itemsDataProcessing.getItems(data.app_id).then(function (items) {
      var item = items.find(function (item) {
        return item.item_id == data.item_id;
      });
      PipeService.emit('gh_item_get', data, item);
    });
  });
}])

/*=======================================================================================================*/
/*=====================================   ITEM SERVICE    ===============================================*/
/*=======================================================================================================*/
/* here we operate with Form's and Form's Editor data*/
/* started from app_controller.js*/
.service('itemDataProcessing', [ '$location', '$q', 'cnfg', 'itemApi', 'itemStorage', 'authService', 'itemsApi', function ( $location, $q, cnfg, itemApi, itemStorage, authService, itemsApi ) {
  var log = cnfg.log.itemDataProcessing;/*- show/hide console output*/




  /*----------------------------- FILTERING ITEM's FIELDS BEFORE SENDING -------------------------*/
  /*-- Checking if some fields has null in field_value ( we don't send such fields )*/
  var filterFields = function (fieldsToFilter) {
    var filteredFields = [];

    angular.forEach(fieldsToFilter, function (field, key) {
      if (field.field_value != null) {
        filteredFields.push(field);
      }
    });
    return filteredFields;
  };




  /*----------------------------- ADDING NEW ITEM -------------------------*/
  this.addNewItem = function ( scope ){
    var deferred = $q.defer();
    var filteredFields = filterFields( scope.fields );
    var appId = scope.app_id;

    /*-- Getting application from server*/
    authService.getToken().then(function( accessToken ) {
        /*-- Update item on server*/
        itemApi.addItemApi( appId, filteredFields, accessToken ).then(function( item ) {
          /*-- Update item in Main Storage*/
          itemStorage.addItemToStorage( appId, item ).then(function() {
            /*-- refreshing table after item was added to server*/
            /* Return item */
            deferred.resolve(item);
            log?console.log("ITEM SERVICE : Item Was Added"):'';
          });

        });
      },
      function(error) {
        /*-- If access token can't be taken then we redirect user to login page*/
        /* after user logged-in we redirect him back to the page that he requested*/
        var appUrl =  $location.url();
        authService.setPageForRedirect( appUrl );
        $location.path( "login" );
        deferred.reject();
      });
    return deferred.promise;
  };


  /*----------------------------- DELETING ITEMS -------------------------*/
  this.deleteItems = function (appId, items ){

    var deferred = $q.defer();

    /*-- Getting application from server*/
    authService.getToken().then(function( accessToken ) {
        /*-- Delete item on server*/

        itemApi.deleteItemsApi( items, accessToken ).then(function(data) {
          /*-- Delete item from scope*/
          itemStorage.deleteItemsFromStorage( appId, items ).then(function() {
            /*-- refreshing table after item was added to server*/
            /*scope.app_init();*/
            deferred.resolve();
            log?console.log("ITEM SERVICE  : Item Was Deleted"):'';
          });
        });
      },
      function(error) {
        /*-- If access token can't be taken then we redirect user to login page*/
        /* after user logged-in we redirect him back to the page that he requested*/
        var appUrl =  $location.url();
        authService.setPageForRedirect( appUrl );
        $location.path( "login" );
      });

    return deferred.promise;
  };





  /*----------------------------- CHECKING FOR SELECTED ITEMS  --------------------------------*/
  this.checkForSelectedItems = function ( items ){
    var selectedArrey = [];
    angular.forEach( items, function(item, key) {
      if ( item.selected == true ){
        selectedArrey.push( item.item_id );
      }
    });

    return selectedArrey;
  };


}])




/*= === === === === === === === === === === === === === === === === === === === === === === === === === === === === === === === === === === * /
 /*=====================================   ITEMS SERVICE    ===============================================*/
/*=======================================================================================================*/
/* here we operate with Form's and Form's Editor data*/
/* started from app_controller.js*/
.service('itemsDataProcessing', ['$location', '$q', 'cnfg', 'itemApi', 'itemStorage', 'authService', 'itemDataProcessing', 'itemsApi', 'appDataProcesingService', 'fillFieldsWithDefaultValues', 'PipeService', function($location, $q, cnfg, itemApi, itemStorage, authService, itemDataProcessing, itemsApi, appDataProcesingService, fillFieldsWithDefaultValues, PipeService) {
  var log = cnfg.log.itemDataProcessing; /*- show/hide console output*/


  /*----------------------------- FILTERING ITEM's FIELDS BEFORE SENDING -------------------------*/
  /*-- Checking if some fields has null in field_value ( we don't send such fields )*/
  var filterItemFields = function ( fieldsToFilter ){
    var filteredFields = [];

    angular.forEach( fieldsToFilter, function(field, key) {
      if ( field.field_value != null ){
        filteredFields.push( field );
      }
    });

    return filteredFields;
  };


  /*----------------------------- ADDING NEW ITEM -------------------------*/
  this.getItems = function(appId) {

    var deferred = $q.defer();

    appDataProcesingService.getApp(appId).then(function(app){
      deferred.resolve(app.items_list);
    }, function(err){
      deferred.reject();
    });

    return deferred.promise;
  };


  /*----------------------------- ADDING NEW ITEM -------------------------*/
  this.addNewItems = function(appId, itemsList) {
    var deferred = $q.defer();

    // fill default values
    fillFieldsWithDefaultValues(appId, itemsList).then(function() {
      /* -- Create valid request object of items*/
      itemsList = itemsList.map(function(item) {
        item.fields = item.fields.filter(function(field) {
          return angular.isDefined(field.field_value);
        });
        return item;
      });

      /*-- Getting application from server*/
      authService.getToken().then(function(accessToken) {
          itemsApi.addItemsApi(appId, itemsList, accessToken).then(function(items) {
            itemStorage.addItemsToStorage(appId, items);
            //-- We return promice becouse some gh-elements needs this for their coorect operations. e.g items_add
            deferred.resolve(items);
            PipeService.emit('gh_items_add', {app_id: appId}, items);
          });
        },
        function(error) {
          /*-- If access token can't be taken then we redirect user to login page*/
          /* after user logged-in we redirect him back to the page that he requested*/
          var appUrl = $location.url();
          authService.setPageForRedirect(appUrl);
          $location.path("login");
          deferred.reject();
        });
    });


    return deferred.promise;
  };




  /*----------------------------- UPDATING ITEM -------------------------*/
  this.updateItems = function(appId, itemsList) {

    var deferred = $q.defer();

    /* -- Create valid request object of items*/
    itemsList = itemsList.map(function(item){
      return {
        item_id: item.item_id,
        fields: filterItemFields(item.fields)
      };
    });

    /*-- Getting access token from server*/
    authService.getToken().then(function(accessToken) {

        itemsApi.updateItemsApi(appId, itemsList, accessToken).then(function(items) {

          itemStorage.updateItemsInStorage(appId, items).then(function(itemsFromStorage) {
            deferred.resolve(itemsFromStorage);
            log ? console.log("ITEMS SERVICE  : Items Was Updated") : '';
          });
          

        });
      },
      function(error) {
        deferred.reject();
        /*-- If access token can't be taken then we redirect user to login page*/
        /* after user logged-in we redirect him back to the page that he requested*/
        log ? console.log("ERROR  : updateItems > getToken()") : '';
        var appUrl = $location.url();
        authService.setPageForRedirect(appUrl);
        $location.path("login");
      });

    return deferred.promise;
  };




  /*----------------------------- DELETING ITEMS -------------------------*/
  this.deleteItems = function(appId, items) {
    var deferred = $q.defer();
    /*-- Getting application from server*/
    authService.getToken().then(function(accessToken) {
        /*-- Delete item on server*/

        itemApi.deleteItemsApi(items, accessToken).then(function(data) {
          /*-- Delete item from scope*/
          itemStorage.deleteItemsFromStorage(appId, items).then(function() {
            /*-- refreshing table after item was added to server*/
            /*scope.app_init();*/
            log ? console.log("ITEM SERVICE  : Item Was Deleted") : '';
            deferred.resolve();
          });
        });
      },
      function(error) {
        /*-- If access token can't be taken then we redirect user to login page*/
        /* after user logged-in we redirect him back to the page that he requested*/
        var appUrl = $location.url();
        authService.setPageForRedirect(appUrl);
        $location.path("login");
        deferred.reject();
      });

    log ? console.log('itemDataProcessing.Create Item') : '';

    return deferred.promise;
  };

}])



/*=======================================================================================================*/
/*==============================================   ITEM API   ===========================================*/
/*=======================================================================================================*/
/*-- here we adding, updating and deleting items*/

.factory('itemApi', [ '$q', '$http', '$location', 'cnfg', function( $q, $http, $location, cnfg ) {
  var log = cnfg.log.itemApi;/*- show/hide console output*/
  var apiUrl = cnfg.server_url + '/api/';

  function replaceSpecialCharacters(item) {
    return angular.toJson(item).replace(/\+|&|%/g, function(match) {
      switch (match) {
        case '+':
          return '%2B';
        case '&':
          return '%26';
        case '%':
          return '%25';
      }
    })
  }

  return {



    /*------------------------------- ADD ITEM--------------------------------------*/
    addItemApi: function( appId, newfields, accessToken ) {
      /*-- composing fields parameter for request*/
      var item = {
        "item_id" : 0,
        "fields" :  newfields
      };

      return $http({
        method: 'POST',
        url: apiUrl + 'item/add',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
        data: 'item=' + replaceSpecialCharacters(item) +
        '&app_id=' + appId +
        '&token=' + accessToken,

      }).then(function(result) {
        log?console.log("SERVER : Item was added"):'';
        return result.data;
      });
    },


    /*------------------------------- DELETE ITEMs --------------------------------------*/
    deleteItemsApi: function( items, accessToken ) {
      return $http({
        method: 'POST',
        url: apiUrl + 'items/delete',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
        data: 'items_ids=[' + items + ']' +
        '&token=' + accessToken,

      }).then(function(result) {
        log?console.log("SERVER : Item was deleted"):'';
        log?console.log( result.data ):'';
        return result.data;
      });
    },



  };
}])



/*========================================================================================================*/
/*==============================================   ITEMS API   ===========================================*/
/*========================================================================================================*/
/*-- here we adding, updating and deleting items*/

.factory('itemsApi', ['$q', '$http', '$location', 'cnfg', function($q, $http, $location, cnfg) {

  /*
   Items list must have structure:

   [
   {
   item_id : 1003,
   fields:[
   {
   field_id : 1034,
   field_value : 'Update with Window'
   }, ....
   ]
   },
   {
   item_id : 1004,
   fields: [
   {
   field_id : 1035,
   field_value : 'Update with Linux'
   }, ...
   ]
   }, ...
   ]

   */

  var log = cnfg.log.itemApi; /*- show/hide console output*/
  var apiUrl = cnfg.server_url + '/api/';

  function replaceSpecialCharacters(item) {
    return angular.toJson(item).replace(/\+|&|%/g, function(match) {
      switch (match) {
        case '+':
          return '%2B';
        case '&':
          return '%26';
        case '%':
          return '%25';
      }
    })
  }

  return {



    /*------------------------------- ADD ITEMS --------------------------------------*/
    addItemsApi: function(appId, itemsList, accessToken) {

      /*-- composing fields parameter for request*/
      return $http({
        method: 'POST',
        url: apiUrl + 'items/add',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        data: 'items=' + replaceSpecialCharacters(itemsList) +
        '&app_id=' + appId +
        '&token=' + accessToken,
      }).then(function(result) {
        if (log) {
          console.log("SERVER : Items was added");
        }
        return result.data;
      });
    },




    /*------------------------------- UPDATE ITEM--------------------------------------*/
    updateItemsApi: function(appId, itemsList, accessToken) {

      return $http({
        method: 'POST',
        url: apiUrl + 'items/update',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        data: 'items=' + replaceSpecialCharacters(itemsList) +
        '&app_id=' + appId +
        '&token=' + accessToken,

      }).then(function(result) {
        if (log) {
          console.log("SERVER : Item was added");
        }
        return result.data;
      });

    },


    /*------------------------------- DELETE ITEMS --------------------------------------*/
    deleteItemsApi: function(items, accessToken) {
      return $http({
        method: 'POST',
        url: apiUrl + 'items/delete',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        data: 'items_ids=[' + items + ']' +
        '&token=' + accessToken,

      }).then(function(result) {
        if (log) {
          console.log("SERVER : Item was deleted");
          console.log(result.data);
        }
        return result.data;
      });
    },



  };
}])



/*=======================================================================================================*/
/*==================================  UPDATE ITEM IN MAIN STORAGE  ======================================*/
/*=======================================================================================================*/
.factory('itemStorage', [ '$q', '$http', '$location', 'cnfg', 'storage', 'PipeService', function( $q, $http, $location, cnfg, storage, PipeService ) {
  var log = cnfg.log.itemScope;/*- show/hide console output*/

  return {



    /*------------------------------- ADD ITEM TO STORAGE --------------------------------------*/
    addItemToStorage: function( appId, item ) {
      var deferred = $q.defer();

      /*-- getting app from storage*/
      storage.getAppFromStorage( appId ).then(function( app ) {
        /*-- pushing item to the app*/
        
        app.items_list.push( item );
        PipeService.emit('gh_items_update', {app_id: appId},  app.items_list);
        deferred.resolve( item );
      });

      log?console.log( 'STORAGE: Item was added' ):'';
      return deferred.promise;
    },


     
    /*------------------------------- ADD ITEM TO STORAGE --------------------------------------*/
    addItemsToStorage: function( appId, items ) {
      var deferred = $q.defer();

      /*-- getting app from storage*/
      storage.getAppFromStorage( appId ).then(function( app ) {
        
        items.forEach(item => {
          /*-- pushing item to the app*/
          app.items_list.push( item );
        })

        deferred.resolve( items );
        PipeService.emit('gh_items_update', {app_id: appId},  app.items_list);
      });


      log?console.log( 'STORAGE: Item was added' ):'';
      return deferred.promise;
    },

    



    
    /*------------------------------ UPDATE ITEMs IN STORAGE --------------------------------------*/
    updateItemsInStorage: function(  appId, items  ) {
      var deferred = $q.defer();
      console.log("updateItemsInStorage = ", items );
      PipeService.emit('gh_items_update', {app_id:appId}, items);
      //-- getting app from storage
      storage.getAppFromStorage( appId ).then(function( app ) {
        
        angular.forEach(items, function(item, index) {
            PipeService.emit('gh_item_update', {app_id: appId, item_id: item.item_id}, item);

            //-- Looking for updated item in the main storage according to 'item_id'
            angular.forEach( app.items_list, function(storageItem, key) {
              if ( storageItem.item_id == item.item_id ){
                item.fields.forEach(function (field) {
                  let fieldIsNotExist = true;

                  //-- Updating value in existing fields 
                  storageItem.fields.forEach(function (sField){
                    if(field.field_id == sField.field_id ){
                      fieldIsNotExist = false;

                      //-- Checking if value of existing fields were updated, becouse we are not sending updates if value didn't changed
                      if (field.field_value != sField.field_value){
                        sField.field_value = field.field_value;
                        PipeService.emit('gh_value_update', {app_id:appId, item_id:item.item_id, field_id:field.field_id}, field.field_value);
                      }
                    }
                  });

                  //-- Pushing new fields to the storage (it's when user enters new value)
                  if (fieldIsNotExist){
                    storageItem.fields.push(field);
                    PipeService.emit('gh_value_update', {app_id:appId, item_id:item.item_id, field_id:field.field_id}, field.field_value);
                  }
                });
              }
            });
        });
        deferred.resolve(items);
      });

      log?console.log( 'STORAGE: Item was updated' ):'';
      return deferred.promise;
    },




    /*------------------------------- DELETE ITEMs FROM STORAGE --------------------------------------*/
    deleteItemsFromStorage: function( appId, itemsForDelete ) {
      var deferred = $q.defer();

      /*-- getting app from storage*/
      storage.getAppFromStorage( appId ).then(function( app ) {

        /*-- iterating through list of items for deleting*/
        angular.forEach( itemsForDelete, function(itemToDelete) {
          /*-- Looking for 'itemToDelete' in the application main storage according to 'item_id'*/
          angular.forEach( app.items_list, function(storageItem, key) {
            /*-- in case if the item was found we delete it*/
            if ( storageItem.item_id == itemToDelete ){
              app.items_list.splice( key, 1);
            }
          });
        });

        PipeService.emit('gh_items_update', {app_id: appId},  app.items_list);
        deferred.resolve();
      });


      log?console.log( 'STORAGE: Item was deleted' ):'';
      return deferred.promise;
    }


  };
}])






/*=======================================================================================================*/
/*===============================  FILL FIELDSWITH DEFAULT VALUES  ======================================*/
/*=======================================================================================================*/
//-- When we create new item we generate default value for some fields in case if GH-ELEMENT has "Used Defoult Value" feature turned on
//-- Each GH-ELEMENT has getDefaultValue() method so we use it to get a defoult value
.factory('fillFieldsWithDefaultValues', ['PipeService', 'GHConstructor', '$q', function(PipeService, GHConstructor, $q) {
  'use strict';

  return function(appId, itemsList) {
    var deferred = $q.defer();

      PipeService.on('gh_models_get', {app_id: appId}, function itemsPipe(event, fieldModels) {
        PipeService.destroy('gh_models_get', {app_id: appId}, itemsPipe);
        var fieldsToFillCount = fieldModels.length;
        var valuesArray = new Array(itemsList.length)
        fieldModels.forEach(function (fieldModel) {
          if ( fieldModel.data_model.use_default_value) {
            GHConstructor.getInstance(fieldModel.data_type).then(function (response) {
              valuesArray = getValueFromItemList(fieldModel.field_id);
              response.getDefaultValue(fieldModel, valuesArray, itemsList).then(arraOfVal => {
                fillItemsWithValues(fieldModel.field_id, arraOfVal);  

                if (!--fieldsToFillCount) {
                  deferred.resolve();
                }

              });
            });
          }else{
            if(!--fieldsToFillCount){
              deferred.resolve();
            }
          }
        });
  
      }).emit('gh_models_get', {}, {app_id: appId});
      
  
    return deferred.promise;
 
    
    function getValueFromItemList(fieldModelID){
      var arrayWthValue = [];
      for(var i = 0; i < itemsList.length; i++){
        var fieldInItemList = itemsList[i].fields.find(function(field){
          return field.field_id == fieldModelID;
        })
        if(fieldInItemList){
          arrayWthValue.push(fieldInItemList.field_value)
        }else{
          arrayWthValue.push(null)
        }
      }

      return arrayWthValue;
    }
        
    function fillItemsWithValues(field_idPar, arrayVal){
        for(var i = 0; i < itemsList.length; i++){
          // looking for field
          itemsList[i].fields.forEach(function(field){
            if(String(field.field_id) == String(field_idPar)){
              field.field_value = arrayVal[i];
              arrayVal[i] = null;                
            }
          })
          //if we haven't found the field then we push value into the item
          if(arrayVal[i] == 0 || arrayVal[i]){
            itemsList[i].fields.push({field_id: field_idPar, field_value: arrayVal[i]})
            arrayVal[i] = null;
          }
        }
    }

  };

}]);