


// --------------------- Build context menu list ---------------------
function createList(itemsList = []) {
  let list = document.createElement('ul');

  itemsList.forEach((item) => {
    let itemHtml = document.createElement('li');
    let title = document.createElement('p');
    title.innerHTML = item.title || '';
    itemHtml.appendChild(title);

    if(item.list && item.list.length){
      itemHtml.appendChild(createList(item.list));
    } else if(item.events){

      // Set events to menu item
      for(let event in item.events){
        if(item.events.hasOwnProperty(event)){
          itemHtml.addEventListener(event.toString(), item.events[event]);
        }
      }

    }
    list.appendChild(itemHtml);
  });

  return list;
}

function stopPropagationEvent(e) {
  e.preventDefault();
  e.stopPropagation();
}


function getEventCoords(e = window.event) {
  let [x, y] = [
    e.pageX || e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft,
    e.pageY || e.clientY + document.body.scrollTop + document.documentElement.scrollTop
  ];
  return {x: x, y: y};
}


function setMenuPosition(e, menu) {
  let clickCoords = getEventCoords(e);

  let [windowW, windowH, menuW, menuH] = [
    window.innerWidth, window.innerHeight, menu.offsetWidth + 2, menu.offsetHeight + 2
  ];

  menu.style.left = Math.min(windowW - menuW, clickCoords.x) + "px";
  menu.style.top = Math.min(windowH - menuH, clickCoords.y) + "px";
}


export default class ContextMenu{
  constructor(targetElement, itemsList = []){

    if(!itemsList.length){
      return;
    }

    this.menuState = 0;
    this.menu = document.createElement('nav');
    this.menu.classList.add('context-menu');
    this.menu.appendChild(createList(itemsList));
    
    
    this.resizeEventHandler = function () {
      
    };

    this.hideHandler = (e) => {
      stopPropagationEvent(e);
      this.hide();
    };

    targetElement.addEventListener("contextmenu", (e) => {
      stopPropagationEvent(e);
      this.toggle();
      if (this.menuState === 1){
        setMenuPosition(e, this.menu);
      }
    });

    this.menu.addEventListener("click", (e) => {
      stopPropagationEvent(e);
      this.hide();
    });

  }

  show(){
    if (this.menuState !== 1){
      this.menuState = 1;
      //this.menu.classList.add('--active');
      document.addEventListener("contextmenu", this.hideHandler);
      document.addEventListener("click", this.hideHandler);
      document.body.appendChild(this.menu);
    }
  }

  hide(){
    if (this.menuState !== 0){
      this.menuState = 0;
      //this.menu.classList.remove('--active');
      document.removeEventListener("contextmenu", this.hideHandler);
      document.removeEventListener("click", this.hideHandler);
      document.body.removeChild(this.menu);
    }
  }

  toggle(){
    this[this.menuState !== 0 ? 'hide' : 'show']();
  }
}
