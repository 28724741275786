require('./team.scss');
angular.module('teamModul', [])

  .controller('teamCtrl', ['$scope','$routeParams', '$location', function($scope, $routeParams, $location) {
		$scope.members = [{
			name: "Andrew",
			position: "CEO/Lead Architect",
			photo_url: "web/team/img/andrew.jpg"
		},
		{
			name: "Ann",
			position: "Marketing Director",
			photo_url: "web/team/img/ann.jpg"
		},
		{
			name: "Tanya",
			position: "CFO",
			photo_url: "web/team/img/tanya.jpg"
		},
		{
			name: "Edward",
			position: "COO",
			photo_url: "web/team/img/edward.jpg"
		},
		{
			name: "Vince",
			position: "Frontend Lead",
			photo_url: "web/team/img/vince.jpg"
		},
		{
			name: "Tony",
			position: "Frontend JS",
			photo_url: "web/team/img/tony.jpg"
		}, 
		{
			name: "Robert",
			position: "Frontend JS",
			photo_url: "web/team/img/robert.jpg"
		},
		{
			name: "Helga",
			position: "Frontend HTML",
			photo_url: "web/team/img/helga.jpg"
		},
		{
			name: "Victoria",
			position: "QA",
			photo_url: "web/team/img/victoria.jpg"
		},
		{
			name: "Vince",
			position: "Backend Developer",
			photo_url: "web/team/img/vince2.jpg"
		},
		{
			name: "Leslie",
			position: "UI/UX",
			photo_url: "web/team/img/leslie.jpg"
		},
		{
			name: "Serge",
			position: "Project Manager",
			photo_url: "web/team/img/serge.jpg"
		},
		{
			name: "Mike",
			position: "Business Development",
			photo_url: "web/team/img/mike.jpg"
		},
		{
			name: "Eugene",
			position: "Business Development",
			photo_url: "web/team/img/eugene.jpg"
		},
		{
			name: "Tanya",
			position: "Business Development",
			photo_url: "web/team/img/Tan.jpg"
		},
	]

  }]);