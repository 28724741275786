import {MainControl} from "./../controls/main_control.js"
import Rete from "rete";
import {items} from "./../controls/sockets.js"
import {ifNoConnections} from "./../controls/utilities.js"
import {act} from "./../../actions/actions.js"






export class UpdateItemsApi extends Rete.Component {

  constructor(){
      super("Update Items Api");
  }

  builder(node) {
    var outItems = new Rete.Output('outItems', "Items", items);
    var inpItems = new Rete.Input('inpItems',"Items", items, true);
    var cntr = new MainControl( this.editor, 'text', false, node, this.getSettings(), this.getTemplate() );

    return node
        .addControl(cntr)
        .addInput(inpItems)
        .addOutput(outItems);
  }

  async worker(node, inputs, outputs, triggeredData, resolve, gudhub) {
    let app_id = node.data.app_id

    for(let i=0; inputs['inpItems'].length>i; i++){
      let inputItems = inputs['inpItems'][i];
      let updatedItems = [];
      
      //-- We send request for items update on server if we have items
      if(inputItems.length != 0){
        updatedItems = await act.apiItemsUpdate(inputItems, gudhub, app_id);
        //console.log("updatedItems",updatedItems);
      }

      outputs['outItems'] = updatedItems;
      

      //-- checking if there are output connections
      //-- If there no outputs then we return value
      if( ifNoConnections(node.outputs) )
      resolve(updatedItems);
    }
  }

  getTemplate() {
    return {
      name: 'Update Items Api',
      icon: 'circular',
      constructor: 'node',
      data_model: { 
        app_id: ''
      }
    }
  }

  getSettings(){
    return [{
            title: 'Options',
            type: 'general_setting',
            icon: 'menu',
            columns_list: [[
              {
                title: 'Default Settings',
                type: 'header'
              },{
                type: 'ghElement',
                property: 'app_id',
                data_model: function (fieldModel) {
                    return {
                        field_name: 'Application',
                        data_type: 'app',
                        data_model: {
                            current_app: false,
                            interpretation: [{
                                src: 'form',
                                id: 'with_text',
                                settings: {
                                    editable: 1,
                                    show_field_name: 1,
                                    show_field: 1
                                }
                            }]
                        }
                    };
                }
            }]]
      }]

  }
}