import Rete from "rete";
import {MainControl} from "./../controls/main_control.js"
import {ifNoConnections} from "./../controls/utilities.js"
import {items} from "./../controls/sockets.js"
import {act} from "./../../actions/actions.js"






export class Filter extends Rete.Component {
  constructor(){
      super("Filter");
  }

  builder(node) {
      var outItems = new Rete.Output('outItems', "Items", items);
      var inpItems = new Rete.Input('inpItems',"Items", items);
      var cntr = new MainControl( this.editor, 'text', false, node, this.getSettings(), this.getTemplate() );

      return node
          .addControl(cntr)
          .addInput(inpItems)
          .addOutput(outItems);
          
  }

  async worker(node, inputs, outputs, triggeredData, resolve) {
      let app_id = node.data.app_id;
      let filters_list = node.data.filters_list;
      let items = inputs['inpItems'][0];

      //-- Filltering
      let filteredItems = await act.filter(items, filters_list);
      outputs['outItems'] = filteredItems;

      //-- checking if there are output connections
      //-- If there no outputs then we stop proccesing
      if( ifNoConnections(node.outputs) )
        resolve(filteredItems);

  }

  getTemplate() {
    return {
      name: 'Filter',
      icon: 'filter',
      constructor: 'node',
      data_model: {
        app_id: '',
        filters_list: []
      }
    }
  }


  getSettings(){
    return [{
            title: 'Options',
            type: 'general_setting',
            icon: 'menu',
            columns_list: [[
              {
                title: 'Default Settings',
                type: 'header'
              },
              {
                type: 'ghElement',
                property: 'app_id',
                data_model: function (fieldModel) {
                    return {
                        field_name: 'Application',
                        data_type: 'app',
                        data_model: {
                            current_app: false,
                            interpretation: [{
                                src: 'form',
                                id: 'with_text',
                                settings: {
                                    editable: 1,
                                    show_field_name: 1,
                                    show_field: 1
                                }
                            }]
                        }
                    };
                }
            },{
              type: 'html',
              onInit: function (settingScope,) {
                settingScope.$watch(function () {
                  return settingScope.fieldModel.app_id;
                }, function(newValue) {
                  settingScope.field_model.recipient.app_id = newValue;
                });
              },
              data_model: function (fieldModel) {
                return {
                  recipient:{
                    app_id: fieldModel.app_id
                  }
                };
              },
              control:
                '<gh-filter gh-filter-data-model="field_model" filter-list="fieldModel.filters_list" gh-mode="variable"></gh-filter>'
            }]]
      }]
  }
}