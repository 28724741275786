angular.module('sliderModule', [])
    .service('sliderService', ['$q', 'PipeService', 'storage', '$window', function ($q, PipeService, storage, $window) {

        this.getApplist = function (showApp) {
            var deferred = $q.defer();
            var address = {
                recipient: 'all'
            };
            
            PipeService.on('gh_apps_list_get', address, function app_listPipe(event, storageAppsList) {
                PipeService.destroy('gh_apps_list_get', address, app_listPipe);
                let tabs = [];
                // Temporary fix for deleting default application (app to init) from the app_list
                var user_app_init = storage.getUser().app_init;

                angular.forEach(storageAppsList, function (value, key) {
                    if (value.app_id == user_app_init) {
                        storageAppsList.splice(key, 1);
                    }
                });
                // -----------------------------

                let appsList = storageAppsList.filter(app => app.from_apps_list);
                if(showApp){
                    tabs = createTabsList(appsList);
                }else{
                    tabs = createHideTabsList(appsList);
                }

                if(typeof tabs == "undefined"){
                    tabs = []
                }
                deferred.resolve(tabs);

            }).emit('gh_apps_list_get', {}, address);
            return deferred.promise;
        }

        function createHideTabsList(appsList){
            appsList = appsList.filter(app => app.show != false)
            return createTabsList(appsList);
        }

        function createTabsList(appsList) {
            var tabs = [];
            // counts the number of items for one slide
            var itemsInTab = 18;
            if ($window.innerWidth > 1280) {
                itemsInTab = 18;
            } else if ($window.innerWidth > 1000) {
                itemsInTab = 15;
            } else if ($window.innerWidth > 750) {
                itemsInTab = 12;
            } else if ($window.innerWidth > 500) {
                itemsInTab = 9;
            } else {
                itemsInTab = 6;
            }
            var tabCount = Math.ceil((appsList.length+1) / itemsInTab);
            

            // formation of arrey for repeat
            if (tabCount && appsList.length) {
                for (var i = 0; i < tabCount; i++) {
                    let firstIndex = i == 0 ? 0 : i * itemsInTab - 1;
                    let lastIndex = itemsInTab - 1 + i * itemsInTab;
                    tabs[i] = appsList.slice(firstIndex, lastIndex);
                    
                    firstIndex = lastIndex;
                    lastIndex = lastIndex + i * itemsInTab;
                }
                return tabs;
            }
        }

        return this;
    }]);