require('./gh_input_list.scss');
angular.module('ghInputListModule', [])

.directive('ghInputList', ['$compile', function($compile) {
  var directive = {};

  directive.scope = {
    ghModel: '=',
    ghDropdown: '=',
    onEnterNewDropdownValue: '&?',
    ghField: '=?',
    showLoader: '=?',
    ghDataType: '@?',
    addButtonName:'@',
    ghEditable: '=?',
    ghRegularExp: '=?'
  };

  directive.controller = [ '$scope', '$element', '$attrs', function($scope, $element, $attrs) {
    $scope.to_ghInput = '';
    $scope.showInput = true; // this variable shows/hide input from input_list
    $scope.editable = true; // this variable shows/hide input from input_list
    $element.empty();
    //--- here we hide inout if it has button name
    if( $scope.addButtonName )
      $scope.showInput = false;
      $scope.showdelet = true;
      if($scope.ghEditable == false ){
        $scope.editable = false;
      }
    $scope.defaultFieldModel = {
      data_model: {
        options:  $scope.ghDropdown
      }
    };

    $scope.decoratedFieldModel = angular.merge(angular.copy($scope.ghField || $scope.defaultFieldModel), {
      data_model: {
        multiple_value: false,
        show_field_name: false
      },
      settings: {
        editable: false
      }
    });


    if ($scope.ghDataType) {
      $scope.decoratedFieldModel.data_type = $scope.ghDataType;
    }

    Object.defineProperty($scope, 'proxyModel', {
      set: function(val) {
        if (Array.isArray($scope.ghModel)) {
          $scope.ghModel = val;
        } else {
          $scope.ghModel = val.join(',');
        }
      },
      get: function() {

        // don't show items when option deleted
        var valuesForExistingOptions = function(arg) {
          if (angular.isDefined($scope.ghDropdown)) {
            return $scope.ghDropdown.find(function(a) {
              return a.value == arg;
            });
          } else {
            return true;
          }
        };

        if (Array.isArray($scope.ghModel)) {
          return $scope.ghModel.filter(valuesForExistingOptions);
        } else {
          return $scope.ghModel && $scope.ghModel.toString().split(',').filter(valuesForExistingOptions) || [];
        }
      }
    });


    $scope.$watch('to_ghInput', function(n) {
      var temp = $scope.proxyModel;
      if (angular.isDefined(n) && n !== '' && temp.indexOf(String(n)) === -1) {
        temp.push(n);
        $scope.proxyModel = temp;


        //--- here we hide inout if it has button name
        if( $scope.addButtonName )
          $scope.showInput = false;
          $scope.showdelet = true;
      }
      $scope.to_ghInput = '';
    });

    $scope.deleteItem = function(index) {
      var temp = $scope.proxyModel;
      temp.splice(index, 1);
      $scope.proxyModel = temp;
    };
    if(typeof $scope.ghEditable == "undefined" ){
      $scope.ghEditable = true;
    }
    
    var dropdown = angular.isDefined($attrs.ghDropdown) ? ' gh-dropdown="ghDropdown"' : '';
    var autocomplete = $attrs.autocomplete ? ' autocomplete="' + $attrs.autocomplete + '"' : '';
    var size_options = {
      small: {
        list_item: 'gh-list-small'
      },
      large: {
        list_item: 'gh-list-large'
      }
    };

    var inputInTemplate = $scope.ghEditable ? '<div class="input-list-item multiple" style="display: inline-block;" ng-show="showInput && editable">' +
    '			<gh-input show-loader="false"  gh-regular-exp="ghRegularExp"  gh-field="decoratedFieldModel" on-enter-new-dropdown-value="onEnterNewDropdownValue({value: value})" model-update="keyup update_by_enter" ng-model="to_ghInput" ' + autocomplete + dropdown + ' size="' + $attrs.size + '" gh-data-type="' + $attrs.ghDataType + '"></gh-input>' +
    '		</div>' : '';
    
    var template = '' +
    '		<div ng-repeat="item in proxyModel track by $index" ng-class="(editable) ? \'list-item\' : \'list-item-notEditable\'" class="gh-button gh-button-inversed gh-shadow-s ' + size_options[$attrs.size].list_item + '">' +
    '     <gh-element class="gh-input-list_name" ng-show="!showLoader || !$last" value="item" decorator="decoratedFieldModel" elem-src="input_list"></gh-element>' +
    '     <span ng-show="(!showLoader && editable) || (!$last && editable)" ng-click="deleteItem($index)" gh-icon="cancel 0893d2 100% normal" class="gh-hover_close cross"></span>' +
    '		</div>' + inputInTemplate +
    '<span class="block_icon_delet icon plus gh-plus" gh-icon="cancel bbbcc5 22px normal" ng-click="showInput = false" ng-show="showInput && editable" ng-hide="showdelet"></span>'+
    '   <div ng-click="showInput =!showInput" class="block_add" ng-show="!showInput && editable"><span class="block_add_icon icon plus gh-plus" gh-icon="plus bbbcc5 22px normal"></span>'+
    '   <p class="block_add_text">{{addButtonName}}</p></div>';

   
    var el = $compile(angular.element(template))($scope);
    $element.append(el);

  }];


  return directive;
}]);
