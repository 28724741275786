require('./gh_option_table.scss');

angular.module('ghOptionTableModule', [
  'dndLists'
])

/*============================================================================================================*/
/*============================================  TABLE CONTROL  ===========================================*/
/*============================================================================================================*/
.directive('ghOptionTable', ['$compile', 'formEditorService', function($compile, formEditorService) {
	return {
    restrict: 'E',
    scope: {
      items: '=',
      pattern: '=',
      addOptions: '@',
      pagination: '@'
    },
    template:
          //'	<div class="clearfix">' +
          '		<div class="thead">' +
          '				<div class="item icon"></div>' +
          '				<div class="item icon">#</div>' +
          '				<div class="item" ng-repeat="ex in ::pattern" ng-show="ex.display">{{ex.prop_name[0].toUpperCase() + ex.prop_name.slice(1)}}</div>' +
          '				<div ng-if="addOptions == \'true\'" class="item" style="min-width:64px; max-width: 64px">Edit</div>' +
          '		</div>' +
          '		<ul class="option-list" dnd-list="items" dnd-external-sources="false">' +
          '			<li ng-repeat="option in items | paginationFilter: itemsToView : pagination" dnd-draggable="option" dnd-moved="items.splice($index, 1)" dnd-effect-allowed="move">' +
          '				<div class="item icon move" gh-icon="menu EDF2F7 32px normal"></div>' +
          '				<div class="item icon text-item">{{$index + 1}}</div>' +
          '       <option-setting class="item" ng-repeat="ex in pattern" ng-show="ex.display" option="option" pattern="ex"></option-setting>' +
          '				<div ng-if="addOptions == \'true\'" class="item icon" edit-setting-field gh-icon="pencil 0893d2 25px normal"></div>' +
          '				<div ng-if="addOptions == \'true\'" class="item icon" ng-click="items.splice($index, 1)" gh-icon="rubbish 0893d2 32px normal"></div>' +
          '			</li>' +
          '		</ul>' +
          /*-- Add Option Button*/
          ' <div ng-if="(items.length > itemsToView) && pagination" ng-click="loadMore()" class="gh-btn gh-btn-bg gh-btn-blue">' +
          '   Load More' +
          ' </div>' +
          '	<div ng-if="addOptions == \'true\'" class="add-option">' +
          '   <div edit-setting-field class="action gh-btn inline gh-btn-blue gh-btn-bg gh-btn-lg"><p>Add Option</p></div>'+
          //'		<div class="col-md-12">' +
          //'			<button edit-setting-field style="cursor:pointer; width: 100%" class="btn btn-primary btn-lg btn-block bnt-dotted transition-disabled" type="button">Add Option</button>' +
          //'		</div>'+
          '	</div>',
    controller: ['$scope', '$element', function($scope, $element) {
      $scope.editForm = {};
      $scope.optionTableForm = $element;
      const pageLimit = 20;
      $scope.itemsToView = 10;
      $scope.items = $scope.items || [];
      $scope.loadMore = function () {
        if($scope.itemsToView < $scope.items.length){
         $scope.itemsToView = pageLimit + $scope.itemsToView;
        }
      }
      $scope.addOptions = $scope.addOptions || 'true';
    }]
  };

}])

.filter('paginationFilter', () => {
  return (items, itemsToView, pagination) => {
    return pagination ? items.filter((item, index) => index < itemsToView) : items
  }
})

// ------------------------------------- INTERPRITATE SETTING VALUE ----------------------------------------
.directive('optionSetting', ['$compile', 'interpritate', function($compile, interpritate) {
  return {
		restrict: 'E',
		scope:{
			option: '=',
			pattern: '=',
      elemSrc: '@'
		},
		controller: ['$scope', '$element', '$timeout', function($scope, $element, $timeout){

      var privateOptionFieldScope = $scope.$new();

      $scope.field_model = {
        data_model:{},
        data_type: $scope.pattern.type
      };

      if ($scope.pattern.isDynamic) {
        privateOptionFieldScope.$watch('dynamicPatternGhElementSource.field_id', function(newValue, oldValue) {
          if(newValue && newValue != oldValue){
            renderSettingField();
          }
        });
      }

      $scope.$watch('option', function(newValue, oldValue) {
        if(newValue != oldValue){
          renderSettingField();
        }
      }, true);


      //-------- RENDERING FIELDS SETTINGS -------------
      function renderSettingField() {

        // Hide field name for all field
        angular.merge($scope.field_model.data_model, angular.merge($scope.pattern.data_model($scope.option, privateOptionFieldScope) || {}, {
          interpretation:[
            {
              src: $scope.elemSrc || $scope.pattern.interpretation || 'table',
              settings:{
                show_field_name: 0
              }

            }
          ]
        }));

        privateOptionFieldScope.field_model.data_model = $scope.field_model.data_model;

        var template;
        if ($scope.pattern.isDynamic) {
          delete privateOptionFieldScope.field_model.data_type;
          template = '<div gh-element decorator="field_model" app-id="{{dynamicPatternGhElementSource.app_id}}" field-id="{{dynamicPatternGhElementSource.field_id}}" elem-src="'+ ($scope.elemSrc || $scope.pattern.interpretation || 'table') +'" value="option[pattern.property]" ></div>';
        } else {
          template = '<div gh-element decorator="field_model" elem-src="'+ ($scope.elemSrc || $scope.pattern.interpretation || 'table') +'" value="option[pattern.property]" class="gh-pattern" ></div>';
        }


        $element
          .empty()
          .append($compile(angular.element(template))(privateOptionFieldScope));

      }
      renderSettingField();

		}]
	};
}])

.directive('editSettingField', ['$compile', function($compile) {
  return {
    restrict: 'A',
    link: function(scope, element) {

      const optionTableService = new OptionTableService($compile, scope);
      var editFormContainer = element.parent();

      scope.addOption = function() {

        
        var isAllInputsFilled = scope.pattern.every(function(pattern) {
					return !pattern.display || scope.editForm[pattern.property] ||String(scope.editForm[pattern.property]);
				});

        if (!isAllInputsFilled) {
          return;
        }

        if (angular.isDefined(scope.$index)) {
          scope.items[scope.$index] = angular.copy(scope.editForm);
        } else {
          /* Add generate dynamic value*/
          angular.forEach(scope.pattern, function(property) {
            if (property.generate_dynamic) {

              scope.editForm[property.prop_name] = +property.getMaxValue() + 1;
              property.setMaxValue(scope.editForm[property.prop_name]);

            }
          });

          scope.items.push(angular.copy(scope.editForm));
        }
        
        scope.close();
      };

      scope.close = function() {
        optionTableService.removeForm();
        // Delete form from elemnt
        scope.optionTableForm.find('edit-form-field-setting').remove();
      };

      element.on('click', function() {
        angular.forEach(scope.pattern, function(pattern) {
          scope.close();
          scope.editForm[pattern.property] = angular.isDefined(scope.$index) ? scope.option[pattern.property] : null;
        });

        editFormContainer.append(optionTableService.getEditFormTemplate());
      });
    }
  };
}])




class OptionTableService {
  constructor($compile, scope) {
    this.$compile = $compile;
    this.scope = scope;
    this.editFormTemplate =
        '<edit-form-field-setting>' +
        '   <div class="item icon"></div>' +
        '   <div class="item icon"></div>' +
        '   <option-setting class="item" ng-repeat="ex in pattern" ng-show="ex.display" option="editForm" pattern="ex" elem-src="form" ></option-setting>'+
        '   <div class="item icon" gh-icon="checked 00ff00 32px normal" ng-click="addOption()" ></div>' +
        '	  <div class="item icon" gh-icon="cancel ff0000 32px normal" ng-click="close()"></div>' +
        '</edit-form-field-setting>';
  }

  getEditFormTemplate() {
    this.privateFormEditScope = this.scope.$new();
    return this.$compile(angular.element(this.editFormTemplate))(this.privateFormEditScope)
  }

  removeForm () {
    if(angular.isDefined(this.privateFormEditScope)){
      this.privateFormEditScope.$destroy();
    }
  };
}