require('./gh_window.scss');
import ContextMenu from './../../gui/context_menu';

/**************************************************************************************************************/
/**********************************************    GH-WINDOWS     *********************************************/
/**************************************************************************************************************/

angular.module('ghWindowModule', [
    'ghConstructor'
])





/*=========================================================================================================*/
/*========================================  GH-WINDOWS DIRECTIVE   =========================================*/
/*=========================================================================================================*/
.directive('ghWindows', [ '$compile', 'ghWindowsService', function($compile, ghWindowsService) {
  return {
      restrict: 'E',
      scope: {},
      template: '<gh-window ng-if="!hideAllExceptLast || $last" window="windows[$index]" ng-repeat="window in windows" class="{{window.window_mode}}" window-model=\"window\" style=\"z-index:{{$index+1}};\"></gh-window>',

      controller: [ '$scope', '$routeParams', '$injector', '$element', '$location', function($scope, $routeParams, $injector, $element, $location) {
          /*-- saving models list for all windows*/
          $scope.windows = [];
          /*--Binding GH-WINDOWS with Window service*/
          ghWindowsService.init($scope);

          $scope.$on('$locationChangeSuccess', function(){
            var current_url = $location.path();
            var patt = new RegExp(/^\/act(?:\/([^\/]+)?)?(?:\/([^\/]+)?)?(?:\/([^\/]+)?)?(?:\/([^\/]+)?)?$/);

            if(patt.test(current_url)){
              if($element.css('display') != 'flex'){
                $element.css('display','flex');
              }
            } else {
              $element.css('display','none');
            }
          });

          $scope.hideAllExceptLast = false;

          $scope.changeVisibilityPreviousWindowsInDOM = function (isHide) {
            $scope.hideAllExceptLast = isHide;
          };

      }],

      link: function(scope, element, attrs) {

      }

  };
}])

/*-----------------------------  GH-WINDOW-NEW  -------------------------------*/
.directive('ghWindow', [ '$compile', '$route', '$location', 'cnfg', 'ghWindowsService', 'GHConstructor', 'PipeService', function($compile, $route, $location, cnfg, ghWindowsService, GHConstructor, PipeService) {
  return {
      restrict: 'E',
      scope: {
          window: '='
      },
      controller: [ '$scope', function($scope) {
          /* Close current window*/
          $scope.destroyWindow = function() {
              ghWindowsService.windowDestroy($scope.window.window_id);
          };

      }],
      link: function(scope, element, attrs) {


            GHConstructor.getInstance(scope.window.window_mode).then(function(response) {

                response.getWindowScope(scope);
                var el = angular.element( response.getWindowHTML(scope) );
                element.empty();
                element.append($compile(el)(scope));

                var addressApp = {
                    app_id: scope.window.app_id
                };

                PipeService.on('gh_app_info_get', addressApp, function app_listPipe(event, storageAppsList) {
                    PipeService.destroy('gh_app_info_get', addressApp, app_listPipe);
                    if(cnfg.show_context_menu && storageAppsList.permission == 4 || cnfg.show_context_menu && storageAppsList.permission == 3){
                        let contextMenu = new ContextMenu(element[0], [{
                            title: 'Edit template',
                            events: {
                            click: function (e) {
                                scope.$evalAsync(() => {
                                // 'param_2' is our view_id
                                $location.path('act/edit_template/' + scope.window.app_id + '/' + $route.current.params.param_2);
                                });
                            }
                            }
                        }]);
                    }
                }).emit('gh_app_info_get', {}, addressApp);

               
               
            });
        }
  };
}])



/*=======================================================================================================*/
/*========================================  GH-WINDOWS SERVICE   =========================================*/
/*=======================================================================================================*/
.service('ghWindowsService', [ 'ActionService', '$injector', '$timeout', function(ActionService, $injector, $timeout) {
  var windowsScope = {};

  var self= this;

  this.isWindowOpened = function (window_id){

    var isOpened = false;
    angular.forEach(windowsScope.windows, function(window, index){
      if(window.window_id == window_id){
        isOpened = true;
        /* -- Swap two elements of window in windows array -- */
        var windowsArr = windowsScope.windows;
        windowsArr[windowsArr.length - 1] = [windowsArr[index], windowsArr[index] = windowsArr[windowsArr.length - 1]][0];
      }
    });

    return isOpened;
  };

  /*------------- INITIALIZATION -------------*/
  this.init = function(scope) {
      windowsScope = scope;
  };

  /*--------------- NEW WINDOW --------------*/
  this.newWindow = function(actionType, scope) {
      var windowId = actionType + ( scope.app_id ? '_'+ scope.app_id : '' ) + ( scope.item_id ? '_'+ scope.item_id : '' ) + ( scope.view_id ? '_'+ scope.view_id : '' );

      /* Check if we want open window twice*/
      if(!self.isWindowOpened(windowId)){
        var windowScopeProp = {
            window_id: windowId, /*-- Window ID is equals windows url --*/
            window_mode: actionType,
            layer_position: windowsScope.windows.length,
            item_id: scope.item_id,
            app_id: scope.app_id,
            data: {},
        };

        angular.extend(windowScopeProp, scope);
        
        /*-- Creating new window in the GH-WINDOWS*/
        windowsScope.windows.push(windowScopeProp);

        if ($injector.get(actionType).getTemplate().hidePreviousWindows) {
          windowsScope.changeVisibilityPreviousWindowsInDOM(true);
        }
      }
  };

  this.windowDestroy = function(windowId) {
    
    if (!windowId) {
      ActionService.selectedItems = {};
      windowsScope.windows = [];
      return;
    }

    let windowToDestroy = windowsScope.windows.find((w) => w.window_id == windowId);

    if (windowToDestroy) {
      let windowSettings = $injector.get(windowToDestroy.window_mode).getTemplate();
      windowsScope.windows.splice(windowsScope.windows.indexOf(window), 1);

      if(windowSettings.run_on_rout){
        window.history.go(-1);
      }

      if(windowSettings.hidePreviousWindows){
        windowsScope.changeVisibilityPreviousWindowsInDOM(false);
      }
    }
    // re run digest for add items and update items
    $timeout(function() {
        windowsScope.$digest();
    }, 0, false)
  };

  this.destroyAllWindows = function() {
      return new Promise(resolve => {
          windowsScope.windows.forEach(windowToDestroy => {
              const windowSettings = $injector.get(windowToDestroy.window_mode).getTemplate();
              if(windowSettings.run_on_rout){
                  window.history.go(-1);
              }

              if(windowSettings.hidePreviousWindows){
                  windowsScope.changeVisibilityPreviousWindowsInDOM(false);
              }
          })
          windowsScope.windows = []
          $timeout(() => {
              windowsScope.$digest();
              resolve()
          },0, false)
          })
      }

}])





/*=========================================================================================================*/
/*===========================================  ACTION SERVICE =============================================*/
/*=========================================================================================================*/
.service('ActionService', [ 'appDataProcesingService', function(appDataProcesingService) {

    var self = this;
    /* ----------------- Selected Items in table -------------------------- */
    /* Object of selected items*/
    /* {*/
    /*      "35":{                   /application ID*/
    /*          items: [{}, {}, ...]     / link for objects of selected items*/
    /*          app: [{}, {}, ...]     / link on application*/
    /*      }*/
    /* }*/
    this.selectedItems = {};
    /* Add select item link to array*/
    this.selectedTableItems = function(appId, item) {

        /* If we not have object of app, create it*/
        if (angular.isUndefined(self.selectedItems[appId])) {
            self.selectedItems[appId] = {
                items: [],
                app: []
            };

            /* Set link to app*/
            appDataProcesingService.getApp(appId).then(function(result) {
                self.selectedItems[appId].app = result;
            });
        }

        var itemObject = self.selectedItems[appId];

        /* Get array of selection items ID*/
        var selectedItemsIds = itemObject.items.map(function(obj) {
            return obj.item_id;
        });

        /* if itemId preset in array*/
        if (selectedItemsIds.indexOf(item.item_id) != -1) {
            itemObject.items.splice(selectedItemsIds.indexOf(item.item_id), 1);
            return;
        }
        itemObject.items[itemObject.items.length] = item;
    };
    /* Get array of selected Items*/
    this.getSelectedTableItems = function(appId) {
        /* Return items with fields list*/
        return self.selectedItems[appId] ? self.selectedItems[appId] : {items:[],app:[]};
    };
    this.cleanSelectedTableItems = function(appId) {
        if(self.selectedItems[appId]){
        self.selectedItems[appId].items = [];
        }
    };

    // -- Needs for send some data beetwen two routers --
    this.bufferData = {};
    /* ---------------------------------------------------------------- */
    return this;
}]);



