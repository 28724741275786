require('./filter_item.js');
require('./advanced_filter.js');
require('./filterPreparation.js');

// example
// values_array = [1,2]; "+" - matched by filter, "-" - not matched by filter
// +===============+=============+============+=================+================+
// | field_value   | Equals(and) | Equals(or) | Not Equals(and) | Not Equals(or) |
// +===============+=============+============+=================+================+
// |     [1,2]     |      +      |     +      |        -        |       -        |
// +---------------+-------------+------------+-----------------+----------------+
// |     [1,3]     |      -      |     +      |        -        |       +        |
// +---------------+-------------+------------+-----------------+----------------+
// |     [3,4]     |      -      |     -      |        +        |       +        |
// +---------------+-------------+------------+-----------------+----------------+
// |     [1]       |      -      |     +      |        -        |       -        |
// +---------------+-------------+------------+-----------------+----------------+
// |     [3]       |      -      |     -      |        +        |       +        |
// +---------------+-------------+------------+-----------------+----------------+
// |     []        |      -      |     -      |        +        |       +        |
// +---------------+-------------+------------+-----------------+----------------+
// |     [1,2,3]   |      +      |     +      |        -        |       +        |
// +---------------+-------------+------------+-----------------+----------------+
// |     [1,2,2]   |      +      |     +      |        -        |       -        |
// +---------------+-------------+------------+-----------------+----------------+


angular.module('tableFilter', [
  'ghConstructor',
  'filterItemModule',
  'advancedFilteringModule',
  'filterPreparationModule'
])

/*-- advanced filter is used to filter with help of Filter menu*/
  .filter('advanced', ['filterAggregate', 'filterChecker', function (filterAggregate, filterChecker) {
    return function (items, filters) {
      var filtered = [];
      if (!items || !items.length) {
        return [];
      }

      return items.filter(function (item) {
        return filters
          .filter(function (filter) {
            return filter.valuesArray.length;
          })
          .every(function (filter) {
            var currField = item.fields.filter(function (itemField) {
              return filter.field_id == itemField.field_id;
            })[0];

            filterAggregate.setStrategy(filter.search_type).setEntity(currField && currField.field_value || null).setFilterValues(filter.valuesArray);
            return filterChecker.check(filterAggregate);
          });
      });
    };

  }])

  /* Sort table column DESC or ASC*/
  .filter('orderSort', function() {
    return function(items, model) {

      /*-- stop filtering if arguments are empty*/
      if (!items || !items.length) {
        return [];
      }

      if (!items[0].fields.length) {
        return items;
      }

      var compareFieldId = model && model.id || items[0].fields[0].field_id;

      /* Get field value*/
      function getFieldValue(item) {
        var result = null;
        for (var i = 0; i < item.fields.length; i++) {

          if (item.fields[i].field_id == compareFieldId) {
            result = item.fields[i].field_value;
            break;
          }

        }
        return result;

      }

      /* Sort items array*/
      items.sort(function(a, b) {
        var aVal = getFieldValue(a);
        var bVal = getFieldValue(b);

        if (aVal === null) {
          return -1;
        }
        if (bVal === null) {
          return 1;
        }

        if (/^\d+$/.test(aVal) && /^\d+$/.test(bVal)) {
          return Number(aVal) < Number(bVal) ? -1 : 1;
        }

        if (/^\d+$/.test(aVal) || /^\d+$/.test(bVal)) {
          return /^\d+$/.test(aVal) ? -1 : 1;
        }

        return angular.lowercase(aVal) > angular.lowercase(bVal) ? 1 : -1;
      });
      return model && model.descending ? items.reverse() : items;
    };
  })

  .filter('searchValue', [function() {
    return function(items, filter) {

      var filtered = [];
      if (!items || !items.length) return;

      if (filter) {
        angular.forEach(items, function(item) {
          var ifCompare = false;
          angular.forEach(item.fields, function(field) {
            if (field.index_value && field.index_value.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
              ifCompare = true;
            }
          });

          if (ifCompare) {
            filtered.push(item);
          }

        });
        return filtered;
      }
      return items;
    };
  }])


  .filter('itself', [function() {
    return function(items, filter) {
      if (!items || !items.length || !filter.active) return items || [];

      // temporary
      if (filter.field_to_filter) {
        filter.field_id = filter.field_to_filter;
      }
      // temporary

      return items.filter(function(item) {
        return item.fields.some(function(field) {
          return String(filter.field_id).split(',').some(function(eachFieldId) {
            return field.field_id == eachFieldId && field.field_value && field.field_value.split(',').indexOf(filter.value) !== -1;
          });
        });
      });
    };

  }])
