import Rete from "rete";
import {NoEditControl} from "../controls/no_edit_control.js"
import {items} from "../controls/sockets.js"
import {ifNoConnections} from "../controls/utilities.js"





export class GhElementNode extends Rete.Component {
  constructor(){
      super("Gh Element Node");
  }

  builder(node) {
      var updatedItems = new Rete.Output('updatedItems', "Updated Items", items, true);
      var cntr = new NoEditControl( this.editor, 'text', false, this.getTemplate() );

      return node
          .addControl(cntr)
          .addOutput(updatedItems);
          
  }

  async worker(node, inputs, outputs, triggeredData, resolve) {
    outputs['updatedItems'] = triggeredData;

    if( ifNoConnections(node.outputs) )
      resolve(null);    
  }

  
  getTemplate() {
    return {
      name: 'Gh Element Node',
      icon: 'remote_add',
      constructor: 'trigger', //-- if constructor is "node" then we can add it 
      data_model: {}
    }
  }


  getSettings(){
    return [];
  }
}