 
 angular.module('filterPreparationModule', [])
  .factory('filterPreparationFactory', ['$q', 'PipeService', 'storage', function($q, PipeService, storage){
    return {
      callbackFactory: function(){},

      startWatchFilterField: function(filter_models, appId, itemId, callback){
        this.callbackFactory = callback;
        filter_models.forEach(filter => {
          if(filter.input_type == "field"){
            let address = {
              app_id: appId, 
              item_id: itemId, 
              field_id: filter.input_value
            }
            PipeService.on('gh_value_update', address, callback);
            
          }
        });
      },

      destroyPipeFilter: function(filter_models, appId, itemId){
        filter_models.forEach(filter => {
          if(filter.input_type == "field"){
            let address = {
              app_id: appId, 
              item_id: itemId, 
              field_id: filter.input_value
            }
            PipeService.destroy('gh_value_update', address, this.callbackFactory);
          }
        });
      },
  //========================================== GET FILTER FOR GH-FILTER ==============================================//
    /*--------------------------------------------------------------------------------------------------------------------
    |  Needs to load data before data type ot load yet
    |  Params:
    |    'field' - Object of field (require).
    |      {
    |        .......,
    |        filters_list: ''
    |        ........
    |      }
    |
    |------------------------------------------------------------------------------------------------------------------*/

    filterPreparation: function(filters_list, element_app_id, current_app_id, item_id){
      var deferred = $q.defer();
      var filterArray = [];

      var objectMethod = {
        variableMethodcurrent_app: function(filter){
          filter.valuesArray = [current_app_id]
          filterArray.push(filter)
        },
        variableMethodelement_app: function(filter){
          filter.valuesArray = [element_app_id]
          filterArray.push(filter)
        },
        variableMethodcurrent_item: function(filter){
          var currentValue = current_app_id + "." + item_id
          filter.valuesArray = [currentValue];
          filterArray.push(filter)
        },
        variableMethoduser_id: function(filter){
          var storage_user = angular.copy(storage.getMainStorage().user);
          filter.valuesArray = [storage_user.user_id]
          filterArray.push(filter)
        },
        variableMethoduser_email: function(filter){
          var storage_user = angular.copy(storage.getMainStorage().user);
          filter.valuesArray = [storage_user.username]
          filterArray.push(filter)
        },
        variableMethodtoday: function(filter){
          filter.valuesArray = [];
          let date = new Date();
          let start_date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
          let end_date =  new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
          let result = start_date.valueOf().toString() + ':' + end_date.valueOf().toString();
          filter.valuesArray = [result]
          filterArray.push(filter)
        }
      }
      if(filters_list){
        for(var i = 0; i < filters_list.length; i++){
          var namePromice = promiceFilter();
          
          function promiceFilter(){
          var deferred = $q.defer();
            if(filters_list[i].input_type){
              switch(filters_list[i].input_type){
                case 'variable':
                  var functionName = filters_list[i].input_type + "Method" + filters_list[i].input_value;
                  for(var nameFunction in objectMethod){
                    if(nameFunction == functionName){
                      objectMethod[nameFunction](filters_list[i])
                      deferred.resolve();
                    }
                  }
                  break;
                case 'field':
                  fieldMethod(filters_list[i]).then(function(){
                  deferred.resolve();
                  })
                  break;
                default: 
                  filterArray.push(filters_list[i])
                  deferred.resolve();
                  break;
              }
            }else{
              filterArray.push(filters_list[i])
              deferred.resolve();
            }
            return deferred.promise;        
          }
        
        }
      }
      function fieldMethod(filter){
        var deferred = $q.defer();
        
        let modelAddress = {
          app_id: current_app_id,
          item_id: item_id,
          field_id: filter.input_value
        };
        PipeService.on('gh_value_get', modelAddress, function ghValueGet(event, field_value) {
          PipeService.destroy('gh_value_get', modelAddress, ghValueGet);
          filter.valuesArray.push(field_value)
          filterArray.push(filter)
          deferred.resolve();
        }).emit('gh_value_get', {}, modelAddress);

        return deferred.promise;        
      }

      $q.all(namePromice).then(function(data) {
        deferred.resolve(filterArray);
      });
      
      return deferred.promise;        
    }
  }
}])
 
 