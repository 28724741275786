import {Filter} from "./modules/filter_node.js"
import {Trigger} from "./modules/trigger_node.js"
import {GetItemByItemRef} from "./modules/get_item_by_item_ref.js"
import {PopulateItems} from "./modules/populate_items.js"
import {UpdateItemsApi} from "./modules/update_items_api.js"
import {GhElementNode} from "./modules/gh_element_node.js"
import {PopulateElement} from "./modules/populate_element.js"
import {GetItems} from "./modules/get_items.js"
import {PopulateWithDate} from "./modules/populate_with_date.js"


export const Modules = {
  Filter : Filter,
  GetItemByItemRef : GetItemByItemRef,
  Trigger : Trigger,
  PopulateItems : PopulateItems,
  UpdateItemsApi : UpdateItemsApi,
  GhElementNode : GhElementNode,
  PopulateElement : PopulateElement,
  GetItems : GetItems,
  PopulateWithDate : PopulateWithDate
}