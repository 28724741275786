require('./../../config.js');

angular.module('ghAppSlider', [
    // 'dndLists'
])

    .directive('appsSlider', [function() {
        return {
            restrict: 'E',
            scope: {
                showCheck: '='
            },
            template: '  <div class="container_slider" md-selected="selected_index" ng-style="{\'transform\':sliderTransform ,\'width\':sliderWidth }">' +
                '     <ul class="flex flex-row gh_slider_wrap">' +
                '       <li ng-if="appList.length == 0" class="flex-1" ng-style="{\'max-width\': IconsColumnWidth  + \'%\', \'min-width\': IconsColumnWidth  + \'%\'}">' +
                '         <div gh-element decorator="{data_model:{interpretation:[{src: \'form\', id: \'name\', settings: {show_field_name:false}}]},data_type: \'app_constructor\'}" elem-src="form" class="slider_svg"></div>' +
                '       </li>' +
                '     </ul>' +
                '   <div ng-repeat="tab in appList"  class="slide_item" label="&#8226" ng-init="tab.index = $index" md-on-select="selected_index = $index" ng-style="styleSliderItem" >' +
                '     <ul dnd-list="tab" class="flex flex-row gh_slider_wrap">' +
                '       <li ng-if="tab.index == 0 && $index == 0" class="flex-1" ng-style="{\'max-width\': IconsColumnWidth  + \'%\', \'min-width\': IconsColumnWidth  + \'%\'}">' +
                '         <div gh-element decorator="{data_model:{interpretation:[{src: \'form\', id: \'name\', settings: {show_field_name:false}}]},data_type: \'app_constructor\'}" elem-src="form" class="slider_svg"></div>' +
                '       </li>' +
                '        <li ng-if="removing_show || app.show" ng-repeat="app in tab" ng-style="{\'max-width\': IconsColumnWidth  + \'%\', \'min-width\': IconsColumnWidth  + \'%\', \'opacity\': app.show ? 1 : 0.5}" test="{{app.show}}">'+
                '           <a  ng-show="removing_show" ng-href="/act/app_constructor/{{app.app_id}}?tab=3" class="cancel transition-disabled slider_cancel" gh-icon="pencil ffffff 100% normal"></a>'+
                '          <label ng-show="showCheck" class="container_checkbox"><input    ng-model="confirmed" ng-change="change({{app.app_id}})"  class="cancel transition-disabled slider_cancel" type="checkbox" ><span class="checkmark"></span></label>'+
                '           <div gh-element app-id="{{app.app_id}}" decorator="{data_model: {open_app: true}, data_type: \'app\'}"  value="app.app_id" elem-src="table" class="slider_svg"></div>' +
                '        </li>'+
                '     </ul>' +
                '   </div>' +
                '</div>' +
                ' <div class="slider_button"></div>',
            controller: ['$scope', '$element','PipeService', 'sliderService', 'GhDialog', 'sendEmailService', 'sendInviteService', 'storage', 'cnfg', '$timeout', function($scope, $element, PipeService, sliderService, GhDialog, sendEmailService, sendInviteService, storage, cnfg, $timeout) {
                sliderService.getApplist($scope.removing_show).then(function(tabs){
                    $scope.appList = tabs;
                });
                $scope.$evalAsync(function() {
                    $scope.removing_show = false;
                });
                $scope.storage_user = angular.copy(storage.getMainStorage().user);
                $scope.appListToSend = [];

                $scope.change = function(appId){
                    var findIndex = $scope.appListToSend.findIndex(function(app){
                        return app.appId == appId
                    })
                    if(findIndex == -1){
                        $scope.appListToSend.push({app_id: appId, permission: 1})
                    }else{
                        $scope.appListToSend.splice(findIndex, 1)
                    }
                }
                $scope.$parent.cancelInvite = function(){
                    $scope.showCheck = false;
                }
                $scope.$parent.openPopUpSend = function(){
                     GhDialog.show({
                    	position: 'full',
                    	template: {
                    	toolbar: '<div class="dialog-delete-app"><p>Invite user</p>This application was share</div>',
                        content: '<div ng-show="!sendFinished" class="dialog-delgete-app-content">' +
                                '<div style="margin:  0 auto; width: 40%;"><gh-element value="sendEmail" style="display:  flex;" decorator="{data_model:{multiple: 1},data_type: \'email\', field_name: \'Email\'}" elem-src="form" data-position="" element-type="email"></gh-element></div>'+
                                '<div class="app_slider_table">'+
                                '   <table><tbody><tr class="app_slider_table_name_tr"><td>App</td><td>Permission</td></tr><tr ng-repeat="app in appList"><td><div gh-element app-id="app.app_id" elem-src="form"  decorator="decorator" value="app.app_id" class="invite_app"></div></td> ' +
                                ' <td ><gh-element value="app.permission" decorator="{data_model:{multiple: 1,options:[{name: \'Read\',value: 1,color: \'#FF0000\'}, {name: \'Write\',value: 2,color: \'#FF6600\'}, {name: \'Admin\',value: 3,color: \'#FF6600\'}],interpretation:[{src: \'form\',id: \'input\',settings: {editable: 1,show_field_name: 0,show_field: 1},style: {font_size: 12}}]},data_type: \'radio_button\'}"  elem-src="form" data-position="" element-type="radio_button"></gh-element></td></tr></tbody></table>' +
                                '</div>'+
                    			'   <button type="button" class="btn btn-grean" ng-click="apply()">Apply</button>'+
                                '   <button type="button" class="btn btn-blue-reverse" ng-click="cancel()">Cancel</button></div>' +
                                '<div ng-show="sendFinished" class="gh-csv-importing_finish">'+
                                '     <div class="app_icon" gh-icon="check_circle 10cfbd 150px app"></div>'+
                                '     <p class="gh-importing_wait">Successfully Completed</p>'+
                                '     <button ng-click="cancel()" class="btn btn-grean  gh-step_bttn"><span>Finish</span></button>' +
                                ' </div> '
                    	},
                    	locals: {
                            appIdlist: $scope.appListToSend,
                            user: $scope.storage_user
                    	},
                        controller: ['$scope', 'appIdlist', 'user', function($scope, appIdlist, user) {
                            $scope.appList = appIdlist;
                            $scope.sendFinished = false;
                            $scope.sendEmail = '';
                            $scope.decorator = {
                                data_type: 'app',
                                data_model: {
                                    interpretation: [{
                                        src: "form",
                                        id: "default_inline",
                                        settings: {
                                            editable: 0,
                                            show_field_name: 0,
                                            show_field: 1
                                        },
                                        style: {
                                            font_size: 20,
                                            position: "beetwen"
                                        }
                                    }]
                                }
                            };
                            
                            $scope.apply = function(){
                                $scope.content = {
                                    emails: $scope.sendEmail,
                                    companyName: 'Gudhub',
                                    body: '<!DOCTYPE html>' +
                                    '<html>' +
                                    ' <head>' +
                                    '   <title>Gudhub</title>' +
                                    ' </head>' +
                                    ' <body style="color: #000000;">' +
                                    '<p>Hello,<br />'+ user.fullname +' shared his applications with you. Please sign-up at <a href="' + cnfg.file_server_url +'/signup/'+ $scope.sendEmail +'">EasyStoneShop.com</a></p>' +
                                    '</body>' +
                                    '</html>'
                                }
                                if($scope.sendEmail){
                                    sendInviteService.sendInvite($scope.appList, $scope.sendEmail).then(function(){
                                        sendEmailService.sendEmail($scope.content, $scope.appList).then(function(){
                                            $scope.sendFinished = true;
                                        });
                                    });
                                }
                                
                            }
                        	}]
                        }).then(function(data) {});
                }
                let N = 0;
                let slideBack = 0;

                let i = 0, //-- Current Slide
                    x0 = null,
                    locked = false,
                    w, h, ini, fin, rID = null,
                    anf;

                const _C = $element[0].children[0];
                const NF = 20; //-- Number of frames (influence on speed )
                var address = {
                    recipient: 'all'
                };

                PipeService.on('gh_apps_list_update', address, function app_listPipe(event, storageAppsList) {
                    sliderService.getApplist($scope.removing_show).then(function(tabs){
                        $scope.appList = tabs;
                    });
                }, $scope);


                $scope.sliderTransform = "";
                $scope.sliderWidth = "";
                $scope.IconsPerRow = 0;
                $scope.IconsPerColumn = 0;

                $scope.$parent.runEditMode = function(){ 
                    $scope.showCheck = false;
                    $scope.removing_show = !$scope.removing_show;
                    $scope.$parent.removing_show_pencil = !$scope.$parent.removing_show_pencil;
                    sliderService.getApplist($scope.removing_show).then(function(tabs){
                        $scope.appList = tabs;
                    });
                    
                    ani();
                    // // DND function to run
                    // if(!$scope.removing_show){
                    //     console.log("ADDD SLIDER MOVE FUNCTIOn")
                    //     _C.addEventListener('mousemove', drag, false);
                    //     _C.addEventListener('touchmove', drag, false);
                    // }else{
                    //     _C.removeEventListener('mousemove', drag, false);
                    //     _C.removeEventListener('touchmove', drag, false);
                    // }
                }

                $scope.$watchCollection('appList', function() {

                     size();
                    if($scope.appList == undefined){
                        return;
                    }
                    if ($scope.appList.length > 0) {
                        N = $scope.appList.length;

                        let sliderWidth_v = N * 100;
                        $scope.sliderWidth = sliderWidth_v + "%";

                        let sliderWidthItem_v = 100 / N;
                        $scope.styleSliderItem = {
                            "width": sliderWidthItem_v + "%"
                        }

                        //When one slide removed we recalculate transform position
                        if( (i+1) > N ){
                          i = i-1;
                          let curentSlidePoss = -i*100/N;
                          $scope.sliderTransform = "translate(" + curentSlidePoss + "%)";
                        }

                        //-- Add button in the bottom --//
                        const sliderButtons = $element[0].querySelector('.slider_button');

                        if( sliderButtons ){
                          sliderButtons.innerHTML = '';
                          for (let i = 0; i < N; i++){
                            let sliderButton = document.createElement("div");
                            sliderButton.classList.add('slider-button_item');
                            sliderButtons.appendChild(sliderButton);
                          }
                        }

                        //-- Made button move slider  --//
                        const button_slid = $element[0].getElementsByClassName('slider-button_item');
                        button_slid[i].classList.add('slider-button_active');

                        if(button_slid){
                          for (let k = 0; k < button_slid.length; k++) {
                              button_slid[k].addEventListener('click', function (index) {
                                for(let j = 0; j < button_slid.length; j++){
                                    button_slid[j].classList.remove('slider-button_active');
                                };
                              button_slid[k].classList.add('slider-button_active');  //-- Add active class to button --//
                                  ini = slideBack;
                                  i = k;
                                  fin = i;
                                  anf = NF;
                                  ani();
                                  slideBack = i;
                              });
                          }

                        }


                    }

                });



                const TFN = {
                    'linear': function(k) {
                        return k
                    },
                    'ease-in': function(k, e = 1.675) {
                        return Math.pow(k, e)
                    },
                    'ease-out': function(k, e = 1.675) {
                        return 1 - Math.pow(1 - k, e)
                    },
                    'ease-in-out': function(k) {
                        return .5 * (Math.sin((k - .5) * Math.PI) + 1)
                    }
                };



                //-- Stop Animation --//
                function stopAni() {
                    cancelAnimationFrame(rID);
                    rID = null
                };


                //-- Animation Slading --//
                function ani(cf = 0) {
                    let v = (ini + (fin - ini) * TFN['ease-out'](cf / anf)) / N * (-100);
                    $scope.sliderTransform = "translate(" + v + "%)";
                    $timeout(function(){
                      $scope.$digest()
                    }, 0)

                    if (cf === anf) {
                        stopAni();
                        return
                    }

                    //-- Runs sliding Animation  "anf"  times
                    // cf - curent frame
                    rID = requestAnimationFrame(ani.bind(this, ++cf))
                };

                function unify(e) {
                    return e.changedTouches ? e.changedTouches[0] : e
                };

                function lock(e) {
                    x0 = unify(e).clientX;
                    locked = true

                };

                function drag(e) {
                    e.preventDefault();

                    if (locked) {
                        let dx = unify(e).clientX - x0,
                            f = +(dx / w).toFixed(2);
                        let v = (i - f) / N * (-100);
                        $scope.sliderTransform = "translate(" + v + "%)";
                        $scope.$digest();
                    }
                };


                function move(e) {
                    if($scope.appList.length !== 0){
                        if (locked) {
                            let dx = unify(e).clientX - x0, //-- Delta X mouse muvment in pixels
                                s = Math.sign(dx),
                                f = +(s * dx / w).toFixed(2); //--  Delta X mouse muvment in renge 0.0 - 1.0
    
                            ini = i - s * f;
                            if ((i > 0 || s < 0) && (i < N - 1 || s > 0) && f > .2) {
                                i -= s;
                                f = 1 - f
                            }
    
                            fin = i;
                            anf = Math.round(f * NF); //-- calculates number of animation frames
    
                            let coutIni = ini % Math.floor(ini);
                            if( coutIni != 0){
                              ani();
    
                            }
                            x0 = null;
                            locked = false;
                        }
    
                        //-- Add active class to button --//
                        const button_slid = $element[0].getElementsByClassName('slider-button_item');
                        for(let j = 0; j < button_slid.length; j++){
                            button_slid[j].classList.remove('slider-button_active');//-- delet active class to button
                        };
                        button_slid[i].classList.add('slider-button_active'); 
                    }
                };



                //--Size window--//
                function size() {
                    w = window.innerWidth;
                    h = window.innerHeight;

                    appIconsCounter(w);
                };

                //--Count how many column in the one row--//
                function appIconsCounter(width) {
                    if (width > 1280) {
                        $scope.IconsPerRow = 6;
                    } else if (width > 1000) {
                        $scope.IconsPerRow = 5;
                    } else if (width > 750) {
                        $scope.IconsPerRow = 4;
                    } else if (width > 500) {
                        $scope.IconsPerRow = 3;
                    } else {
                        $scope.IconsPerRow = 2;
                    }
                    $scope.IconsColumnWidth = 100 / $scope.IconsPerRow;
                    $timeout(function(){
                      $scope.$digest()
                    }, 0)
                };


                size();

                // Delete application
                $scope.deleteApp = function(appId) {
                  PipeService.emit('gh_delete_app', {}, {app_id: appId});
                  $scope.buttonDelete = false;
                };




                addEventListener('resize', size, false);

                _C.addEventListener('mousedown', lock, false);
                _C.addEventListener('touchstart', lock, false);

                _C.addEventListener('mousemove', drag, false);
                _C.addEventListener('touchmove', drag, false);

                _C.addEventListener('mouseup', move, false);
                _C.addEventListener('touchend', move, false);

                _C.addEventListener('mouseleave', move, false);

            }]
        }
    }])
