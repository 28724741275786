export default function getContainerList(id, attrs = '') {
  return [
    {
      id: 'map',
      svg: `<path fill="url(#${id})" ${attrs} d="M120.5,303.5c-23.3-46.7-49.7-98.8-38-163c8.6-47.3,65-144,173-140s162,79,173,139
              s-4.2,100.5-35,157c-36,66-68,121-137,215C201.5,437.5,147.2,357,120.5,303.5z"/>`,
      iconStyle: 'transform="scale(0.65) translate(135 0)"',
    },
    {
      id: 'app',
      svg: `<rect stroke="#000" ${attrs} rx="128" id="svg_1" height="512" width="512" y="0" x="0" stroke-width="0" fill="url(#${id})"/>`,
    },
  ];
}
