
angular.module('groupeProcessor', [])

/*=======================================================================================================*/
/*=====================================   GROUPE SERVICE    ===============================================*/
/*=======================================================================================================*/

.service('groupeService', ['$q', function ($q) {
  
  //******* Grope items ******//
  
  this.groupe = function(fieldGroupe, items){
    var deferred = $q.defer();
    let groupeItemList = [];
    let arrayGroupeField;
    if(!fieldGroupe){
      groupeItemList = items;
    }else{
      arrayGroupeField = fieldGroupe.split(',');
    }
    
    let arrayValue = [];

    if(fieldGroupe){
      items.forEach(item => {
        let valueField = ''
        arrayGroupeField.forEach((fieldToGroupe, index) => {
          let findField = item.fields.find(field => {
            return field.field_id == fieldToGroupe;
          })
          if(findField){
            valueField += findField.field_value;
          }
          if(arrayGroupeField.length-1 == index){
            let itemUnic = findUnicItem(valueField);
            if(itemUnic){
              groupeItemList.push(item);
            }
          }
        })
      });
    }

    // send field value and ceck if ith's unick 
    function findUnicItem(value){
      let unicValue = false;
      let findValue = arrayValue.find(valueInArray => {
        return value == valueInArray;
      });
      if(!findValue){
        arrayValue.push(value);
        unicValue = true;
      }
      return unicValue;
    }
    
    deferred.resolve(groupeItemList);

    return deferred.promise;
  }
}])


