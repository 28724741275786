export function getDistanceFromLatLonInKm(coordsFrom, coordsTo) {
  const [lat1, lon1, distance] = coordsFrom.split(':');
  const [lat2, lon2] = coordsTo.split(':');
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distanceBetween = R * c; // Distance in km

  return Number(distance) >= distanceBetween;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

export function getDate({ type, date = 0, match = true } = {}, itemTime) {
  if (!itemTime && type) return false;
  const newDate = new Date();
  let result = true;
  switch (type) {
    case 'day':
      const filterDayStart = getDateInMs(date);
      const filterDayEnd = getDateInMs(date + 1);

      result = filterDayStart <= itemTime && itemTime < filterDayEnd;
      break;

    case 'days':
      if (date < 0) {
        const currentDayEnd = getDateInMs(1);
        const startPast7Days = getDateInMs(-6);

        result = startPast7Days <= itemTime && itemTime < currentDayEnd;
      } else {
        const currentDay = getDateInMs();
        const startNext7Days = getDateInMs(7);

        result = currentDay <= itemTime && itemTime < startNext7Days;
      }
      break;

    case 'day_week':
      result = date === new Date(itemTime).getDay();
      break;

    case 'week':
      const weekStart = newDate.getDate() - newDate.getDay();
      const weekEnd = weekStart + 6;
      const weekDayStart = newDate.setDate(weekStart + date * 7);
      const weekDayEnd = newDate.setDate(weekEnd + date * 7);

      const [sunday, saturday] = getWeek(weekDayStart, weekDayEnd);

      result = sunday <= itemTime && itemTime <= saturday;
      break;

    case 'month':
      if (newDate.getFullYear() !== new Date(itemTime).getFullYear()) return false;

      const month = newDate.getMonth() + date;

      result = month === new Date(itemTime).getMonth();
      break;

    case 'year':
      const year = newDate.getFullYear() + date;

      result = year === new Date(itemTime).getFullYear();
      break;

    default:
      return true
  }
  return match ? result : !result;
}

function getWeek(start, end) {
  return [new Date(start), new Date(end)];
}

function getDateInMs(date = 0) {
  const currentDay = new Date();
  return new Date(
    currentDay.getFullYear(),
    currentDay.getMonth(),
    currentDay.getDate() + date
  ).valueOf();
}
