import Rete from "rete";
import {MainControl} from "./../controls/main_control.js"
import {items} from "./../controls/sockets.js"
import {ifNoConnections} from "./../controls/utilities.js"
import {act} from "./../../actions/actions.js"






export class GetItemByItemRef extends Rete.Component {

  constructor(){
      super("Get Item By Item Ref");
  }

  builder(node) {
    var outItems = new Rete.Output('outItems', "Items", items);
    var inpItems = new Rete.Input('inpItems',"Items", items);
    var cntr = new MainControl( this.editor, 'text', false, node, this.getSettings(), this.getTemplate() );

    return node
        .addControl(cntr)
        .addInput(inpItems)
        .addOutput(outItems);
  }

  async worker(node, inputs, outputs, triggeredData, resolve, gudhub) {
    let field_id = node.data.item_ref_field_id;
    let app_id = node.data.item_ref_app_id
    let inputItems = inputs['inpItems'][0];
    
    //-- Getting item by item reference value
    let receivedItems = await act.itemGetByItemRef(inputItems, gudhub, field_id); 
    outputs['outItems'] = receivedItems;

    //-- checking if there are output connections
    //-- If there no outputs then we return value
    if( ifNoConnections(node.outputs) )
      resolve(receivedItems);
  }

  getTemplate() {
    return {
      name: 'Get Item By Item Ref',
      icon: 'back_btn',
      constructor: 'node',
      data_model: { 
        item_ref_app_id: '',
        item_ref_field_id: ''
      }
    }
  }

  getSettings(){
    return [{
            title: 'Options',
            type: 'general_setting',
            icon: 'menu',
            columns_list: [[
              {
                title: 'Default Settings',
                type: 'header'
              },{
                type: 'ghElement',
                property: 'item_ref_app_id',
                data_model: function (fieldModel) {
                    return {
                        field_name: 'Application',
                        data_type: 'app',
                        data_model: {
                            current_app: false,
                            interpretation: [{
                                src: 'form',
                                id: 'with_text',
                                settings: {
                                    editable: 1,
                                    show_field_name: 1,
                                    show_field: 1
                                }
                            }]
                        }
                    };
                }
            },{
              type: 'ghElement',
              property: 'item_ref_field_id',
              onInit: function (settingScope) {
                settingScope.$watch(function () {
                  return settingScope.fieldModel.item_ref_app_id;
                }, function(newValue) {
                  settingScope.field_model.data_model.app_id = newValue
                });
              },
              data_model: function (fieldModel, appId) {
								return {
									data_model: {
										app_id: fieldModel.item_ref_app_id
									},
									field_name: 'Field',
									name_space: 'Field',
									data_type: 'field'
								};
							},
            }]]
      }]

  }
}