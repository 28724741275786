require('./../config.js');
require('./../gui/gh_toast.js');

angular.module('appDataProcessor', [
'authorizationMod',
'mainStorage',
'config',
'oc.lazyLoad',
'ghToastModule',
'WebSocketModule'
])


.run(['appListenerService', function (appListenerService) {
	appListenerService.start();
}])




/*========================================================================================================*/
/*=============================     APPLICATION DATA PROCESSING SERVICE     ==============================*/
/*========================================================================================================*/
/*  this is the main method where we initiate data exchanging between server and client*/


.service('appListenerService', ['PipeService', 'appDataProcesingService', function (PipeService, appDataProcesingService) {
	this.start = function () {
		PipeService.onRoot('gh_app_get', {}, function (event, data) {
			appDataProcesingService.getApp(data.app_id).then(function (app) {
				PipeService.emit('gh_app_get', data, app);
			});
		});

		PipeService.onRoot('gh_apps_list_get', {}, function (event, data) {
			appDataProcesingService.getAppsList().then(function (appList) {
				PipeService.emit('gh_apps_list_get', data, appList);
			});
		});

		PipeService.onRoot('gh_delete_app', {}, function (event, data) {
			appDataProcesingService.deleteApp(data.app_id).then(function (appList) {
				PipeService.emit('gh_apps_list_update', {recipient: 'all'}, appList);
			});
		});

		PipeService.onRoot('gh_app_update', {}, function (event, data) {
			data.app.items_list = [];
			data.app.file_list = [];

			appDataProcesingService.updateApp(data.app).then(function(newApp) {

				PipeService.emit('gh_app_views_update', {app_id: newApp.app_id}, newApp.views_list);

				appDataProcesingService.getAppsList().then(function (appsList) {
					PipeService.emit('gh_apps_list_update', {recipient: 'all'}, appsList);
				});

				angular.forEach(newApp.field_list, function(fieldModel){
					PipeService.emit('gh_model_update', {app_id: newApp.app_id, field_id: fieldModel.field_id}, fieldModel);
				});

			});
		});

		PipeService.onRoot('gh_app_view_get', {}, function (event, data) {
			appDataProcesingService.getApp(data.app_id).then(function (app) {
				angular.forEach(app.views_list, function(view, key){
					if(view.view_id == data.view_id){
						PipeService.emit('gh_app_view_get', data, app.views_list[key]);
					}
				});
			});
		});

		PipeService.onRoot('gh_app_views_get', {}, function (event, data) {
			appDataProcesingService.getApp(data.app_id).then(function (app) {
				PipeService.emit('gh_app_views_get', data, app.views_list);
			});
		});

		// Return app_name, app_id, app_icon
		PipeService.onRoot('gh_app_info_get', {}, function (event, data) {
			appDataProcesingService.getAppInfo(data.app_id).then(function (appInfo) {
				PipeService.emit('gh_app_info_get', data, appInfo);
			});
		});

		PipeService.onRoot('gh_app_info_update', {}, function (event, data) {
			appDataProcesingService.updateAppInfo(data.app).then(function (appInfo) {
				PipeService.emit('gh_app_info_update', {app_id: data.app.app_id}, appInfo);
			});
		});

		PipeService.onRoot('gh_app_create', {}, function (event, data) {
			appDataProcesingService.createNewApp(data.app).then(function () {
				appDataProcesingService.getAppsList().then(function (appsList) {
					PipeService.emit('gh_apps_list_update', {recipient: 'all'}, appsList);
				});
			});
		});

	};

}])


.service('appDataProcesingService', [ '$q', '$location', '$interval', 'applicationApi', 'appsStorage', 'authService', 'cnfg', 'WebSocket', function ( $q, $location, $interval, applicationApi, appsStorage, authService, cnfg, WebSocket) {
	var log = cnfg.log.appDataProcesingService;/*- show/hide console output*/



	/*----------------------------------- CREATING NEW APPLICATION -------------------------------*/
	this.createNewApp = function ( app ){
		var deferred = $q.defer();
		/*-- Getting Access Token*/
		authService.getToken().then(function( accessToken ) {
				/*-- If the token is taken*/
				applicationApi.createNewAppApi( app, accessToken ).then(function( result ) {
					var parsed = angular.fromJson(result);

					appsStorage.addNewAppToStorage(parsed);
					deferred.resolve(result);
				});
			},
			function(error) {
				/*-- If access token can't be taken then*/
				var appUrl =  $location.url();
				authService.setPageForRedirect( appUrl );  /* setting page to return user back after he logged-in*/
				$location.path( "login" );/* we redirect user to login page*/
			});
		return deferred.promise;
	};





	/*-------------------------------- UPDATING APPLICATION INFORMATION-------------------------------*/
	/*-- we update application when we need to add/remove fields, change icon and app's name*/
	this.updateAppInfo = function ( appInfo ){
		var self = this;
		var deferred = $q.defer();

		appsStorage.getAppFromStorage(appInfo.app_id).then(function (app) {

			var newApp = angular.merge(angular.copy(app), appInfo);
			self.updateApp(newApp).then(function (parsed) {
				deferred.resolve(parsed);
			});

		});

		return deferred.promise;
	};






	/*----------------------------------- UPDATING APPLICATION -------------------------------*/
	/*-- we update application when we need to add/remove fields, change icon and app's name*/
	this.updateApp = function ( app ){
		var deferred = $q.defer();

		if (!angular.isDefined(app.views_list) || !app.views_list.length) {
			//-------------temporary extending app with views_list
			this.getApp(app.app_id).then(function(appData) {
				authService.getToken().then(function( accessToken ) {
					applicationApi.updateAppApi( angular.merge(app, {views_list: appData.views_list}), accessToken ).then(function( result ) {
						var parsed = angular.fromJson(result);

						appsStorage.updatingAppInStorage( parsed ).then(function(){
							deferred.resolve( parsed );
						});
						return result;
					});
				});
			});
			//-------------temporary extending app with views_list
		} else {

			/*-- Getting Access Token*/
			authService.getToken().then(function (accessToken) {
				/*-- If the token is taken we start updating the applicaton*/
				applicationApi.updateAppApi(app, accessToken).then(function (result) {
					var parsed = angular.fromJson(result);

					appsStorage.updatingAppInStorage(parsed).then(function () {
						deferred.resolve(parsed);
						/*-- refreshing table after item was added to server*/
						log ? console.log("CLIENT : " + result.app_name + " App was Updated") : '';
					});
					return result;
				});
			}, function (error) {
				/*-- If access token can't be taken then*/
				var appUrl = $location.url();
				authService.setPageForRedirect(appUrl);
				/* setting page to return user back after he logged-in*/
				$location.path("login");
				/* we redirect user to login page*/
				deferred.resolve(false);
			});
		}

		return deferred.promise;
	};







	/*-------------------------------- GETTING APPLICATIONS ---------------------------------*/
	/* for keeping promises to get applications*/
	var getAppPromises = {};

	this.getApp = function (appId, appUrl, callFrom) {
		var deferred = $q.defer();

		if(!appId) {
			deferred.reject();
		}

		if (getAppPromises[appId]) {
			return (getAppPromises[appId]).promise;
		} else {
			getAppPromises[appId] = deferred;

			appsStorage.getAppFromStorage(appId).then(function(response) {

				deferred.resolve(response);
			}, function(error) {
				getAppFromServer(appId);
			});
		}

		function getAppFromServer(appId) {
			authService.getToken().then(function( accessToken ) {
				applicationApi.getAppApi(appId, accessToken).then(function(response) {

					var app = appsStorage.saveAppInStorage(response);

					//-- Here we Setup Websockets Connection
					WebSocket.appSubscribe(appId, accessToken);

					deferred.resolve(app);
				}, function (error) {
					deferred.reject();
				});
			}, function(error) {
				deferred.reject();
			});
		}

		return deferred.promise;
	};


	

	/*----------------------------------- DELETING APPLICATION -------------------------------*/
	this.deleteApp = function (appId){
		var deferred = $q.defer();
		/*-- Getting Access Token*/
		authService.getToken().then(function( accessToken ) {

			/*-- If the token is taken we delete the app*/
			applicationApi.deleteAppApi( appId, accessToken ).then(function( result ) {
				/*-- when application is deleted we refresh the app storage*/

				appsStorage.deletingAppFromStorage(appId).then(function (appsList) {
					deferred.resolve(appsList);
				});

				if(log){
					console.log("CLIENT : App was Deleted");
				}

			});
		},function(error) {
			/*-- If access token can't be taken then*/
			authService.setPageForRedirect( 'home' );  /* setting page to return user back after he logged-in*/
			$location.path( "login" );/* we redirect user to login page*/
		});

		return deferred.promise;
	};






	/*-------------------------------- GETTING LIST OF APPLICATIONS ---------------------------------*/
	this.getAppsList = function ( appUrl ){
		var deferred = $q.defer();

		/*-- Checking if we have Apps List in 'MainStorage'*/
		appsStorage.getAppListFromStorage().then(function( data ){
				deferred.resolve( data );
			},
			function( noList ){
				/*-- Getting Access Token*/
				authService.getToken().then(function( accessToken ) {
						/*-- if the token is taken*/
						applicationApi.getAppListApi( accessToken ).then(function( result ) {
								deferred.resolve((() => {
									// A copy is made to avoid loading the system
									let resultCopy = [];
									let filteredPropsList = ['items_list', 'field_list'];
									result.apps_list.forEach((appObj) => {
										let actualObject = {};
										for (let key in appObj) {
											if (!filteredPropsList.includes(key)) {
												actualObject[key] = appObj[key];
											}
										}
										resultCopy.push(actualObject);
									});
									return resultCopy;
								})());
								/*-- Saving applications list to appListStorage*/
								appsStorage.updateAppsListInStorage( result.apps_list );
								log?console.log("CLIENT : App List was Received"):'';
							},
							function( error ) {
								/*-- If we can't receive the applist then we redirect user to login page*/
								log?console.log("CLIENT : App List was not Received"):'';
								authService.setPageForRedirect( appUrl ); /* setting page to return user back after he logged-in*/
								deferred.reject( error );
								$location.path( "login" );/* we redirect user to login page*/
							});
					},
					function(error) {
						/*-- If access token can't be taken then*/
						log?console.log("CLIENT : Access Token can't be taken"):'';
						authService.setPageForRedirect( appUrl ); /* setting page to return user back after he logged-in*/
						deferred.reject( error );
						$location.path( "login" );/* we redirect user to login page*/
					});

			});

		return deferred.promise;
	};



	/*-------------------------------- GETTING APP INFO ---------------------------------*/
	this.getAppInfo = function ( appId ){
		var deferred = $q.defer();

		/*-- Checking if we have Apps List in 'MainStorage'*/
		appsStorage.getAppListFromStorage().then(function( appList ){
			return appList;
		}, function( noList ){
			/*-- Getting Access Token*/
			return authService.getToken().then(function( accessToken ) {

				/*-- if the token is taken*/
				return applicationApi.getAppListApi( accessToken ).then(function( result ) {
					appsStorage.updateAppsListInStorage( result.apps_list );
					return result.apps_list;
				},function( error ) {
					return;
				});

			}, function(error) {
				return;
			});

		}).then(function (appList) {
			var appInfo = appList.find(function (app) {
				return app.app_id == appId;
			});

			if(!appInfo){
				deferred.reject();
			}
			deferred.resolve(appInfo);
		}, function (error) {
			authService.setPageForRedirect( appUrl ); // setting page to return user back after he logged-in
			$location.path( "login" );// we redirect user to login page
			deferred.reject();
		});

		return deferred.promise;
	};


	/*-------------------------------------- DIVIDING ARRAY ON PAGE -------------------------------------*/
	this.divideOnPages = function ( items, itemsOnPage ){
		var length = items.length;
		var pages = [];
		var pagesQuantyty = 0;

		/*-- Calculating pages quantity*/
		pagesQuantyty = parseInt(length / itemsOnPage);
		if ( (length % itemsOnPage) > 0 )
			pagesQuantyty += 1;

		/*-- Filling pages array with page*/
		for(i=0; i< pagesQuantyty; i++){
			var startIndex = i*itemsOnPage;
			var endIndex = (i+1)*itemsOnPage;
			var page = [];

			/*-- Filling page with items*/
			for(j=startIndex; j< endIndex; j++){
				if(length <= j)/*-- if there no more times we stop the filling*/
					break;
				page.push(items[j]);
			}

			pages.push( page );
		}


		return pages;
	};



	this.deleteField = function(id) {
		return authService.getToken().then(function(token) {
			return applicationApi.deleteField(token, id);
		}, function() {
			return $q.reject('authService.getToken() error');
		});
	};

	/*-------------------------------------- UPDATE APPLICATIONS -------------------------------------*/
	/*  Should be created*/






}])




/*==========================================================================================================*/
/*========================================     APPLICATION API      ========================================*/
/*==========================================================================================================*/
/* here we send requests on server																		    */

.factory('applicationApi', [ '$q', '$http', '$location', 'cnfg', '$httpParamSerializerJQLike', 'ghToastService', function( $q, $http, $location, cnfg, $httpParamSerializerJQLike, ghToastService) {
	var log = cnfg.log.applicationApi;/*- show/hide console output*/
	var apiUrl = cnfg.server_url + '/api/';

	function replaceSpecialCharacters(app) {
		return angular.toJson(app).replace(/\+|&|%/g, function(match) {
			switch (match) {
				case '+':
					return '%2B';
				case '&':
					return '%26';
				case '%':
					return '%25';
			}
		})
	}

	return {




		/*--------------------------------- CREATING NEW APP API ----------------------------------------*/
		createNewAppApi: function( app, accessToken ) {
			return $http({
				method: 'POST',
				url: apiUrl + 'app/create',
				headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
				data: 'app=' + replaceSpecialCharacters(app)+
				'&token=' + accessToken,
			}).then(function(result) {
                result.data.from_apps_list = true;
				return result.data;
			});
		},




		/*--------------------------------- UPDATING APP API ----------------------------------------*/
		updateAppApi: function(app, accessToken) {

			return $http({
				method: 'POST',
				url: apiUrl + 'app/update',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
				},
				data: 'app=' + replaceSpecialCharacters(app)+
				'&token=' + accessToken,

			}).then(function(result) {
				return result.data;
			});
		},





		/*----------------------------------- DELETING APPLICATION ----------------------------------*/
		deleteAppApi: function(appId, accessToken) {
			return $http({
				method: 'POST',
				url: apiUrl + 'app/delete',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
				},
				data: 'app_id=' + appId +
				'&token=' + accessToken,

			}).then(function(result) {
				return result.data;
			});
		},



		/*------------------------------- GETTING LIST OF APPLICATIONS API --------------------------------*/
		getAppListApi: function(accessToken) {

			return $http({
				method: 'GET',
				url: apiUrl + 'applist/get',
				params: {
					token: accessToken
				}
			}).then(function(result) {
				return {
                    apps_list: result.data.apps_list.map(app => {
                        app.from_apps_list = true;
                        return app;
                    })
				};
			});
		},






		/*------------------------------ GETTING APPLICATION API ------------------------------*/
		getAppApi: function(appId, accessToken) {

			if(!appId){
				return $q.reject();
			}

			return $http({
				method: 'GET',
				url: apiUrl + 'app/get',
				transformResponse: function(response) {
					var parsed = '';

					try {
						parsed = angular.fromJson(response);
					} catch(e) {
						ghToastService.simple(response + '; app_id: ' + appId);
					} finally {
						return parsed;
					}

				},
				params: {
					app_id: appId,
					token: accessToken
				}
			}).then(function(result) {
				return result.data;
			}, function(error) {
				return $q.reject();
			});
		},



		deleteField: function(token, id) {
			var requestConfig = {
				method: 'POST',
				url: cnfg.server_url + '/api/app/delete-field',
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
				data: $httpParamSerializerJQLike({
					token: token,
					field_id: id
				})
			};
			return $http(requestConfig);
		}

	};
}])


/*==========================================================================================================*/
/*==========================   UPDATING  APPLICATION IN MAIN STORAGE      ==================================*/
/*==========================================================================================================*/
/*-- If user created new application or changing icon or name, then we have to update information in scope*/

.factory('appsStorage', [ '$q', 'cnfg', 'storage', '$rootScope', 'PipeService', function($q, cnfg, storage, $rootScope, PipeService) {
	var log = cnfg.log.appsStorage;
	return {




		/*------------------------------- GETING APPs LIST FROM STORAGE ---------------------------------*/
		getAppListFromStorage: function( ) {
			var mainStorage = storage.getMainStorage();
			var deferred = $q.defer();

			if( mainStorage.apps_list.length > 0){
				deferred.resolve(  mainStorage.apps_list );
			} else {
				deferred.reject( false );
			}

			return deferred.promise;
		},





		/*------------------------------- GETING APP FROM STORAGE ---------------------------------*/
		getAppFromStorage: function( appId ) {
			var mainStorage = storage.getMainStorage();
			var deferred = $q.defer();
			var appWasFound = false;

			/*-- Looking for app in the main storage (we are looking for the app with data only)*/
			angular.forEach( mainStorage.apps_list, function(app, key) {
				if (app.app_id == appId) {
                    if (app.field_list.length > 0) {
                        appWasFound = true;
                        deferred.resolve(app);
					}
				}
			});

			/*-- If App was not found in the Main Storage*/
			if (!appWasFound){
				deferred.reject( false );
			}


			return deferred.promise;
		},




		/*---------------------------- ADDING NEW APPLICATION TO STORAGE APPs LIST ------------------------------*/
		addNewAppToStorage: function( app ) {
			var mainStorage = storage.getMainStorage();

			mainStorage.apps_list.push( app );
		},





		/*---------------------------------- SAVE RECEIVED APPLICATION IN STORAGE  -------------------------------------*/
		/*-- We save apps that are in apps list, if we heven't found App in mainStorage.apps_list we don't save it*/
		/*-- If we received application from server we replace a dummy app from 'apps_list' with a new one*/
		saveAppInStorage: function(app) {
			/* existing fields in view*/

			var existingFieldIds = [];
			angular.forEach(app.field_list, function(field) {
				existingFieldIds.push(field.field_id);
			});

			var mainStorage = storage.getMainStorage();

			/*-- Looking for application in the App List*/

			var appIndex = mainStorage.apps_list.findIndex(function(app_from_storage){
				return app_from_storage.app_id == app.app_id;
			})
			// add app to app list if app list empty or remove the old app from storage and replace it with a new one
			// if app index < 0 its means app is public (the app is out of app list)
			if(appIndex >= 0){
				app.from_apps_list = mainStorage.apps_list[appIndex].from_apps_list;
				// MUST BE REMOVED : TEMP FIX PERMISSION FROM GET APP 
				app.permission = mainStorage.apps_list[appIndex].permission;
				mainStorage.apps_list.splice(appIndex, 1, app);
			}else{
                app.from_apps_list = false;
				mainStorage.apps_list.push(app);
			}

			return storage.getAppFromStorage(app.app_id);

		},







		/*---------------------------------- UPDATING APPLICATION IN STORAGE  -------------------------------------*/
		/*-- When views, fields, app name and icon was changed we update whole application*/
		updatingAppInStorage: function( app ) {

			var deferred = $q.defer();
			var appId = app.app_id;

			storage.getAppFromStorage( appId ).then(function( storageApp ) {
				app.items_list = storageApp.items_list;
				app.file_list = storageApp.file_list;

				angular.extend(storageApp, app);

				//-- Sending updates for Views Updates
				PipeService.emit('gh_app_views_update', {app_id: app.app_id}, app.views_list);

				//-- Sending updates for updating Fields
				angular.forEach(app.field_list, function(fieldModel){
					PipeService.emit('gh_model_update', {app_id: app.app_id, field_id: fieldModel.field_id}, fieldModel);
				});

				deferred.resolve();
			});

			return deferred.promise;
		},




		/*------------------------------- DELETING APPLICATION FROM STORAGE -------------------------------------*/
		deletingAppFromStorage: function(appId) {
			var deferred = $q.defer();

			var mainStorage = storage.getMainStorage();

			/*-- Looking for application in App Storage*/
			angular.forEach( mainStorage.apps_list, function(app, key) {
				if ( app.app_id == appId ){
					/*-- in case if the app was found we remove it from storage*/
					mainStorage.apps_list.splice( key, 1 );
					deferred.resolve(mainStorage.apps_list);
				}
			});

			deferred.resolve(mainStorage.apps_list);

			return deferred.promise;
		},






		/*------------------------------- UPDATING APPLICATION LIST IN STORAGE --------------------------------------*/
		updateAppsListInStorage: function ( appsList ){
			var mainStorage = storage.getMainStorage();
			// find in mainstorage some thet was load faster then applist in app list from server if exist we replace 
			var lengthApplist = appsList.length;
			var lengthMainstorage = mainStorage.apps_list.length;
			for(var i = 0; i < lengthApplist; i++){
				for(var y= 0; y < lengthMainstorage; y++){
					if(appsList[i] != undefined && appsList[i].app_id == mainStorage.apps_list[y].app_id){
						appsList.splice(i, 1)
					}
				}
				if(appsList[i] != undefined){
					mainStorage.apps_list.push(appsList[i])
				}
			}
		}





	};
}])