require('./gh_radio_button.scss');


angular.module('radioButtonData', [])




/*=======================================================================================================*/
/*=======================================  RADIO BUTTON DATA   ==========================================*/
/*=======================================================================================================*/


.factory('radio_button', function($q, $http, $location, cnfg) {

    return {




        /*--------------------------- FIELD TEMPLATE ----------------------------------*/
        getTemplate: function() {
            var fieldsTemplate = {
                constructor: 'field',
                name: 'Radio button',
                icon: 'radio_button',
                type: 'radio_button',
                model: {
                    field_id: 0,
                    field_name: 'Radio button',
                    field_value: ' ',
                    data_id: 0,
                    data_type: 'radio_button',
                    data_model: {
                        use_default_value: false,
                        options: [{
                                name: 'In Process',
                                value: 0,
                                color: '#FF0000'
                            }, {
                                name: 'Assigned',
                                value: 1,
                                color: '#FF6600'
                            }
                            // , {
                            //   name: 'Done',
                            //   value: 2,
                            //   color: '#00D3ED'
                            // }
                        ],
                        interpretation: [{
                            src: 'table',
                            id: 'current_value',
                            settings: {
                                editable: 0,
                                show_field_name: 0,
                                show_field: 1
                            },
                            style: {
                                font_size: 12
                            }
                        }, {
                            src: 'form',
                            id: 'input',
                            settings: {
                                editable: 1,
                                show_field_name: 1,
                                show_field: 1
                            },
                            style: {
                                font_size: 12
                            }
                        }, {
                            src: 'dropdown',
                            id: 'current_value',
                            settings: {
                                editable: 1,
                                show_field_name: 0,
                                show_field: 1
                            }
                        }, {
                            src: 'input',
                            id: 'current_value',
                            settings: {
                                editable: 0,
                                show_field_name: 0,
                                show_field: 1
                            }
                        }, {
                            src: 'input_list',
                            id: 'current_value',
                            settings: {
                                editable: 0,
                                show_field_name: 0,
                                show_field: 1
                            }
                        }, {
                            src: 'document',
                            id: 'default',
                            settings: {
                                editable: 0,
                                show_field_name: 0,
                                show_field: 1
                            },
                            style: {}
                        }]
                    }
                }
            };

            return fieldsTemplate;
        },


        getDefaultValue: function(fieldModel, valuesArray) {
          var deferred = $q.defer();
            console.log('valuesArray', valuesArray)

            for(var i = 0; i < valuesArray.length; i++){
                if(valuesArray[i] == null){
                    valuesArray[i] = fieldModel.data_model.default_field_value; 
                }
            }
        
            deferred.resolve(valuesArray);

		    return deferred.promise;           
        },


        /*--------------------------- FIELD SETTINGS ----------------------------------*/
        getSettings: function(fieldSettingsScope) {

            var settingTemplate = [{
                title: 'Options',
                type: 'general_setting',
                icon: 'menu',
                columns_list: [
                    [],
                    [{
                        title: 'Options Settings',
                        type: 'header'
                    }, {
                        type: 'html',
                        data_model: function(fieldModel) {
                            return {
                                patterns: [{
                                    property: 'name', // Table column header of property
                                    prop_name: 'name', // Name of property in data_model
                                    type: 'text', // Data type of property
                                    data_model: function(option) {
                                        return {};
                                    },
                                    display: true // Show field
                                }, {
                                    property: 'color',
                                    prop_name: 'color',
                                    type: 'color',
                                    display: true,
                                    data_model: function(option) {
                                        return {};
                                    }
                                }, {
                                    property: 'value',
                                    prop_name: 'value',
                                    type: 'number',
                                    getMaxValue: function() {
                                        return fieldModel.data_model.max_value || Math.max.apply(null, fieldModel.data_model.options.map(function(obj) {
                                            return obj.value;
                                        }));
                                    },
                                    setMaxValue: function(maxValue) {
                                        fieldModel.data_model.max_value = maxValue;
                                    },
                                    data_model: function(option) {
                                        return {};
                                    },
                                    display: false, // Hide field
                                    generate_dynamic: true // Generate value dynamic when create new. Generate number
                                }]
                            };
                        },
                        control: '<gh-option-table items="fieldModel.data_model.options" pattern="field_model.patterns" ></gh-option-table>'
                    }],
                    [{
                        title: 'Default Value Settings',
                        type: 'header'
                    }, {
                        type: 'ghElement',
                        property: 'data_model.use_default_value',
                        data_model: function() {
                            return {
                                field_name: 'Use default value',
                                name_space: 'use_default_value',
                                data_type: 'boolean'
                            };
                        }
                    }, {
                        type: 'ghElement',
                        property: 'data_model.default_field_value',
                        showIf: 'data_model.use_default_value',
                        data_model: function(fieldModel) {
                            return {
                                data_model: {
                                    options: fieldModel.data_model.options
                                },
                                field_name: 'Default value',
                                name_space: 'default_value',
                                data_type: 'radio_button'
                            };
                        }
                    }]
                ]
            }];

            return settingTemplate;
        },

        /*--------------------------- FILTER ----------------------------------*/
        filter: {
            _search_options: [{
                id: 'equal_or',
                name: 'Equals(or)',
                html: '<gh-input-list gh-field="field" gh-dropdown="field.data_model.options" gh-model="filter.valuesArray" gh-data-type="radio_button" size="small"/>'
            }, {
                id: 'equal_and',
                name: 'Equals(and)',
                html: '<gh-input-list gh-field="field" gh-dropdown="field.data_model.options" gh-model="filter.valuesArray" gh-data-type="radio_button" size="small"/>'
            }, {
                id: 'not_equal_or',
                name: 'Not equals(or)',
                html: '<gh-input-list gh-field="field" gh-dropdown="field.data_model.options" gh-model="filter.valuesArray" gh-data-type="radio_button" size="small"/>'
            }, {
                id: 'not_equal_and',
                name: 'Not equals(and)',
                html: '<gh-input-list gh-field="field" gh-dropdown="field.data_model.options" gh-model="filter.valuesArray" gh-data-type="radio_button" size="small"/>'
            }, {
                id: 'value',
                name: 'Value',
                html: '<gh-input gh-dropdown="[{name: \'Is Defined\', value: \'true\'}, {name: \'Is Not Defined\', value: \'false\'}]" ng-model="filter.valuesArray[0]" gh-data-type="text_opt" size="small"/>'
            },{
                id: 'contain_or',
                name: 'Checkbox list(or)',
                html: '<gh-checkbox-list gh-model="filter.valuesArray" gh-values="field.data_model.options" ></gh-checkbox-list>'
            }],


            getSearchOptions: function() {
                return this._search_options;
            }
        },




        /*--------------------------- INTERPRETATION ----------------------------------*/
        getInterpretation: function(value, field, dataType, source) {



            var interpretations = [{
                id: 'input',
                name: 'Input With Name',
                html: '<gh-radio-button gh-options="field_model.data_model.options" gh-model="field_model.field_value" gh-mode="input" editable="field_model.settings.editable" class="gh-field_value"/>'
            }, {
                id: 'current_one',
                name: 'inputOne',
                html: '<gh-radio-button gh-options="field_model.data_model.options" gh-model="field_model.field_value" gh-mode="input" editable="field_model.settings.editable" class="gh_radio_button_one"/>'
            }, {
                id: 'current_value',
                name: 'inputList',
                html: '<gh-radio-button gh-options="field_model.data_model.options" gh-model="field_model.field_value" gh-mode="current_value" editable="field_model.settings.editable"/>'
            }, {
                id: 'current_value_Square',
                name: 'inputSquare',
                html: '<gh-radio-button gh-options="field_model.data_model.options" gh-model="field_model.field_value" gh-mode="current_value" editable="field_model.settings.editable" class="gh_radio_button_one"/>'
            }, {
                id: 'default',
                name: 'Default',
                html: '<div ng-if="field_model.field_value == button.value" ng-repeat="button in field_model.data_model.options" class="gh_radio_button_default">{{button.name}}</div>'
            }];

            return $q.when(interpretations);
        }
    };
});