import Rete from "rete";
import {MainControl} from "../controls/main_control.js"
import {items} from "../controls/sockets.js"
import {ifNoConnections} from "../controls/utilities.js"
import {act} from "../../actions/actions.js"





export class PopulateWithDate extends Rete.Component {
  constructor(){
      super("Populate With Date");
  }


  builder(node) {
      var outItems = new Rete.Output('outItems', "Items", items);
      var inpItems = new Rete.Input('inpItems',"Items", items);
      var cntr = new MainControl( this.editor, 'text', false, node, this.getSettings(), this.getTemplate() );

      return node
          .addControl(cntr)
          .addInput(inpItems)
          .addOutput(outItems);
          
  }


  async worker(node, inputs, outputs, triggeredData, resolve) {
      let model = node.data.model;
      let app_id = node.data.app_id;
      let keep_whole_data = node.data.keep_whole_data;
      let inputItems = inputs['inpItems'][0];

      //-- Populating items with data
      let populatedItems = await act.populateWithDate(inputItems, model);
      outputs['outItems'] = populatedItems;
      console.log('populate', populatedItems)

      //-- checking if there are output connections
      //-- If there no outputs then we return value
      if( ifNoConnections(node.outputs) )
      resolve(populatedItems);
  }

  getTemplate() {
    return {
      name: 'Populate With Date',
      icon: 'availible',
      constructor: 'node',
      data_model: {
        app_id: '',
        model: []
      }
    }
  }


  getSettings(){
    return [{
            title: 'Options',
            type: 'general_setting',
            icon: 'menu',
            columns_list: [[
              {
                title: 'Default Settings',
                type: 'header'
              },
              {
                type: 'ghElement',
                property: 'app_id',
                data_model: function (fieldModel) {
                    return {
                        field_name: 'Application',
                        data_type: 'app',
                        data_model: {
                            current_app: false,
                            interpretation: [{
                                src: 'form',
                                id: 'with_text',
                                settings: {
                                    editable: 1,
                                    show_field_name: 1,
                                    show_field: 1
                                }
                            }]
                        }
                    };
                }
              }],
            [{
                title: 'Data To Populate',
                type: 'header'
              },{
              type: 'html',
              class: 'option-column_750px',
              data_model: function(fieldModel) {
                  return {
                      patterns: [ {
                        property: 'element_id',
                        type: 'field',
                        prop_name: 'Field',
                        data_model:function(option){
                          return {
                            app_id: fieldModel.app_id
                          };
                        },
                        display: true
                      },{
                        property: 'date_type', 
                        prop_name: 'Date',
                        type: 'text_opt',
                        display: true,
                        data_model:function(){
                          return {
                            options: [
                              {name: 'Now', value: 'now'},
                              {name: 'Next Day', value: 'next_day'},
                              {name: 'Two Days After', value: 'two_days_after'},
                              {name: 'Three Days After', value: 'three_days_after'},
                              {name: 'Four Days After', value: 'four_days_after'},
                              {name: 'Next Week', value: 'next_week'},
                              {name: 'Two Weeks After', value: 'two_weeks_after'},
                              {name: 'Three Weeks After', value: 'three_weeks_after'}
                            ]
                          }
                        }
                      }]
                  };
              },
              control: '<gh-option-table items="fieldModel.model" pattern="field_model.patterns" ></gh-option-table>'
          }]]
      }]
  }
}