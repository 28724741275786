import filter from './filter.js';

//es6 doesn't hoist const/let declarations
const dummy = function(...args){
    return new Promise((resolve, reject) => {resolve("result")});
}

class Actions {
    constructor(){
        this.updateItems = dummy,
        this.filter = filter, // (items, filters) 
        this.mergeItems = dummy, // (srcItems, dstItems)
        this.apiItemsUpdate = dummy, // async (items, auth_key, app_id)
        this.apiFieldValueGet = dummy, // async (auth_key, app_id, item_id, element_id)!!!!!!
        this.apiItemGet = dummy, // async (auth_key, app_id, item_id)!!!!!!
        this.itemGetByItemRef = dummy, // async (items, auth_key, app_id, item_ref_field_id)
        this.populateWithDate = dummy
    }
}

export const act = new Actions();