require('./gh_filter.scss');
angular.module('ghFilterModule', [
])

/*
  *
  *   // ghFilterModel
  *
  * {
  *   recipient:{
  *     app_id: '',
  *     field: ''
  *   },
  *   filters_to_view: [ // Array of filters, which must be view by default.
  *     {
  *       field_id: '',
  *       show: '', // 1 or 0
  *       filter_type: '' // 'value', 'equal', ect.
  *     }
  *   ] //
  * }
  *
  * */

  .directive('ghFilter', ['$q', 'PipeService', 'Message', 'ghFilterService', 'filterItem', 'TableService', function($q, PipeService, Message, ghFilterService, filterItem, TableService) {

    return {
      restrict: 'E',
      scope: {
        ghFilterDataModel: '=',
        //recipientAppId: '=', // Address of recipient 'app_id'. REQUIRED!!!!!!
        //recipientFieldId: '=', // Address of recipient 'field_id'. Not required!!!!!!
        filterList: '=?', // List of filters
        ghMode: '@',
        ghSearchTypeFilter: '@', // Type of search option, which must be view
        fieldModel: '='
      },
      controller: ['$scope', function($scope) {

        // All fields with filter model
        var fildListBuff = [];
        
        // Dropdown filds list for gh-button 'Add filter+'
        $scope.dropdownFieldList = [];
        
        // Interpetation gh-filter settings
        $scope.ghFilterSettins = ghFilterService.getInterpretationSettings($scope.ghMode || 'advanced');

        if ($scope.ghFilterDataModel.recipient.current_app && $scope.ghFilterDataModel.recipient.field_id) {
          PipeService.on('gh_model_get', {app_id: $scope.ghFilterDataModel.recipient.current_app, field_id: $scope.ghFilterDataModel.recipient.field_id}, function fieldModelGet(event, model) {
            PipeService.destroy('gh_model_get', {app_id: $scope.ghFilterDataModel.current_app}, fieldModelGet);
            $scope.ghFilterDataModel.recipient.app_id = model.data_model.app_id;
          }, $scope).emit('gh_model_get', {}, {app_id: $scope.ghFilterDataModel.recipient.current_app, field_id: $scope.ghFilterDataModel.recipient.field_id});
        }

       
        $scope.$watch('ghFilterDataModel.recipient.app_id', function (newValue, oldValue) {

          if(newValue != oldValue){
            $scope.filterList = [];
            $scope.dropdownFieldList = [];
          }

          if(newValue){
            
            if($scope.fieldModel && !$scope.fieldModel.data_model.filter_settings ){
              $scope.fieldModel.data_model.filter_settings = { fields_to_view: [] };
            }
            // Take filter list to show when filter its load
            TableService.createFieldsListToView(newValue, $scope.fieldModel ? $scope.fieldModel.data_model.filter_settings.fields_to_view : []).then(function (columnsList) {
              $scope.filterList = $scope.filterList || [];

              var address = {
                app_id: $scope.ghFilterDataModel.recipient.app_id
              };
  
              var arrayFilterModels = [];
              PipeService.on('gh_models_get', address, function modelsPipe(event, bindedAppFields) {
                PipeService.destroy('gh_models_get', address, modelsPipe);

                var mergeArrays = merge($scope.ghFilterDataModel.filters_to_view || [], bindedAppFields);

                if($scope.fieldModel && $scope.fieldModel.data_model.filter_settings){
                  $scope.fieldModel.data_model.filter_settings.fields_to_view = columnsList;
                  angular.forEach(mergeArrays, function (field) {
                    angular.forEach($scope.fieldModel.data_model.filter_settings.fields_to_view, function (value) {
                      if(value.field_id == field.field_id && value.show){
                        arrayFilterModels.push(getFilter(field));
                      }
                    });
                  });
                } else {
                  angular.forEach(mergeArrays, function (field) {
                    arrayFilterModels.push(getFilter(field));
                  });
                }
  
                $q.all(arrayFilterModels).then(function (filterModels) {
                  angular.forEach(filterModels, function (obj) {
                    if (obj && obj.filter) saveFilterModel(obj.field, obj.filter, obj.show);
                  });
                }, function (err) {});
  
              }, $scope).emit('gh_models_get', {}, address);
  
            })
          }
        });

        function getFilter(field) {
          var deferred = $q.defer();

          filterItem.getFilter(field, field.filter_type).then(function (filter) {
            deferred.resolve({
              field: field,
              filter: filter,
              show: field.show || 0
            });
          }, function () {
            deferred.resolve();
          });

          return deferred.promise;
        }

        function saveFilterModel(field, filter, addDefault) {

          fildListBuff.push({
            field_id: field.field_id,
            field_name: field.field_name,
            data_type: field.data_type,
            filter: filter
          });

          // Set field to dropdown list or filter array
          if(addDefault){

            // When we add all filters, check if it not present in filterList
            var wasFind =$scope.filterList.find(function (presentFilter) {
              return presentFilter.field_id == field.field_id;
            });

            if(!wasFind){
              $scope.filterList.push(angular.copy(filter));
            }

          } else {
            $scope.dropdownFieldList.push({
              name: field.field_name,
              id: field.field_id
            });
          }
        }

        function merge(destArr, srcArray) {
          var newArray = angular.copy(destArr);

          srcArray.forEach(function (srcArrayObj) {

            var index = destArr.findIndex(function (destArrObj) {
              return srcArrayObj.field_id == destArrObj.field_id;
            });

            if (index == -1) newArray.push(srcArrayObj);
          });
          return newArray;
        }

        $scope.addFilterModel = function(fieldObj) {

          var selectedField = fildListBuff.find(function (field) {
            return field.field_id == fieldObj.id;
          });

          $scope.filterList.push(angular.copy(selectedField.filter));

          // Delete field from dropdown select field filter
          angular.forEach($scope.dropdownFieldList, function (field, i) {
            if(field.id == fieldObj.id){
              $scope.dropdownFieldList.splice(i, 1);
            }
          });
        };

        // $scope.getField = function(fieldId) {
        //   return fildListBuff.find(function (field) {
        //     return field.field_id == fieldId;
        //   });
        // };

        // ------------------------- Delete filter model from filter list -----------------------
        $scope.deleteFilterModel = function(fieldId, i) {

          // delete filter from filter list
          $scope.filterList.splice(i, 1);

          // Add field to dropdown select field filter
          angular.forEach(fildListBuff, function (field, i) {
            if(field.field_id == fieldId){
              $scope.dropdownFieldList.splice(i, 0, { 
                name: field.field_name,
                id: field.field_id
              });
            }
          });
        };



        var message = new Message({
          field_id: 'filter_' + $scope.$id + '_' + $scope.ghFilterDataModel.recipient.app_id
        }, $scope);

        $scope.$watch('filterList', function (newValue, oldValue) {
          if(newValue != oldValue && $scope.ghFilterDataModel.recipient.field_id && $scope.ghFilterDataModel.recipient.app_id){
            message.emit('filter', {
              to:{
                app_id: $scope.ghFilterDataModel.recipient.app_id,
                field_id: $scope.ghFilterDataModel.recipient.field_id
              },
              value: newValue
            });
          }
        }, true);


      }],
      template:
        '<div ng-repeat="filter in filterList" class="filter-item">' +
        //'	  <div class="cross-button" ng-if="ghFilterSettins.modify_filter" ng-click="deleteFilterModel(filter.field_id, $index)" gh-icon="cross C8D1D8 30px normal"></div>' +
        '	  <div filter-item class="flex flex-column filter-wrap"></div>' +
        '</div>'+
        '<gh-button class="add-filter-button" ng-show="ghFilterSettins.modify_filter" gh-dropdown="dropdownFieldList" callback="addFilterModel(value)" size="large"></gh-button>'
    };
  }])


  .directive('filterItem', [ 'PipeService', 'filterItem', '$routeParams', function (PipeService, filterItem, $routeParams) {
    return {
      restrict: 'A',
      template:
        '<div class="filter-name">'+
        '   <p>{{field.field_name}}:</p>'+
        '   <div class="expand_button" ng-class="{open: show}" ng-click="show = !show" gh-icon="bot_arrow 0893d2 22px normal"></div>' +
        '	  <div class="cross-button" ng-if="ghFilterSettins.modify_filter" ng-click="deleteFilterModel(filter.field_id, $index)" gh-icon="cross C8D1D8 30px normal"></div>' +
        '</div>'+
        '<div layout="column" ng-class="{open: show}" ng-init="show = true" class="filter-content">' +
        '   <div ng-show="ghFilterSettins.modify_filter" layout="row" style="position: relative">'+
        '			<span ng-init="dropdownShow = false" class="gh-dropdown">' +
        '				<span class="gh" ng-click="dropdownShow = !dropdownShow" gh-icon="bot_arrow 0893d2 22px normal" style="margin-top: 5px;"></span>' +
        '				<ul ng-show="dropdownShow" class="display-block">' +
        '					<li ng-repeat="option in filterModel.search_options" ng-click="setSearchOption(option); $parent.dropdownShow = false"><p>{{option.name}}</p></li>' +
        '				</ul>' +
        '			</span>' +
        '			<span layout="column" layout-alling="start center" layout-margin="5">{{filterModel.selected_search_option.name}}:</span>' +
        '   </div>'+
        '   <div ng-show="ghFilterSettins.variable_mode" layout="row" style="position: relative">'+
        '			<span ng-init="dropdownShowMode = false" class="gh-dropdown">' +
        '				<span class="gh" ng-click="dropdownShowMode = !dropdownShowMode" gh-icon="bot_arrow 0893d2 22px normal" style="margin-top: 5px;"></span>' +
        '				<ul ng-show="dropdownShowMode" class="display-block">' +
        '					<li ng-repeat="option in filterModel.variable_option" ng-click="setSearchOptionVariable(option); $parent.dropdownShowMode = false"><p>{{option.name}}</p></li>' +
        '				</ul>' +
        '			</span>' +
        '			<span layout="column" layout-alling="start center" layout-margin="5">{{filter.selected_search_option_variable}}:</span>' +
        '   </div>'+
        '	<span layout="row" class="filter-input" ><div class="gh-filter-container-full" gh-bind-html="html_template"></div></span>'+
        '</div>',
      controller: ['$scope', '$compile', '$element', function ($scope, $compile, $element) {
        
        $scope.current_app = $routeParams.param_1;
        $scope.setSearchOption = function(option) {
            $scope.filter.search_type = option.id;
            $scope.filterModel.selected_search_option = option;
            $scope.html_template = option.html;
            if($scope.filter.selected_search_option_variable.id == 'value'){
              $scope.html_template = option.html;
            }
        };
        $scope.setSearchOptionVariable = function(option){
          $scope.filter.selected_search_option_variable = option.name;
          if(option.id == 'value'){
            $scope.html_template = $scope.filterModel.selected_search_option.html;
          }else{
            $scope.filter.input_type = option.id;
            $scope.html_template = option.html;
          }
        }

        var address = {
          app_id: $scope.ghFilterDataModel.recipient.app_id,
          field_id: $scope.filter.field_id
        };



        PipeService.on('gh_model_get', address, function modelPipe(event, fieldModel) {
          PipeService.destroy('gh_model_get', address, modelPipe);

          $scope.field = angular.copy(fieldModel);

          filterItem.getNewFilter($scope.field, $scope.filter.search_type).then(function (filterModel) {
            $scope.filterModel = angular.copy(filterModel);
            $scope.html_template = $scope.filterModel.selected_search_option.html;
            $scope.filterModel.variable_option = [
              {id: 'value', name: 'Value', html: ''},
              {id: 'variable', name: 'Variable', html: '<gh-input-list gh-dropdown="[{name: \'App Id (current)\', value: \'current_app\'},{name: \'App Id (element)\', value: \'element_app\'}, {name: \'Current Item\', value: \'current_item\'}, {name: \'User (id)\', value: \'user_id\'}, {name: \'User (email)\', value: \'user_email\'}, {name: \'Today\', value: \'today\'}]" gh-model="filter.input_value" gh-data-type="text_opt" size="small"/>'},
              {id: 'field', name: 'Field', html: '<gh-input ng-model="filter.input_value" gh-dropdown autocomplete="fieldsList {{current_app}}" gh-data-type="field" size="large"></gh-input>'}
            ]

            if(!$scope.filter.selected_search_option_variable){
              $scope.filter.selected_search_option_variable = $scope.filterModel.variable_option[0].name;
            }
            if($scope.filter.selected_search_option_variable != 'Value'){
              $scope.filterModel.variable_option.forEach(function(obj){
                if(obj.name == $scope.filter.selected_search_option_variable){
                  $scope.html_template = obj.html;
                }
              })
            }

          });

        }, $scope).emit('gh_model_get', {}, address);


      }]
    };
  }])

  .service('ghFilterService', ['$q', 'GHConstructor', function ($q, GHConstructor) {

    this.getInterpretationSettings = function (viewMode) {
      var settings ={
        advanced: {
          modify_filter: true,
          add_all_filters_to_list: false,
          variable_mode: false          
        },
        checkbox:{
          modify_filter: false,
          add_all_filters_to_list: true,
          variable_mode: false          
        },
        variable:{
          modify_filter: true,
          add_all_filters_to_list: true,
          variable_mode: true,
        }
      };

      return settings[viewMode];
    };
  }]);
