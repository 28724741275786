export default {
  private: true,
  template_name: 'Float',
  allowed_types: [
    'gh_float',
    'gh_element',
    'gh_tabs'
  ],
  model: {
    template_id: 'gh_float',
    name: '',
    icon_id: '',
    class: 'gh_float gh_col_width_12 gh_col_height_0 gh_height_percent gh_width_percent vertical_scroll',
    priority: 0,
    container_id: 0,
    content: []
  },
  container_settings: [{
    name: 'Add Container',
    icon: 'plus',
    function: 'addContainer',
    arg: '{"allowed_types": ["gh_float", "gh_element", "gh_tabs"], "template_id": "gh_float", "name": "", "icon_id": "", "class": "gh_float gh_col_width_12 gh_col_height_0 gh_height_percent gh_width_percent vertical_scroll", "container_id": 0, "priority": 0, "content": []}'
  }, {
    name: 'Delete Container',
    icon: 'rubbish',
    function: 'deleteContainer'
  }, {
    name: 'Show Title',
    icon: 'plus',
    hide_if: 'gh_title',
    function: 'addClass',
    arg: 'gh_title'
  }, {
    name: 'Hide Title',
    icon: 'rubbish',
    show_if: 'gh_title',
    function: 'removeClass',
    arg: 'gh_title'
  }, {
    name: 'Show Icon',
    icon: 'plus',
    hide_if: 'gh_icon',
    function: 'addClass',
    arg: 'gh_icon'
  }, {
    name: 'Hide Icon',
    icon: 'rubbish',
    show_if: 'gh_icon',
    function: 'removeClass',
    arg: 'gh_icon'
  }, {
    name: 'Width PX',
    icon: 'plus',
    hide_if: 'gh_width_px',
    function: 'replaceClass',
    arg: '{"addClass" : "gh_width_px", "deleteClass" : ["gh_width_percent"]}'
  }, {
    name: 'Width %',
    icon: 'plus',
    hide_if: 'gh_width_percent',
    function: 'replaceClass',
    arg: '{"addClass" : "gh_width_percent", "deleteClass" : ["gh_width_px"]}'
  }, {
    name: 'Width +',
    icon: 'plus',
    function: 'sizeChange',
    arg: 'width plus'
  }, {
    name: 'Width Clear',
    icon: 'cancel',
    function: 'sizeChange',
    arg: 'width clear'
  }, {
    name: 'Width -',
    icon: 'cancel',
    function: 'sizeChange',
    arg: 'width minus'
  }, {
    name: 'Height PX',
    icon: 'plus',
    hide_if: 'gh_height_px',
    function: 'replaceClass',
    arg: '{"addClass" : "gh_height_px", "deleteClass" : ["gh_height_percent"]}'
  }, {
    name: 'Height %',
    icon: 'plus',
    hide_if: 'gh_height_percent',
    function: 'replaceClass',
    arg: '{"addClass" : "gh_height_percent", "deleteClass" : ["gh_height_px"]}'
  }, {
    name: 'Height +',
    icon: 'plus',
    function: 'sizeChange',
    arg: 'height plus'
  }, {
    name: 'Height Clear',
    icon: 'cancel',
    function: 'sizeChange',
    arg: 'height clear'
  }, {
    name: 'Height -',
    icon: 'cancel',
    function: 'sizeChange',
    arg: 'height minus'
  },{
    name: 'Horizontal scroll',
    icon: 'range',
    hide_if: 'horizont_scroll',
    function: 'replaceClass',
    arg: '{"addClass" : "horizont_scroll"}'
  },{
    name: 'Vertical scroll',
    icon: 'zip',
    show_if: 'horizont_scroll',
    function: 'replaceClass',
    arg: '{ "deleteClass" : ["horizont_scroll"]}'
  },{
    name: 'Margin-top on',
    icon: 'side',
    hide_if: 'margin_top',
    function: 'replaceClass',
    arg: '{"addClass" : "margin_top"}'
  },{
    name: 'Margin-top off',
    icon: 'side',
    show_if: 'margin_top',
    function: 'replaceClass',
    arg: '{"deleteClass" : ["margin_top"]}'
  },{
    name: 'Margin-bottom on',
    icon: 'side',
    hide_if: 'margin_bottom',
    function: 'replaceClass',
    arg: '{"addClass" : "margin_bottom"}'
  },{
    name: 'Margin-bottom off',
    icon: 'side',
    show_if: 'margin_bottom',
    function: 'replaceClass',
    arg: '{"deleteClass" : ["margin_bottom"]}'
  }],
  html_editor:
    '<div class="gh_float_block">'+
      //-----------------------------------
      '<div class="edit_template_container_form">'+
        '<div class="edit_template_container_form_icon_title">'+
          '<span ng-click="setIcon(item)" class="icon_block" gh-icon="{{item.icon_id ? item.icon_id : \'box\'}} 86BEF6 40px normal"></span>'+
          '<input class="title_block" ng-model="item.name" placeholder="Title"/>'+
        '</div>'+
        '<pencil-container-settings parent="$parent.lists" lists="item.content" item="item"></pencil-container-settings>'+
      '</div>'+
      //-----------------------------------
      '<div class="gh_float_block_list" ng-init="lists = item.content"'+
      'dnd-list="lists" dnd-allowed-types="item.allowed_types" dnd-drop="dropElement(index, item, external, type, lists)" dnd-inserted="insertedElement()">'+
        '<div ng-if="item.template_id != \'gh_element\'" class="gh_float_block_list_item" ng-class="item.class" ng-repeat="item in lists"'+
        'dnd-draggable="item"'+
        'dnd-effect-allowed="move"'+
        'dnd-moved="$parent.lists.splice($index, 1)"'+
        'dnd-type="item.template_id"'+
        'ng-include="item.template_id + \'_editor\'"></div>'+
        //-----------------------------------
        '<gh-element ng-if="item.template_id == \'gh_element\'" ng-class="item.class" ng-repeat="item in lists" container-id="{{item.container_id}}" decorator="getElementModelT(item.element_id)" elem-src="{{srcForElement}}"'+
        'dnd-draggable="item"'+
        'dnd-effect-allowed="move"'+
        'dnd-moved="$parent.lists.splice($index, 1)"'+
        'dnd-type="item.template_id"></gh-element>'+
      '</div>'+
      //----------------------------------------------------------
      '<div style="clear:both"></div>'+
    '</div>',
  html_form: function (value, func) {
    return `
      <div class="${ value.class }">
        ${ value.icon_id || value.name ?
          `<div class="template_icon_title_block_form">
            ${ value.icon_id ? `<span class="template_icon_form" gh-icon="${ value.icon_id } 86BEF6 40px normal"></span>` : `` }
            ${ value.name ? `<span class="template_title_form">${ value.name }</span>` : `` }
            </div>` : ``
        }
        <div class="float_block_template_repeat_form gh_block_title_name">${ func(value.content) }</div>
      </div>`;
  },
  icon: ''
};
