require('./../gh_elements/range/gh_range.scss');

angular.module('ghRangeModule', [])

  .directive('ghRange', [function() {
    let directive = {};

    directive.restrict = 'E';
    directive.scope = {
      ghModel: '=',
      ghField: '=?',
      min: '@',
      max: '@',
      precision: '@',
      type: '='
    };

    directive.controller = [ '$scope', '$element', function($scope, $element) {
      $scope.type = $scope.type || 'double';

      const MIN = $scope.min || 0;
      const MAX = $scope.max || 100;

      const ghModel = $scope.ghModel ? String($scope.ghModel).split(':') : [];

      if(ghModel[0] && ghModel[1]){
        $scope.value = (ghModel[0] + ghModel[1]) / 2 < MAX || MAX;
      } else $scope.value = $scope.ghModel;

      $scope.pointA = $scope.ghModel ? ghModel[0] : MIN;
      $scope.pointB = $scope.ghModel ? ghModel[1] : MAX;

      $scope.save = function () {
        if( isCorrectlyDouble() && $scope.type === 'double'){
          $scope.ghModel = $scope.pointA + ':' + $scope.pointB;
        }else if ( isCorrectlySingle($scope.value) &&  $scope.type === 'single'){
          $scope.ghModel = $scope.value
        }
      };

      $scope.changeNumber = function () {
        if( isCorrectlyDouble() ){
          setPercents();
          drawLine();
        }; 
      };

      let x, pointStart = 0;
      let point = '';

      const allLine = $element[0].querySelector('.gh_range_container');
      const pointAElement = allLine.querySelector('.gh_range_pointer_a');
      const pointBElement = allLine.querySelector('.gh_range_pointer_b');
      const rangeLine = allLine.querySelector('.gh_range_line');

      function isCorrectlyDouble() {
        return +$scope.pointA >= MIN && +$scope.pointB <= MAX && +$scope.pointA <= +$scope.pointB;
      }

      function isCorrectlySingle(value){
        return Number(value) >= MIN && Number(value) <= MAX;
      }

      function allLineWidth() {
        return allLine.offsetWidth;
      }

      function onePercentWidth() {
        return allLineWidth() / 100;
      }

      function numberToPercent(number) {
        let pointsMax = MAX - MIN;
        let points = (+number) - MIN;

        return points * 100 / pointsMax;
      }

      function percentToNumber(percent) {
        let pointsMax = MAX - MIN;

        return (((+percent.replace('%', '')) * pointsMax / 100) + (+MIN)).toFixed($scope.precision);
      }

      function setPercents() {
        pointAElement.style.left = numberToPercent($scope.pointA) >= 0 ? numberToPercent($scope.pointA) + '%' : '0%';
        pointBElement.style.left = numberToPercent($scope.pointB) <= 100 ? numberToPercent($scope.pointB) + '%' : '100%';
      }

      setPercents();

      function setModelNumbers() {
        $scope.pointA = percentToNumber(pointAElement.style.left);
        $scope.pointB = percentToNumber(pointBElement.style.left);

        $scope.$apply();
      }

      function targetLeftPercent(element) {
        return +element.style.left.replace('%', '');
      }

      function drawLine() {
        rangeLine.style.left = pointAElement.style.left;
        rangeLine.style.width = targetLeftPercent(pointBElement) - targetLeftPercent(pointAElement) + '%';
      }

      drawLine();

      function targetLeftPX(element) {
        return (+element.style.left.replace('%', '')) * onePercentWidth();
      }

      function movePoint(move) {
        return move < allLineWidth() ? (move > 0 ? move / onePercentWidth() + '%' : '0%') : '100%';
      }

      function mouseMove(event) {
        let move = pointStart + event.x - x;

        switch (point) {
          case 'A':{
            if( move <= targetLeftPX(pointBElement) ){
              pointAElement.style.left = movePoint(move);
            }

            break;
          }

          case 'B': {
            if( move >= targetLeftPX(pointAElement) ){
              pointBElement.style.left = movePoint(move);
            }

            break;
          }
        }

        setModelNumbers();
        drawLine();
      }

      function mouseDown(event) {
        x = event.x;

        if(event.target === pointAElement || event.target === pointBElement){
          event.preventDefault();
          pointStart = targetLeftPX(event.target);

          if(event.target === pointAElement){
            point = 'A';
          } else if(event.target === pointBElement){
            point = 'B';
          }

          document.addEventListener("mouseup", mouseUp, false);
          document.addEventListener("mousemove", mouseMove, false);
        }
      }

      function mouseUp() {
        $scope.save();
        $scope.$apply();

        document.removeEventListener("mouseup", mouseUp, false);
        document.removeEventListener("mousemove", mouseMove, false);
      }
      if($scope.type === 'double'){
        $element[0].addEventListener("mousedown", mouseDown, false);
      }

      $scope.$on('$destroy', function() {
        $element[0].removeEventListener("mousedown", mouseDown, false);
        document.removeEventListener("mouseup", mouseUp, false);
        document.removeEventListener("mousemove", mouseMove, false);
      });

      $scope.checkNaN = function (value) {
        return isNaN(value);
      }
    }];
    directive.template = `
      <div class="gh_range" ng-show="type === 'double'">
        <div class="gh_range_container">
          <div class="range_all_line"></div>
          <div class="gh_range_line"></div>
          <span class="gh_range_pointer_a"></span>
          <span class="gh_range_pointer_b"></span>
        </div>
        <div class="range_container_input">
          <input class="for_point_a" type="text" ng-class="{'no_valid' : +pointA < +min || +pointA > +pointB || checkNaN(+pointA)}" ng-model="pointA" ng-change="changeNumber()" ng-blur="save()">
          <input class="for_point_b" type="text" ng-class="{'no_valid' : +pointB > +max || +pointB < +pointA || checkNaN(+pointB)}" ng-model="pointB" ng-change="changeNumber()" ng-blur="save()">
        </div>
      </div>
      <div class="inputWrapper" ng-show="type === 'single'">
        <input type="range" ng-model="value" min="{{min}}" max="{{max}}" ng-mouseup="save()" ng-blur="save()"/>
        <input type="text" class="value" ng-class="{'no_valid': +value  < +min || +value > +max || checkNaN(+value)}" ng-model="value" ng-change="save()"/>
      </div>`;

    return directive;
  }]);
