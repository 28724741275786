require('./app_processor.js');
require('./gh_element/gh_element.js');

angular.module('dataInterpreter', [
  'appDataProcessor'
])




/*=======================================================================================================*/
/*=========================================  DIRECTIVE INTERPRETER  =====================================*/
/*=======================================================================================================*/
/* We use interpreter to convert value according to data_model in user friendly format*/
/* e.g option value are stored in number format, with the interpreter we convert it to text format*/

.directive('interpreter', ['$compile', 'cnfg', 'interpritate', '$q', function($compile, cnfg, interpritate, $q) {
  return {
    restrict: 'E',
    scope: {
      intrValue: '=',
      /*-- in this way we take model from 'gh-model' attribute and apply it to '$scope.ghModel'*/
      intrFieldData: '=',
      intrDataType: '@',
      intrSrc: '@',
      itemId: '=?',
      appId: '=?',
      /*-- interpretation place*/
      intrItem: '='
    },

    link: function(scope, elem, attrs) {

      scope.field_model = {};
      angular.merge(scope.field_model, scope.intrFieldData);
      scope.field_model.field_value = scope.intrValue;

      //-- If model changed we start rendering againe
      //scope.$watch('intrFieldData', function(val){
          if (angular.isDefined(scope.intrValue) ) {
            interpritate.getInterpretation(scope.intrValue, scope.intrFieldData, scope.intrDataType, scope.intrSrc, scope.itemId, scope.appId).then(function(result) {
              if(result){
                updateView(result.html);
              }//else updateView ("Interpritation Problem");
            });

          }
      //}, true);


      /*---- Rendering html*/
      function updateView(value) {
        value = value || '';
        /*-- if cell value is empty then we create a dummy with <span/> tag*/
        if (value.length || value.indexOf('<') == -1) {
          value = '<span>' + value + '</span>';
        }

        var el = angular.element(value);
        compiled = $compile(el);
        elem.empty();
        elem.append(el);
        compiled(scope);
      }
    }
  };
}])





/*=======================================================================================================*/
/*=================================   INTERPRETER FACTORY   =============================================*/
/*=======================================================================================================*/
.factory('interpritate', ['cnfg', '$q', 'GHConstructor', 'appDataProcesingService', function(cnfg, $q, GHConstructor, appDataProcesingService) {

  // ---------------  Get interpritation object ------------------------------
  // In old data_models interpritation is 'Object'. In new - 'Array
  function getInterpretationObj(fieldDataModel, defaultFieldDataModel, src, containerId){

    var currentIntrpr = Object.create(null);

    var defaultIntrprObj = defaultFieldDataModel.data_model.interpretation.find(function (interpritation) {
      return interpritation.src == src;
    }) || defaultFieldDataModel.data_model.interpretation.find(function (interpritation) {
        return interpritation.src == 'table';
    }) || { id: 'default'};

    if(fieldDataModel.data_model && fieldDataModel.data_model.interpretation){
      // To detect interpretation we use (container_id), if there is not (container_id) we use (src)
      currentIntrpr = fieldDataModel.data_model.interpretation.find(function (interpritation) {
        return interpritation.src == containerId;
      }) || fieldDataModel.data_model.interpretation.find(function (interpritation) {
        return interpritation.src == src;
      });
    }

    return angular.merge(defaultIntrprObj, currentIntrpr);
  }


  return {
    log: cnfg.log.interpritate,
    /*- show/hide console output*/

    /*----------------------------- GET INTERPRETATION  --------------------------------*/
    /*-- It's an initial method that decide a further methods for interpretation*/
    getInterpretation: function(value, field, dataType, source, itemId, appId, containerId) {
    //console.log(field)

      var deferred = $q.defer();

      /*-- By default we use 'data_type' from field but in case if field is undefined we use 'dataType' from attribute*/
      var data_type = field && field.data_type ? field.data_type : dataType;

      /*---- Constructing Data Object ----*/
      if (data_type) {
        /*-- if we have data_type then we construct new data object to interpritate value*/
        GHConstructor.getInstance(data_type).then(function(data){
          var interpretationObj = getInterpretationObj(field, data.getTemplate().model, source, containerId);
          data.getInterpretation(value, field, data_type, interpretationObj.id, itemId, appId).then(function(result) {
         // console.log(result, interpretationObj)

            deferred.resolve(angular.merge(result, interpretationObj));
          }, function(error) {
            deferred.reject();
          });
        }, function(error){});

      } else {
        /*-- if we don't have data_type then we run defaultInterpretation function*/
        deferred.resolve(this.getDefaultInterpretation(value, field));
      }

      return deferred.promise;
    },



    /*----------------------------- GET DEFAULT INTERPRETATION -------------------------*/
    getDefaultInterpretation: function(value, options) {
      var result = value;

      if (options) {
        /*-- Iterating through options from data_model to find a nave for selected*/
        angular.forEach(options, function(item) {
          if (item.value == value && value != "") { /*--when we compare ("" == 0 ) then we receive true, and as result we receive item[0] as interpritation, that is not corrrect, that is why we check if if the no empty string*/
            result = item.name;
          }
        });
      }
      return result;
    }




    /*----------------------------- GET FIELD MODEL -------------------------*/
    /*
    getFieldModel: function( fieldId, appId ) {
      var deferred = $q.defer();

      appDataProcesingService.getApp(appId, '', 'data_interpreter').then(function(app) {
        angular.forEach(app.field_list, function(field) {
          if (field.field_id == fieldId) {
           deferred.resolve(field);
          }
        });
      });

      return deferred.promise;
    }
    */

  };
}]);
