require('./help.scss');

angular.module('helpModul', [])

    .controller('helpCtrl', ['$scope','fieldTemplatesStorage','GHConstructor', '$element', '$routeParams', '$location', '$timeout', 'helpService', function($scope,fieldTemplatesStorage, GHConstructor, $element, $routeParams, $location, $timeout, helpService) {
        //-- Getting list of elements
        $scope.gh_elements_catalog = fieldTemplatesStorage.getFieldsTemplates()

        GHConstructor.getInstance($routeParams.id).then(function(elementTemplate){
            elementTemplate = elementTemplate.getTemplate();
            /* in case if module is private we don't push it into field's list */
            if (!elementTemplate['private']){
                $scope.current_element = elementTemplate;
                $scope.fieldModel = $scope.current_element.model;
                $scope.tags = $routeParams.id;
            }
          });
        $scope.active_element = $routeParams.id;
        $scope.changeCategory = function( element ) {
            $timeout(function () {
                $location.path('help/' + element.type)
            }, 0);
        }
    
        $scope.$on('mainObj', function (event, data, decript) {
            $scope.fieldValue = data;
            $scope.dataTypeDecrtiption = decript;
        });
    }])
    .filter('trustHtml', ['$sce', function($sce){
        return function(html){
          return $sce.trustAsHtml(html)
        }
    }])
    .directive('helpDescription',  ['helpService', function(helpService) {
        return { 
            restrict: 'E',
            scope: {
              tagsSet: '@?',
              tags: '=?'
            },
  
            controller: ['$scope', 'cnfg', 'fileManager', '$compile', '$element',  function($scope, cnfg, fileManager, $compile, $element) {
                $scope.fieldModel = $scope.$parent.fieldModel;
                $scope.$watchCollection('tags', function(newValue) { 
                    $scope.fullValueAndColumn = {
                        colums: {},
                        help_items: []
                    };
                    //-- Creating list of tags for item filtering
                    if(newValue !== 'undefined')
                    helpService.getItems( $scope.$parent.tags, $scope.$parent ).then(function(getItemForRendering) {
                        let objToSend = [];
                        let objDecript = [];
                        var field_id = cnfg.app_help.field_id_description;
                        //-- Clear container before inserting new content
                        //-- Generating HTML conteng based on received items
                        getItemForRendering.forEach(function(item, index){
                            let tempObj = [];
                            let tempObjHtml = [];
                            if(item && item.length > 1){
                                item[1].forEach(function(item_in_item, index) {    
                                    if(typeof item_in_item === 'object' ){
                                        var field_value = helpService.getFieldValue(item_in_item, field_id);
                                        fileManager.downloadFileFromString(cnfg.app_help.app_id, field_value).then(function(file) {
                                            tempObjHtml.push(file.data);
                                            if(index + 1 == item[1].length){
                                                sendMainObj();
                                            }
                                        });
                                    }else if(item_in_item == 'field_name'){
                                       var fieldValue = "<h3>Field Name</h3><p>Here you can change the name of the field.</p>"
                                        tempObjHtml.push(fieldValue);
                                    }else if(item_in_item == 'name_space'){
                                        var fieldValue = '<h3>Name space</h3><p>Name space is text identification field.'
                                        + 'It is used for data export/import and binding with other applications.'
                                        + 'The value for this field is set by default, but can be changed if necessary.'
                                        + 'Name space field must not contain capital letters or blank spaces.</p>'
                                        tempObjHtml.push(fieldValue);
                                    }else if(typeof item_in_item === 'undefined'){
                                        $scope.fullValueAndColumn = objToSend;
                                        sendMainObj();
                                    }
                                });
                            }else{
                            if(item){
                                var field_value = helpService.getFieldValue(item, field_id);
                                fileManager.downloadFileFromString(cnfg.app_help.app_id, field_value).then(function(file) {
                                    objDecript.push(file.data);
                                    if(index + 1 == item.length || file){
                                        sendMainObj();
                                        }
                                    });
                                }
                            }
                       
                            tempObj.push(tempObjHtml);
                            if(item && item.length > 1){
                                tempObj.push(item[0]);
                                objToSend.push(tempObj)
                            }
                        })
                        $scope.decription = objDecript;
                        $scope.fullValueAndColumn = objToSend;
                        function sendMainObj(){
                            $scope.$emit('mainObj', $scope.fullValueAndColumn, $scope.decription)
                        }  
                    });
                });
            }],
        };
    }])

    .service('helpService', ['$injector', 'cnfg', 'GHConstructor',  'PipeService', 'GhElementEditor', 'advancedFiltering', 'fileManager', '$q',  function($injector, cnfg,  GHConstructor, PipeService, GhElementEditor, advancedFiltering, fileManager, $q) {

        this.getFieldValue = function(item, field_id) {
            var fieldValue = 0;
            if(item !== undefined){
                for (var i = 0; i < item.fields.length; i++) {
                    if (item.fields[i].field_id == field_id) {
                        fieldValue = item.fields[i].field_value;
                        return fieldValue;
                    }
                }
            }
        };

        this.getItems = function(tags, scopeperent) {
            var deferred = $q.defer();
            var getItemForRendering = {};
            let nameSpaceForColumn = [];
            var nameSpaceAll = [];
            var alltest = [];
            GHConstructor.getInstance(tags).then(function (data){
                let settings = GhElementEditor.mergeTabs(data.getSettings(scopeperent), 'general_setting');
                 if(settings.length != 0){ 
                    settings[0].columns_list.forEach(function(item, index){
                        nameSpaceAll = [];
                        alltest = []
                        item.forEach(function(item_in_item){
                            if(item_in_item.hasOwnProperty('data_model')){
                                let dataModel = item_in_item.data_model(scopeperent.fieldModel);
                                if(typeof dataModel.name_space != 'undefined'){
                                    nameSpaceAll.push(dataModel.name_space)
                                }
                            }
                        })
                        alltest.push(item);
                        alltest.push(nameSpaceAll); 
                        nameSpaceForColumn.push(alltest);
                    })
                }
            getFilterHelpList(nameSpaceForColumn);
            });
            function getFilterHelpList(nameSpaceForColumn){
                let itemsListFromNameSpace = [];

                let address = {
                    app_id: cnfg.app_help.app_id
                };

                let filterListDescription = [{
                    data_type: 'text',
                    field_id: cnfg.app_help.field_id_map,
                    search_type: 'equal_and',
                    valuesArray: [tags, 'description_set']
                }];

                PipeService.on('gh_items_get', address, function getAppItemsPipe(event, items) {
                    PipeService.destroy('gh_items_get', address, getAppItemsPipe);
                    advancedFiltering(items, filterListDescription).then(function(itemsList) {
                        nameSpaceForColumn.push(itemsList[0])
                        deferred.resolve(nameSpaceForColumn);
                    });
                }).emit('gh_items_get', {}, address);

                nameSpaceForColumn.forEach(function(item){
                    item[1].forEach(function(item_in_item){
                        
                      
                        let filterList = [{
                            data_type: 'text',
                            field_id: cnfg.app_help.field_id_map,
                            search_type: 'equal_and',
                            valuesArray: [tags, item_in_item]
                        }];
                        PipeService.on('gh_items_get', address, function getAppItemsPipe(event, items) {
                            PipeService.destroy('gh_items_get', address, getAppItemsPipe);
                            advancedFiltering(items, filterList).then(function(itemsList) {
                                item[1].push(itemsList[0])
                                deferred.resolve(nameSpaceForColumn);
                            });
                        }).emit('gh_items_get', {}, address);
                    })
                })
            }
            return deferred.promise;
        }
    }])