import { getDistanceFromLatLonInKm, getDate } from "./utils";

angular.module('advancedFilteringModule', [])
.factory('advancedFiltering', ['$filter', '$ocLazyLoad', '$injector', 'cnfg', 'storage', '$q', 'GHConstructor', function($filter, $ocLazyLoad, $injector, cnfg, storage, $q, GHConstructor) {
  return function(items, filters = []) {
    return new Promise(function(resolve) {
      if (filters.length) {
        var promises = [];
        filters.forEach(function(_filter) {
          if (!$injector.has(_filter.data_type)) {
            var deferred = $q.defer();
            promises.push(deferred.promise);
            GHConstructor.getInstance(_filter.data_type).then(function (data) {
              deferred.resolve();
            });
          }
        });

        $q.all(promises).then(function() {
          resolve($filter('advanced')(items, filters));
        });
      } else {
        resolve(items);
      }
    });
  };
}])

.factory('filterChecker', function() {
  'use strict';

  class Checker {
    changeBehavior(checkOption) {
      switch (checkOption) {
        case 'contain_or':
          this._checkFn = function (data, filtersValues) {
            return filtersValues.some((_filter) => data.some((_dataItem) => _dataItem.indexOf(_filter) !== -1));
          };
          break;
        case 'contain_and':
          this._checkFn = function (data, filtersValues) {
            return filtersValues.every((_filter) => data.some((_dataItem) => _dataItem.indexOf(_filter) !== -1));
          };
          break;
        case 'not_contain_or':
          this._checkFn = function (data, filtersValues) {
            return filtersValues.some((_filter) => data.every((_dataItem) => _dataItem.indexOf(_filter) === -1));
          };
          break;
        case 'not_contain_and':
          this._checkFn = function (data, filtersValues) {
            return filtersValues.every((_filter) => data.every((_dataItem) => _dataItem.indexOf(_filter) === -1));
          };
          break;
        case 'equal_or':
          this._checkFn = function (data, filtersValues) {
            if (!data.length) return false;
            return data.some((_dataItem) => filtersValues.some((_filter) => _dataItem == _filter));
          };
          break;
        case 'equal_and':
          this._checkFn = function (data, filtersValues) {
            if (!data.length) return false;

            let filtersValuesSet = new Set(filtersValues);
            while (data.length && filtersValuesSet.size) {
              let dataValue = data.pop();
              if (filtersValuesSet.has(dataValue)) {
                filtersValuesSet.delete(dataValue);
              }
            }
            return !filtersValuesSet.size;
          };
          break;
        case 'not_equal_or':
          this._checkFn = function (data, filtersValues) {
            if (!data.length) return true;
            let filtersValuesSet = new Set(filtersValues);
            while (data.length && filtersValuesSet.size) {
              let dataValue = data.pop();
              if (!filtersValuesSet.has(dataValue)) {
                return true;
              }
            }
            return false;
          };
          break;
        case 'not_equal_and':
          this._checkFn = function (data, filtersValues) {
            let filtersValuesSet = new Set(filtersValues);
            while (data.length && filtersValuesSet.size) {
              let dataValue = data.pop();
              if (filtersValuesSet.has(dataValue)) {
                return false;
              }
            }
            return true;
          };
          break;
        case 'bigger':
          this._checkFn = function (data, filtersValues) {
            return filtersValues.some((_filter) => data.every((_dataItem) => _dataItem > _filter));
          };
          break;
        case 'lower':
          this._checkFn = function (data, filtersValues) {
            return filtersValues.some((_filter) => data.every((_dataItem) => _dataItem < _filter));
          };
          break;
        case 'range':
          this._checkFn = function (data, filtersValues) {
            return filtersValues.some((_filter) => data.every((_dataItem) => (_filter.start <= _dataItem) && (_dataItem < _filter.end)));
          };
          break;
        case 'value':
          this._checkFn = function (data, filtersValues) {
            return filtersValues.some((_filter) => data.some((_dataItem) => _dataItem == _filter));
          };
          break;
        case 'phone_equal_or':
          this._checkFn = function (data, filtersValues) {
            if (!data.length) return false;
            return filtersValues.some((_filter) => data.some((_dataItem) => _dataItem.replace(/[^0-9]/g, '').indexOf(_filter.replace(/[^0-9]/g, '')) !== -1));
          };
          break;
        case 'distance':
          this._checkFn = function (data, filtersValues) {
            return filtersValues.some((_filter) => data.some((_dataItem) => getDistanceFromLatLonInKm(_filter, _dataItem)));
          };
          break;
        case 'date_in':
        case 'date_out':
          this._checkFn = function (data, filtersValues) {
            return filtersValues.some((_filter) => data.some((_dataItem) => getDate(_filter, _dataItem)));
          };
          break;
      }
      return this;
    }

    check(aggregate) {
      return this.changeBehavior(aggregate.getCheckOption())._checkFn(aggregate.getEntity(), aggregate.getFilterValues());
    }
  }



  return new Checker();
})

.factory('filterAggregate', function() {
  'use strict';

  class NumberFetchStrategy {
    convert(val) {
      return [Number(val)];
    }

    convertFilterValue(val) {
      return Number(val);
    }
  }

  class RangeFetchStrategy extends NumberFetchStrategy {
    convertFilterValue(val) {
      return {
        start: Number(val.split(':')[0]),
        end: Number(val.split(':')[1])
      };
    }
  }
  class dateFetchStrategy extends NumberFetchStrategy {
    convertFilterValue(val) {
      const [type, date, match] = val.split(':');
      return {
        type,
        date: Number(date),
        match: !!Number(match)
      };
    }
  }

  class StringFetchStrategy {
    convert(val) {
      return String(val || '').toLowerCase().split(',');
    }

    convertFilterValue(val) {
      if (val === 0) return '0';
      return String(val || '').toLowerCase();
    }
  }

  class BooleanFetchStrategy {
    convert(val) {
      return [String(Boolean(val))];
    }

    convertFilterValue(val) {
      return String(val);
    }
  }

  class Aggregate {
    constructor() {
      this._strategies = {
        stringStrategy: new StringFetchStrategy(),
        numberStrategy: new NumberFetchStrategy(),
        booleanStrategy: new BooleanFetchStrategy(),
        rangeStrategy: new RangeFetchStrategy(),
        dateStrategy: new dateFetchStrategy()
      };
    }

    setStrategy(checkOption) {
      this._checkOption = checkOption;
      switch (checkOption) {
        case 'contain_or':
        case 'contain_and':
        case 'not_contain_or':
        case 'not_contain_and':
        case 'equal_or':
        case 'equal_and':
        case 'not_equal_or':
        case 'not_equal_and':
        case 'phone_equal_or':
        case 'distance':
          this._currentStrategy = this._strategies.stringStrategy;
          break;
        case 'bigger':
        case 'lower':
          this._currentStrategy = this._strategies.numberStrategy;
          break;
        case 'range':
          this._currentStrategy = this._strategies.rangeStrategy;
          break;
        case 'date_in':
        case 'date_out':
          this._currentStrategy = this._strategies.dateStrategy;
          break;
        case 'value':
          this._currentStrategy = this._strategies.booleanStrategy;
          break;
      }
      return this;
    }

    setEntity(val) {
      this._entity = this._currentStrategy.convert(val);
      return this;
    }

    getEntity() {
      return this._entity;
    }

    setFilterValues(val) {
      let temp = Array.isArray(val) ? val : [val];
      this._filterValues = temp.map((curFilterVal) => this._currentStrategy.convertFilterValue(curFilterVal));
      return this;
    }

    getFilterValues() {
      return this._filterValues;
    }

    getCheckOption() {
      return this._checkOption;
    }
  }

  return new Aggregate();
});
