const GUDHUB_SETTINGS = {
  /*------------------------- SERVER's URL -----------------------*/
  server_url: GUDHUB_SERVER_API_SCHEME + '://' + GUDHUB_SERVER_API_HOST + '/' + GUDHUB_SERVER_API_PATH,
  ws_url: 'wss://' + GUDHUB_SERVER_API_HOST + '/' + GUDHUB_SERVER_API_PATH,
  node_services_api_path: GUDHUB_NODE_SERVICES_SCHEME + '://' + GUDHUB_NODE_SERVICES_HOST + GUDHUB_NODE_SERVICES_API_PATH,
  node_services_host: GUDHUB_NODE_SERVICES_SCHEME + '://' + GUDHUB_NODE_SERVICES_HOST,
  file_server_url: GUDHUB_FILE_SERVER_SCHEME + '://' + GUDHUB_FILE_SERVER_HOST + ':' + GUDHUB_FILE_SERVER_PORT,
  async_modules_path: 'build/' + GUDHUB_VERSION + '/async_modules/',
  /*------------------------- DEVELOPMENT MODE -----------------------*/
  /*-- In dev mode we don't remove directives tag*/
  app_help: {
      app_id: 7054,
      field_id_map: 82396,
      field_id_description: 82391
  },
  maxDuplicatedFiles: 10,
  development_mode: GUDHUB_SETTINGS_DEVELOPMENT_MODE,
  show_context_menu: GUDHUB_SETTINGS_SHOW_CONTEXT_MENU,
  log: {
    /*-- main.js*/
    MainCtrl: true,
    /*-- app/app_controller.js*/
    ApplicationCtrl: true,
    /*-- app/data_interpreter.js*/
    interpritate: true,
    /*-- apps_manager/app_processor.js*/
    appsStorage: true,
    /*-- apps_manager/applications_contrl.js*/
    HomePageCtrl: true,
    /*-- web/web_pages_contrl.js*/
    WebPageCtrl: true,
    /*-- apps_manager/app_processor.js*/
    appDataProcesingService: true,
    applicationApi: true,
    /*-- apps_manager/file_manager.js*/
    fileStorage: true,
    fileManager: true,
    /*-- app/form/form_controller.js*/
    FormCtrl: true,
    formService: true,
    FormDirectives: true,
    /*-- app/form/item_processor.js*/
    itemDataProcessing: true,
    itemScope: true,
    itemApi: true,
    /*-- user/authorization_contrl.js*/
    AuthorizationCtrl: true,
    authService: true,
    authApi: true,
    /*-- storage/main_storage.js*/
    storage: true,
    /*-- gui/gh_itemUpdate.js*/
    ghItemUpdate: true
  }
};



angular.module('config', [])

.run(['cnfg', function (cnfg) {
  window.gudhub = {};

  Object.defineProperty(window.gudhub, 'development_mode', {
    set: function (value = false) {
      cnfg.development_mode = value;
    },
    get: function () {
      return cnfg.development_mode;
    }
  });

}])


/*==========================================================================================================*/
/*================================     CONVIGURATION CONTROLLER       ======================================*/
/*==========================================================================================================*/
.controller('configCtrl', ['$scope', 'cnfg', function($scope, cnfg) {

  $scope.version = {
    client: '1.0.1',
    server: servercnfg.getAppVersionApi()
  };

  /*-- Show/Hide Log Messages*/
  cnfg.initLogMessages();

}])

/*==========================================================================================================*/
/*=======================================     CONVIGURATIONS      ==========================================*/
/*==========================================================================================================*/
.service('cnfg', [ '$http', function($http) {

    /*------------------- GETTING SERVER VERSION --------------------*/
  this.getAppVersionApi = function() {
      var apiUrl = this.server_url + "/";
      return $http({
        method: 'GET',
        url: apiUrl + 'version',
      }).then(function(result) {
        scope.version.server = result.data;
        return result.data;
      });
    };

    /*------------------- INIT LOG MESSAGES --------------------*/
  this.initLogMessages = function() {
    var self = this;

    angular.forEach(this.log, function(module, key) {
      self.log[key] = module && self.development_mode;
    });
  };

  Object.assign(this, GUDHUB_SETTINGS);
  return this;

}]);
