import ContextMenu from '../../gui/context_menu';

require('./gh_element_editor.js');
require('../data_interpreter.js');
require('../app_processor.js');
import "./../../automation/gh_automation";
import Rete from "rete";
import {Modules} from './../../automation/automation_modules.js';

/************************************************************************************************************|
|*********************************************    GH-ELEMENT    *********************************************|
|************************************************************************************************************|
|  ┌──────────────────────────────────────────────────────────────────────────────────────────────────────┐
|  │                               <div gh-element="open_item_action" ></div>                             │
|  └──────────────────────────────────────────────────────────────────────────────────────────────────────┘
|
|  gh-element could be:
|      ◆ action - used to make action with data
|      ◆ field  - used to render fields
|      ◆ elem-src  - used to set source fields for interpretation
|
|**********************************************************************************************************|
*/


angular.module('ghElement', [
    'ghElementEditorModule',
    'mainStorage',
    'ghConstructor',
    'dataInterpreter',
    'appDataProcessor',
    'mainStorage',
    'pipeModule',
    'config',
    'authorizationMod',
    'ghAutomationModule'
])

.directive('ghElementWidget', ['appDataProcesingService', 'GHConstructor', 'GhElementFactory', 'storage', function(appDataProcesingService, GHConstructor, GhElementFactory, storage) {
    var directive = {
        restrict: 'E',
        scope: {
            appId: '=',
            fieldId: '=',
            authKey: '='
        },

        controller: ['$scope', function($scope) {
            storage.setUser({
                auth_key: $scope.authKey,
                expirydate: 0
            });
        }],

        link: function(scope, elem, attrs) {
            appDataProcesingService.getApp(scope.appId).then(function(app) {
                scope.window = {
                    data: {
                        fields: app.field_list
                    }
                };

                angular.forEach(app.field_list, function(field) {
                    if (scope.fieldId == field.field_id) {
                        GHConstructor.getInstance(field.data_type).then(function(data) {
                            attrs.action = scope.fieldId;
                            switch (data.getTemplate().constructor) {
                                case 'action':
                                    GhElementFactory.action(scope, elem, attrs);
                                    break;
                                case 'field':
                                    GhElementFactory.field(scope, elem, attrs);
                                    break;
                            }
                        });

                    }
                });
            });
        }
    };

    return directive;
}])

.directive('ghElementContainer', ['storage', 'authApi', '$window', function(storage, authApi, $window) {
    var directive = {
        restrict: 'E',

        controller: ['$scope', function($scope) {
            $scope.isTokenReady = false;
            if ($window.localStorage.auth_key) {
                authApi.updateToken($window.localStorage.auth_key).then(function(res) {
                    storage.setUser(res.data);
                    $scope.isTokenReady = true;
                });
            } else {
                var watcher = $scope.$watch(function() {
                    return $window.localStorage.auth_key;
                }, function(n) {
                    if (n) {
                        authApi.updateToken($window.localStorage.auth_key).then(function(res) {
                            storage.setUser(res.data);
                            $scope.isTokenReady = true;
                            watcher();
                        });
                    }
                });
            }

        }]
    };

    return directive;
}])


/*============================================================================================================|
|===========================================  GH-ELEMENT DIRECTIVE   =========================================|
|=============================================================================================================|
| GH-ELEMENT do the next things:
|  - creates field_model that is used for rendering of field or action
|  - listen for updates in pipe of field_value from out side and then send it to field
|  - sends updates of field_value to pipe
|  - listen for updates of field_model from out side and rerender field or action
|
| GH-ELEMENT has following data:
|  - appId: '@?'
|  - itemId: '@?'
|  - fieldId: '@?'
|  - elemSrc: '@?'
|  - decorator: '=?'
|  - value: '=?'
|  - field_model:
*/
.directive('ghElement', ['GHConstructor', 'GhElementFactory', 'fieldProcesingService', 'PipeService', '$timeout', '$compile', 'UtilsService', '$injector', 'cnfg', function(GHConstructor, GhElementFactory, fieldProcesingService, PipeService, $timeout, $compile, UtilsService, $injector, cnfg) {

    var directive = {
        restrict: 'AE',
        scope: {
            appId: '@?',
            itemId: '@?',
            fieldId: '@?',
            containerId: '@?',
            elemSrc: '@?',
            decorator: '=?',
            value: '=?'
        },
        controller: ['$scope', '$element', '$attrs', 'PipeService', 'GHConstructor', 'Message', 'GhElementEditor', '$location', 'formEditorService', '$routeParams', function($scope, $element, $attrs, PipeService, GHConstructor, Message, GhElementEditor, $location, formEditorService, $routeParams) {
            $scope.update_state = false; // value 
            $scope.field_model = {
                settings: {},
                container_id: $scope.containerId
            };

            var value_address = {};

            Object.defineProperty($scope.field_model, 'field_value', {
                get: function() {
                    return $scope.value;
                },
                set: function(newValue) {
                    if (newValue != $scope.value) {
                        $scope.value = newValue;

                        if (value_address.item_id) {
                            value_address.new_value = newValue;
                            PipeService.emit('gh_value_set', {}, value_address);
                        }

                    }
                }
            });


            if ($scope.appId && $scope.fieldId) {
                var model_address = {
                    app_id: $scope.appId,
                    field_id: $scope.fieldId
                };

                let modelPipe = function modelPipe(event, field_model) {
                    var tempInterpr;
                    if ($scope.decorator && $scope.decorator.data_model && $scope.decorator.data_model.interpretation) {
                        tempInterpr = angular.copy(field_model.data_model.interpretation);
                        UtilsService.mergeInterpretations(tempInterpr, $scope.decorator.data_model.interpretation, 'src');
                    }

                    angular.merge($scope.field_model, angular.merge(angular.copy(field_model), $scope.decorator));
                    if ($scope.decorator && $scope.decorator.data_model && $scope.decorator.data_model.interpretation) {
                        $scope.field_model.data_model.interpretation = tempInterpr;
                    }

                    if (field_model.data_model && field_model.data_model.code) {
                        eval(field_model.data_model.code);
                    }

                    //-- automation init
                    if ($scope.itemId && $scope.field_model.data_model.hasOwnProperty('automation')){
                        initAutomation();
                    }

                    rendering();
                    // Destroy init field_model event
                    PipeService.destroy('gh_model_get', model_address, modelPipe);
                };


                PipeService
                    .on('gh_model_get gh_model_update', model_address, modelPipe, $scope)
                    .emit('gh_model_get', {}, model_address);

                value_address = {
                    app_id: $scope.appId,
                    item_id: $scope.itemId,
                    field_id: $scope.fieldId
                };

                // for calculator (example app team vacation)
                $scope.callbacks = [];

                function pipeFn(event, field_value) {
                    $scope.value = field_value;
                    if ($scope.callbacks.length) {
                        $scope.callbacks.forEach((cb) => cb(value_address.item_id));
                    }
                    PipeService.destroy('gh_value_get', value_address, pipeFn);
                }

                function pipeValueGet(event, field_value) {
                    $scope.value = field_value;
                    PipeService.destroy('gh_value_get', value_address, pipeValueGet);
                }

                function pipeValueUpdate(event, field_value) {
                    $scope.update_state = true;
                    $timeout(function() {
                        $scope.update_state = false;
                    }, 1000);
                    $scope.value = field_value;
                }

                PipeService.on('gh_value_get', value_address, pipeValueGet, $scope).emit('gh_value_get', {}, value_address);
                PipeService.on('gh_value_update', value_address, pipeValueUpdate, $scope);

                // This nead to correct show string join and calculator
                PipeService.on('gh_value_update gh_value_get', value_address, pipeFn, $scope).emit('gh_value_get', {}, value_address);

                //-- If GH-Element changes itemId we unsubscribe from previous item and subscribe for new item
                // e.g in gh-table we have virtual reepite that changes itemId
                $attrs.$observe('itemId', function(newItemId) {
                    if (newItemId !== value_address.item_id) {
                        PipeService.destroy('gh_value_update', value_address, pipeValueUpdate);
                        PipeService.destroy('gh_value_update', value_address, pipeFn);
                        value_address.item_id = newItemId;
                        PipeService.on('gh_value_get', value_address, pipeValueGet, $scope).emit('gh_value_get', {}, value_address);
                        PipeService.on('gh_value_update', value_address, pipeValueUpdate, $scope);

                        // This nead to correct show string join and calculator
                        PipeService.on('gh_value_update gh_value_get', value_address, pipeFn, $scope).emit('gh_value_get', {}, value_address);

                    }
                });
            } else {
                angular.extend($scope.field_model, $scope.decorator);
                rendering();
            }

            function saveChanges(fieldData) {
                let fieldModel = Object.assign({}, fieldData);
                delete fieldModel.settings;
                
                PipeService.emit('gh_model_update', {}, {
                    app_id: $scope.appId,
                    field_id: $scope.fieldId,
                    field_model: fieldModel
                });
            }

            var addressApp = {
                app_id: $scope.appId ? $scope.appId : $routeParams.param_1
            };
            
                PipeService.on('gh_app_info_get', addressApp, function app_listPipe(event, storageAppsList) {
                    PipeService.destroy('gh_app_info_get', addressApp, app_listPipe);
                        if (cnfg.show_context_menu && storageAppsList.permission == 4 || cnfg.show_context_menu && storageAppsList.permission == 3) {
                            // ------------------ CONTEXT MENU FOR EDIT FIELD ------------------------
                            let contextMenu = new ContextMenu($element[0], [{
                                title: 'Edit template',
                                events: {
                                    click: function(e) {
                                        $scope.$evalAsync(() => {
                                            $location.path('act/edit_template/' + $scope.appId);
                                        });
                                    }
                                }
                            }, {
                                title: 'Edit field',
                                events: {
                                    click: function(e) {
                                        GhElementEditor.edit($scope.field_model, $scope.appId, $scope.itemId).then(function(fieldData) {
                                            if ($scope.appId) {
                                                saveChanges(fieldData);
                                            } else {
                                                var message = new Message({
                                                    field_id: 'existing_field_update_' + $scope.$id
                                                }, $scope);
            
                                                message.emit('existing_field_update', {
                                                    to: {
                                                        place: "template_editor" //-- example of message id 2365.template_editor
                                                    },
                                                    value: fieldData
                                                });
                                            }
                                        });
                                    }
                                }
                            }, {
                                title: 'Edit style',
                                events: {
                                    click: function(e) {
                                        GhElementEditor.editStyle(null, $scope.field_model, $scope.elemSrc, $scope.containerId).then(function(fieldData) {
                                            if ($scope.appId) {
                                                saveChanges(fieldData);
                                            } else {
                                                var message = new Message({
                                                    field_id: 'existing_field_update_' + $scope.$id
                                                }, $scope);
            
                                                message.emit('existing_field_update', {
                                                    to: {
                                                        place: "template_editor" //-- example of message id 2365.template_editor
                                                    },
                                                    value: fieldData
                                                });
                                            }
                                        });
                                    }
                                }
                            }, {
                                title: 'Delete element',
                                events: {
                                    click: function(e) {
                                        if ($scope.appId) {
                                            let address = {
                                                app_id: $scope.appId
                                            };
            
                                            PipeService.on('gh_app_get', address, function getAppPipe(event, app) {
                                                PipeService.destroy('gh_app_get', address, getAppPipe);
            
                                                let app_copy = angular.copy(app);
                                                formEditorService.deleteContainerFromView(app_copy.views_list, $scope.containerId);
            
                                                PipeService.emit('gh_app_update', {}, { app: app_copy });
            
                                            }).emit('gh_app_get', {}, address);
                                        } else {
                                            var message = new Message({
                                                container_id: 'container_delete_' + $scope.$id
                                            }, $scope);
            
                                            message.emit('container_delete', {
                                                to: {
                                                    place: "delete"
                                                },
                                                value: $scope.containerId
                                            });
                                        }
                                    }
                                }
                            }]);
                        }
                    }).emit('gh_app_info_get', {}, addressApp);
    
            
    
           



            //---------------------- RENDERING ELEMENT -------------------//
            function rendering() {
                /*
                        var el = angular.element('<span>{{field_model.field_value}}</span>');
                        $element.append($compile(el)($scope));
                */


                GHConstructor.getInstance($scope.field_model.data_type).then(function(data) {
                    
                    GhElementFactory.render($scope, $element).then(function(interpreterStyle) {
                        
                        // Set element 'float' ('left', 'center', ...) style, 'font-size' and 'width' by interpritation
                        $element
                            .attr('data-position', (interpreterStyle.position || '') + (parseInt(interpreterStyle.full_width) ? ' full' : ''))
                            // Set atribute of element type
                            .attr('element-type', $scope.field_model.data_type.toString())
                            .css('font-size', (interpreterStyle.font_size ? interpreterStyle.font_size + 'px' : $element[0].style ? $element[0].style.fontSize : ''));
                            
                    });
                    return data;
                }).then(function(data) {


                    if (data.getTemplate().constructor == 'action') {
                        try {
                            data.extendController($scope) ;
                         }
                         catch (e) {
                            console.log(data.getTemplate().name, ' - Doesnt hase extendController() method');
                         }
                        $element.off('click'); 
                        $element.on('click', function(event) {
                            event.stopPropagation();
                            try {
                                data.runAction($scope);
                             }
                             catch (e) {
                                console.log(data.getTemplate().name, ' - Doesnt hase runAction() method');
                             }
                        });
                    }

                });
            }



            //------------------ AUTOMATION ------------------//
            

            function initAutomation(){
                let itemAddress = {
                    app_id: $scope.appId,
                    item_id: $scope.itemId
                };

                let automations = function(model, item){
                    return new Promise((resolve, reject) => {
                        var engine = new Rete.Engine('trigger@0.1.0');
                        console.log("AUTOMATION START");
                
                        //---- Components Initialization ---//
                        Object.keys(Modules).forEach(node =>{
                            let component = new Modules[node]();
                            engine.register(component); //---- Registering components
                        });
                
                        engine.on('error', ({ message, data }) => { console.log(message, data)});
                        engine.process(model, null, [item], resolve);
                    });
                }

                let valuePipeFn = async function valuePipeFn(event, item) {
                    let result = await automations($scope.field_model.data_model.automation.model, item);

                    $scope.field_model.field_value = result;
                    if(result){
                        console.log("AUTOMATION FINISH", result);
                    }
                  };

                  
                //------ START -------//
                // Here we check if We need to start automation
                if ($scope.field_model.data_model.automation.active) {
                    PipeService.on('gh_item_update', itemAddress, valuePipeFn, $scope);
                    console.log("automation", $scope.field_model.data_model.automation.active);
                }

            }




        }]
    };

    return directive;
}])


/*========================================================================================================|
|===============================================  GH-ELEMENT FACTORY   ===================================|
|=========================================================================================================|
*/
.factory('GhElementFactory', ['$q', '$compile', 'cnfg', 'GHConstructor', 'interpritate', 'UtilsService', function($q, $compile, cnfg, GHConstructor, interpritate, UtilsService) {

    function updateView(value) {
        value = value || '';
        /* if cell value is empty then we create a dummy with <span/> tag */
        if (value.indexOf('<') !== 0) {
            value = '<span>' + value + '</span>';
        }
        return value;
    }

    return {

        render: function(scope, element) {

            var deferred = $q.defer();
            //-- Getting interpretation --//

            interpritate.getInterpretation(scope.field_model.field_value, scope.field_model, scope.field_model.data_type, scope.elemSrc || 'form', scope.itemId, scope.appId, scope.containerId).then(function(result) {

                // ----- Set interpritate setting opportunity to field_model ----
                // such as: show_field_name, image size, date format, time format .....
                angular.extend(scope.field_model.settings, result.settings);

                // --------------------------------------------
                element.empty();
                if (scope.field_model.settings.show_field) {
                    // Create field template
                    var template =

                        // Field name
                        (scope.field_model.settings.show_field_name ?
                            '<span class="field-wrap-name"><span class="gh_element_name"><span class="update_check" ng-class="{\'show_update\': update_state}">&nbsp;&nbsp;&nbsp;&nbsp;</span>' +
                            (scope.field_model.data_model ? (scope.field_model.data_model.tooltip ? '<gh-tool-tip model="field_model.data_model"></gh-tool-tip>' : '') : '') +
                            scope.field_model.field_name +
                            '</span></span>' : '') +
                        // Field template
                        updateView(result.html);

                    var el = $compile(angular.element(template))(scope);
                    element.append(el);
                    
                    deferred.resolve(result.style || {});
                }

                if (cnfg.show_watchers) {
                    //element.append(angular.element('<div class="watcher-count">'+ UtilsService.getWatchersCount(elem) +'</div>'));
                }

                deferred.reject();
            });

            return deferred.promise;
        }
    };
}])



/*======================================================================================================|
|===========================================   UTILS SERVICE   =========================================|
|=======================================================================================================|
|-- Service for development testing
*/
.service('UtilsService', [function() {

    //------------------------------ CROWLER ----------------------------------//
    this.crawling = function(object, action) {
        var properties = object === null ? [] : Object.keys(object); /* <--- Array of object properties */
        var self = this;


        /* <--- Array of object properties */
        /* We start crawling in case if object has properties */
        if (properties.length > 0) {
            angular.forEach(properties, function(prop) {
                var propertyValue = object[prop];

                /*  We won't go deeper into object if it's a string
                because string will be defined by 'Object.keys' as an arrey of letters
                */
                if (angular.isDefined(propertyValue) && typeof object != 'string') {

                    action(prop, propertyValue, object); /* <--- Action sends as argument to crawler */
                    self.crawling(propertyValue, action);
                }
            });
        }

        // object === null ?  properties =  object: properties = Object.keys(object);
        // properties = Object.keys(object);

    };

    /*----------------------------  GETTING COUNT OF WATHERS  -----------------------------*/
    // -- return count (number) of watchers with children watchers --
    this.getWatchersCount = function(element) {
        element = angular.element(element);
        var watcherCount = 0;

        function getElemWatchers(element) {
            var isolateWatchers = getWatchersFromScope(element.data().$isolateScope);
            var scopeWatchers = getWatchersFromScope(element.data().$scope);
            var watchers = (+scopeWatchers) + (+isolateWatchers);
            angular.forEach(element.children(), function(childElement) {
                watchers += getElemWatchers(angular.element(childElement));
            });
            return watchers;
        }

        function getWatchersFromScope(scope) {
            if (scope) {
                return scope.$$watchersCount;
            } else {
                return 0;
            }
        }

        return getElemWatchers(element);
    };

    this.mergeInterpretations = function(dst, src, byProperty) {
        var self = this;
        dst.forEach((interpr) => {
            src.forEach((srcInterpr) => {
                if (interpr[byProperty] == srcInterpr[byProperty]) {
                    for (var key in interpr) {
                        if (interpr.hasOwnProperty(key) && srcInterpr.hasOwnProperty(key)) {
                            if (key == 'settings') {
                                self.mergeInterpretationSettings(interpr.settings, srcInterpr.settings);
                            } else {
                                interpr[key] = srcInterpr[key];
                            }
                        }
                    }
                }
            });

        });

    };

    this.mergeInterpretationSettings = function(dst, src) {
        for (var key in src) {
            dst[key] = src[key];
        }
    };

    return this;
}]);