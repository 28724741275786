import IconGenerator from './icons_generator';
import GradientsGenerator from './gradient_list';


/****************************************************************************************************/
/**********************************    ICONS GENERATOR    *******************************************/
/****************************************************************************************************/

/*  icon has 5 atrributes totaly*/

/*		[0] - iconId ( name)*/
/*		[1] - iconColor*/
/*		[2] - iconSize ( all icons are square, it could be in persents or pixels e.g '80%' or '120px' )*/
/*		[3] - iconType ( it could be 'app'-is icon with rounded box. For all other values the icon will be rendered without boxes)*/
/*		[4] - gradientStart (color in HEX formar e.g 'ff00ff')*/
/*		[5] - gradientEnd (color in HEX formar e.g '00ff00')*/

/* Example:  gh-icon="phone ffffff 100px app FFB900 FF8800"*/

/****************************************************************************************************/


angular.module('iconsGenerator', [])



/*=======================================================================================================*/
/*==================================     ICONS GENERATOR DIRECTIVE     ==================================*/
/*=======================================================================================================*/
/* this directive renders icon															 				 */

  .directive('ghIcon', ['$compile', 'iconsStorage', function($compile, iconsStorage) {


    return{
      restrict: 'A',
      link: function(scope, elem, attr){

        /*-- These staff is run each time when we change directive's attribute*/
        attr.$observe('ghIcon', function(attrs) {
          var value = attrs.split(" ");
          var iconId = value[0],
            iconColor = value[1],
            iconSize = value[2],
            iconType = value[3],
            gradientStart = value[4],
            gradientEnd = value[5];

          if(!iconId){
            return;
          }

          /*-- Composing an icon*/
          var iconSvg = iconsStorage.getIcon(iconId, iconColor, iconSize, iconType, gradientStart, gradientEnd);

          var icon = angular.element(iconSvg);

          // if(gradientStart && iconType == 'app'){
          //   gradientEnd = gradientEnd || gradientStart;
          //   icon.css({
          //     'border-radius': '25%',
          //     'background': '-moz-linear-gradient(top, #'+ gradientStart +' 0%, #'+ gradientEnd +' 100%)' /* FF3.6-15 */
          //   }).css({
          //     'background': '-webkit-linear-gradient(top, #'+ gradientStart +' 0%, #'+ gradientEnd +' 100%)' /* Chrome10-25,Safari5.1-6 */
          //   }).css({
          //     'background': 'linear-gradient(to bottom, #'+ gradientStart +' 0%, #'+ gradientEnd +' 100%)' /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          //   }).css({
          //     'filter': 'progid:DXImageTransform.Microsoft.gradient( startColorstr=\'#'+ gradientStart +'\', endColorstr=\'#'+ gradientEnd +'\',GradientType=0 )'
          //   });
          // }
          elem.empty();
          elem.prepend(icon);

        });

      }
    };

  }])




  /*============================================================================================================*/
  /*======================================     ICONS STORAGE  SERVICE   ========================================*/
  /*============================================================================================================*/
  /* here we keep all our icons and methods */
  .service('iconsStorage', [ function() {

    /*--------------------------------------- CONSTRUCTING ICON ----------------------------------------------*/
    this.getIcon = function(iconId, iconColor, iconSize, iconType, gradientStart, gradientEnd) {
      return IconGenerator.getIcon(iconId, iconColor, iconSize, iconType, gradientStart, gradientEnd);
    },


      /*----------------------------------- GETTING LIST OF ICONS -----------------------------------*/
      this.getIconsList = function(iconColor) {
        return IconGenerator.getIconsList(iconColor);
      },


      /*----------------------------------- FINDING ICON IN THE ICONSTORAGE -----------------------------------*/
      /* Here we get svg icon according to the 'iconId' (e.g 'phone' 'paint')*/
      this.findIcon = function(iconId, iconColor) {
        return IconGenerator.getIconsList(iconColor).find((icon) => icon.id == iconId);
      },


      /*--------------------------------------------------------  ICONS STORAGE ----------------------------------------------------------*/
      /* here we keep all our icons and get them by id, e.g 'phone', 'paint' etc.*/
      this.iconStorage = function(iconColor) {
        return IconGenerator.getIconsList(iconColor);
      },


      /*-------------------------------------------------------- GRADIENT STORAGE ----------------------------------------------------------*/
      this.gradientsList = function() {
        return GradientsGenerator.getGradientsList();
      },


      /*--------------------------------------------------------  ICONS COLOR STORAGE ----------------------------------------------------------*/
      this.colorsList = function(){
        let colors = ["ffffff", "000000", "ff0000", "00ff00", "0000ff", "ffff00", "00ffff", "ff00ff", "ff2c65", "00cccc"];
        let gradients = this.gradientsList();

        gradients.forEach(gradient => {
          colors.push(gradient.up);
          colors.push(gradient.down);
        });

        function onlyUnique(value, index, self) { 
          return self.indexOf(value) === index;
        }

        return colors.filter(onlyUnique);
      }



  }]);
