require('./contact_us.scss');
angular.module('contactusModule', [])

  .controller('contactusCtrl', ['$scope','$routeParams', '$location', 'sendEmailService', function($scope, $routeParams, $location, sendEmailService) {
		
		$scope.sendFinished = false;
		$scope.errorMail = false;

		$scope.checkValidationEmail = function(){
				var validationPass = true;
				var reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

				//--email checking
				if (!reg.test($scope.email)) {
						validationPass = false;
						$scope.errorMail = true;
				} else {}

				console.log(validationPass)
				return validationPass;

		}
    $scope.sendContactForm = function(){
			console.log($scope, "SCOPE")
		$scope.showLoader = false;
			let content = {
				emails: "info@gudhub.com",
				theme: "Contact us GudHub",
				body: '<!DOCTYPE html>' +
				'<html>' +
				' <head>' +
				'   <title>Contact Us</title>' +
				' </head>' +
				' <body style="color: #000000;">' + 
				"<p>Username: </p>" + $scope.name + 
				"<p>User Mail: </p>" + $scope.email + 
				"<p>Message: </p>" + $scope.message +
				'</body>' +
				'</html>'
			}

				sendEmailService.sendEmail(content).then(function(){
					console.log("EMAIL SEND");
					$scope.sendFinished = true;
				});
		}
  }]);