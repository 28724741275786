require('./app_slider.js');
require('./slider_service.js');
require('./send_invite_service.js');
require('./../../gh_elements/radio_button/radio_button_data.js')


angular.module('applicationsCtrlMod', [
        'pipeModule',
        'ghAppSlider',
        'sliderModule',
        'sendingInviteModule'
    ])


    /*=======================================================================================================*/
    /*==============================   APPLICATIONS MANAGER CONTROLLER   ====================================*/
    /*=======================================================================================================*/
    .controller('ApplicationsCtrl', ['$location', '$scope', 'authService', 'authApi', '$window', 'storage', 'PipeService','$q', 'sliderService', function($location, $scope, authService, authApi, $window, storage, PipeService, $q, sliderService) {
        $scope.changePath = function(path){
            $location.path(path);
        }
        var appUrl = $location.url();

        angular.extend($scope, {
            tabs: [],
            removing_show: false
        });
        var itemPerTab = 0;

        
        $scope.userModel = {
            data_model: {
                photo_size: 128,
                interpetation: [{
                    src: 'table',
                    id: 'photo_full_name',
                    settings: {
                        editable: 0,
                        show_field_name: 0,
                        show_field: 1
                    }
                }]
            },
            data_type: 'user',
            field_value: angular.copy(storage.getUser().user_id)
        };



        $scope.logout = function() {
            authService.getToken().then(function(accessToken) {
                return authApi.logout(accessToken);
            }).then(function() {
                $window.localStorage.clear();
                storage.unsetUser();
                storage.unsetApps();
                $location.path('/login');
            });
        };

        // Delete application
        $scope.deleteApp = function(appId) {
            PipeService.emit('gh_delete_app', {}, { 
                app_id: appId
            });
        };

        $scope.closeDropdown = function() {
            $scope.open_ropdown = false;
        };

        $scope.$evalAsync(function() {
            $scope.removing_show = false;
            $scope.removing_show_pencil = false;
        });

    }])


    /* -- Filter apps list by range --*/
    .filter('limitFromTo', function() {
        return function(apps, from, to) {
            return (apps != undefined) ? apps.slice(from, to) : '';
        };
    });
