require('./gh_element_settings.js');

/**************************************************************************************************************/
/****************************************    GH-ELEMENT EDITOR    *********************************************/
/**************************************************************************************************************/


angular.module('ghElementEditorModule', [
  'mainStorage',
  'oc.lazyLoad',
  'GhDialogModule',
  'config',
  'ghElementSettingsModule'
])


.service('GhElementEditor', ['$q', 'GHConstructor', 'fieldSettingsService', 'GhDialog', function ($q, GHConstructor, fieldSettingsService, GhDialog) {
  var self = this;

  function showEditDialog(fieldData, fieldModel, editSettingType = 'general_setting', initCb = function () {}) {
    let deferred = $q.defer();
    GhDialog.showSettings({
      position: 'bottom',
      template: getTemplate(),
      locals:{
        fieldModel: fieldModel,
        fieldData: fieldData
      },
      controller: ['$scope', 'fieldModel', 'fieldData', function ($scope, fieldModel, fieldData) {
        
        angular.extend($scope,{
          tabs: self.mergeTabs((fieldData.getSettings($scope)).filter(function (setting) { 
            return setting.type == editSettingType;
          }), editSettingType, fieldData.getTemplate().constructor),
          value: fieldData.getTemplate().model.field_value,
          fieldModel: fieldModel
        });

        initCb($scope);

      }]
    }).then(function (data) {
      deferred.resolve(data);
    }, function () {
      deferred.reject();
    });

    return deferred.promise;
  }

  this.editStyle = function (interpretationModelIndex, fieldModelToEdit, src, containerId) {
    let deferred = $q.defer();
    let fieldModelCopy = angular.copy(fieldModelToEdit);

    //--------- RENDERING FIELDS SETTINGS -------------
    GHConstructor.getInstance(fieldModelCopy.data_type).then(function(data){

      showEditDialog(data, fieldModelCopy, 'interpretation_setting', function ($scope) {
        let index = null;

        if($scope.fieldModel.data_model.interpretation.length == 0){
          $scope.fieldModel.data_model.interpretation.push({
            id: "default",
            settings: {
              editable: 1,
              show_field: 1,
              show_field_name: 1
            },
            src: src
          })
        }

        if(src){
          $scope.fieldModel.data_model.interpretation.forEach((intrp, i) => {
            if(intrp.src == src){ index = i; }
          });

          $scope.fieldModel.data_model.interpretation.forEach((intrp, i) => {
            if(intrp.src == containerId){ index = i; }
          });
        }

        $scope.fieldModel.interpreter = $scope.fieldModel.data_model.interpretation[index || interpretationModelIndex || 0];
      }).then(function (data) {
        deferred.resolve(data);
      }, function () {
        deferred.reject();
      });

    }, function () {
      deferred.reject();
    });

    return deferred.promise;

  };


  this.edit = (fieldModelToEdit, appId, itemId) => {
    let deferred = $q.defer();
    let fieldModelCopy = angular.copy(fieldModelToEdit);

    //--------- RENDERING FIELDS SETTINGS -------------
    GHConstructor.getInstance(fieldModelToEdit.data_type).then((data) => {

      showEditDialog(data, fieldModelCopy, 'general_setting', ($scope) => {

        // -------------------------------- Show interpretation edit window ------------------------------------------
        $scope.appId = appId;
        $scope.itemId = itemId;
        $scope.elementId = fieldModelToEdit.element_id;
        $scope.elementName = data.getTemplate().name;
        $scope.elementIcon = data.getTemplate().icon;

        $scope.editStyle = (interpretationModelIndex, fieldModel = angular.copy(fieldModelCopy)) => {

          this.editStyle(interpretationModelIndex, fieldModel).then(function (data) {
            angular.extend(fieldModelCopy.data_model.interpretation, data.data_model.interpretation);
          });
        };


        $scope.addInterpreter = function () {
          let fieldModel = angular.copy(fieldModelCopy);
          fieldModel.data_model.interpretation.push({
            src: '',
            id: 'default',
            settings:{
              editable: 1, show_field_name: 1, show_field: 1
            }
          });
          $scope.editStyle(fieldModel.data_model.interpretation.length - 1, fieldModel);
        };


        // -----------------------------------------------------------------------------------------------------------
      }).then(function () {
        deferred.resolve(fieldModelCopy);
      }, function () {
        deferred.reject();
      });

    });
    return deferred.promise;
  };

  // -------------------------- Get random unique id for tabs ---------------------------------
  function getUniqueId() {
    return parseInt((new Date()).getMilliseconds() + '' + Math.floor((Math.random() * 10000) + 1));
  }

  // ---------------------- Merge two different tabs of one type ------------------------------
  this.mergeTabs = function(srcTab, settingType, constructorType) {
    return srcTab.reduce(function (tabList, tab) {

      let findedTab = tabList.find(function (tablistTab) {
        return tablistTab.title == tab.title;
      });

      if(findedTab){
        tab.columns_list.forEach(function (colunm, i) {
          findedTab.columns_list[i] = (findedTab.columns_list[i] || []).concat(colunm);
        });
      } else {
        tabList.push(tab);
      }

      return tabList;
    }, 
    fieldSettingsService.getDefaultSettings(settingType).filter(setting => {
      if (constructorType != 'field' && setting.title == 'Automation'){
        return false;
      }else return true;
    }));

  }

  // ------------------------- Storage of edit templates types --------------------------------
  function getTemplate() {
    let uniqueId = getUniqueId();

    return {
      toolbar:
       `<div class="gh-element-type"><div class="gh-element-icon" gh-icon="{{elementIcon}} C0C1C3 32px normal"></div><div class="gh-element-name">{{elementName}}</div></div>
        <div class="gh-tabs-labels">
            <label ng-repeat="tab in tabs" for="tab${uniqueId}{{$index}}" ng-class="{'active': currentTab == $index}" ng-click="$parent.currentTab = $index">
               <div class="icon" gh-icon="{{tab.icon}} C0C1C3 32px normal"></div>
               <div>{{tab.title}}</div>
            </label>
         </div>`,
      content:
        `<div class="gh-tabs gh-field-settings-tabs">
          <div class="gh-tab">
            <div class="gh-tab-container" ng-if="currentTab == $index" ng-repeat="tab in tabs">
              <input type="radio" name="${uniqueId}" id="tab${uniqueId}{{$index}}" ng-checked="$index ? false : true"/>
              <div class="gh-tab-content">
                <div class="option_column" ng-repeat="column in tab.columns_list">
                  <field-setting style="height: 100%" class="flex" ng-repeat="setting in column"></field-setting>
                </div>
              </div>
            </div>
          </div>
        </div>`
    };
  }

  return this;
}])

.filter('trustSVG', ['$sce', function($sce){
  return function(html) {
    return $sce.trustAsHtml(html);
  };
}]);