import Rete from "rete";
import {MainControl} from "../controls/main_control.js"
import {items} from "../controls/sockets.js"
import {ifNoConnections} from "../controls/utilities.js"
import {act} from "../../actions/actions.js"





export class PopulateElement extends Rete.Component {
  constructor(){
      super("Populate Element");
  }


  builder(node) {
      var inpItems = new Rete.Input('inpItems',"Items", items);
      var cntr = new MainControl( this.editor, 'text', false, node, this.getSettings(), this.getTemplate() );

      return node
          .addControl(cntr)
          .addInput(inpItems)  
  }


  async worker(node, inputs, outputs, triggeredData, resolve) {
      let model = node.data.model;
      let app_id = node.data.app_id;
      let keep_whole_data = node.data.keep_whole_data;
      let inputItems = inputs['inpItems'][0];

      //-- Populating items with data
      if(inputItems.length){
        resolve(node.data.output_value);
      }//else resolve(null);
  }

  getTemplate() {
    return {
      name: 'Populate Element',
      icon: 'table',
      constructor: 'node',
      data_model: {
        output_value: null
      }
    }
  }


  getSettings(){
    return [{
            title: 'Options',
            type: 'general_setting',
            icon: 'menu',
            columns_list: [[
              {
                title: 'Settings',
                type: 'header'
              },
              {
                type: 'ghElement',
                property: 'output_value',
                class: 'option-column_500px',
                onInit: function (settingScope) {
                  settingScope.field_model.data_model.app_id = settingScope.appId;
                  settingScope.field_model.data_model.field_id = settingScope.elementId;
                },
                data_model: function () {
                    return {
                        field_name: 'Output Value',
                        name_space: 'output_value',                                                                                    
                        data_type: 'element_ref',
                        data_model: {
                          app_id: '',
                          field_id: '',
                        }
                    };
                }
            }],
            ]
      }]
  }
}