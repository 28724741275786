

/************************************************************************************************************|
|********************************************    GH CONSTRUCTOR    ******************************************|
|************************************************************************************************************|
|  ┌──────────────────────────────────────────────────────────────────────────────────────────────────────┐
|  │                 var constructor = GHConstructor.getInstance(constructor_type);                             │
|  └──────────────────────────────────────────────────────────────────────────────────────────────────────┘
|
|  GHConstructor could create action or field by running appropriate constuctor
|  For this moment GHConstructor four two types of constructors:
|   
|    'field' - This type of element is the most popular, it saves value in field_value (e.g. text, number, date, email, etc)
|    'action' - Action is not saving value it's just doing some actions (e.g. open_item, open_app, delete, etc)
|    'file' - is saving file_id in field_value then it also need to load file from server by this file_id
|    'json' - is saving document_id in field_value then it also need to load document from server by this document_id
|
|   
|    getTemplate: function() {
|     return{ 
|       constructor: 'file', // Could be: 'field', 'action', 'file', 'json'
|       private: false, // if 'private == true' then we are not showing this element in 'Template Editor' on a left bar
|       name: 'Quote tool', // this name is going to be shown in 'Template Editor' bar
|       icon: 'quoters', // we use this icon for 'Template Editor' bar
|       type: 'quote_tool', // it's element identificator
|       model: {...} // here we keep element's settings 
|     }
|  }
|  
|
|************************************************************************************************************|
*/


angular.module('ghConstructor', [])

/*=========================================================================================================*/
/*=================================================  ACTION CONSTRUCTOR   =================================*/
/*=========================================================================================================*/
.factory('GHConstructor', ['$q', '$location', '$injector', 'storage', '$ocLazyLoad', 'cnfg', function($q, $location, $injector, storage, $ocLazyLoad, cnfg) {
  var modules_path = {};
  var cache = [];

  angular.forEach(storage.getModules(), function(module) {
    modules_path[module.name] = cnfg.file_server_url + '/' + module.url;
  });
  
  //*****************  CONSTRUCTOR *******************//
  var constructor = function (module_id) {
    if (!$injector.has(module_id)) {
      return $ocLazyLoad.load(
        [modules_path[module_id]],
        {cache: !cnfg.development_mode}
      ).then(function (load_modules) {
          return putToCache(module_id);
      }, function(error) {
        console.log("ocLazyLoad ERROR", module_id, error);
        return Promise.reject();
      });
    } else {
        return $q.when( getCached(module_id) );
    }
  };

  //***************** GET FROM CACHE *******************//
  function getCached(type) {
    return cache.find(function(cachedItem) {
      return cachedItem.type == type;
    });
  }

  //***************** PUT TO CACHE *******************//
  function putToCache(module_id){
    var instance = {
      type: module_id,

      /*----------------- FIELD TEMPLATE ----------------*/
      getTemplate: function (ref, changeFieldName, displayFieldName, field_model, appId, itemId) {
        var displayFieldNameChecked = displayFieldName === 'false' ? 'false' : 'true';  //-- show/hide field_name
        return $injector.get(module_id).getTemplate(ref, changeFieldName, displayFieldNameChecked, field_model, appId, itemId);
      },


      /*----------------- GET DEFAULT VALUE ----------------*/
      getDefaultValue: function(fieldModel, valuesArray, itemsList) {
          var deferred = $q.defer();
          
          var getValueFunc = $injector.get(module_id).getDefaultValue;
          if (getValueFunc) {
            getValueFunc(fieldModel, valuesArray, itemsList).then(arraOfVal => {
              deferred.resolve(arraOfVal);
            });
          } else {
            deferred.resolve(null);
          }
          return deferred.promise;
      },


      /*----------------- FIELD SETTINGS ----------------*/
      getSettings: function (scope, settingType, fieldModels) {
        return $injector.get(module_id).getSettings(scope, settingType, fieldModels);
      },


      /*----------------- FILTER ----------------*/
      filter: {
        getSearchOptions: function (fieldModel) {
          let d_type = $injector.get(module_id);
          if (d_type.filter && d_type.filter.getSearchOptions) {
            return $injector.get(module_id).filter.getSearchOptions(fieldModel);
          }
          return [];
        },
        getDropdownValues: function () {
          let d_type = $injector.get(module_id);

          if (d_type.filter && d_type.filter.getDropdownValues) {
            return d_type.filter.getDropdownValues();
          } else {
            return [];
          }
        }
      },


      /*----------------- INTERPRETATION ----------------*/
      getInterpretation: function (value, field, dataType, interpretation_id, itemId, appId) {
        var deferred = $q.defer();

        var currentDataType = $injector.get(dataType);

        currentDataType.getInterpretation(value, field, dataType, interpretation_id, itemId, appId).then(function (interpr_arr) {

          var data = interpr_arr.find(function (item) {
            return item.id == interpretation_id;
          }) || interpr_arr.find(function (item) {
            return item.id == 'default';
          });

          deferred.resolve({
            html: data.html
          });

        }, function () {
          deferred.resolve({
            html: '<span>error(from data constructor)</span>',
          });
        });

        return deferred.promise;
      },


      /*----------------- ACTION SCOPE ----------------*/ //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! TO BE REMOVED
      extendController: function (actionScope) {
        return $injector.get(module_id).getActionScope(actionScope);
      },



      /*----------------- WINDOW SCOPE ----------------*/
      getWindowScope: function (windowScope) {
        return $injector.get(module_id).getWindowScope(windowScope);
      },


      /*----------------- WINDOW HTML ----------------*/
      getWindowHTML: function (scope) {
        return $injector.get(module_id).getWindowHTML(scope);
      },


      /*----------------- RUN ACTION ----------------*/
      runAction: function (scope) {
        try {
          $injector.get(module_id).runAction(scope);
        }
        catch(err) {
            // Handle error(s) here
        }

      }

    };

    cache.push(instance);
    return instance;
  }



  return {
    getInstance: constructor
  };
}]);
