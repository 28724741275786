require('../config.js');

angular.module('authorizationMod', [
  'mainStorage',
  'ngCookies',
  'config'
])

.controller('UserSettingsCtrl', [ '$scope', function($scope) {
  $scope.updateAccount = function() {};
}])






/*============================================================================================================*/
/*======================================     AUTHORIZATION SERVICE   =========================================*/
/*============================================================================================================*/
.service('authService', [ '$q', 'authApi', 'cnfg', 'storage', 'usersStorage', function( $q, authApi, cnfg, storage, usersStorage ) {
  var log = cnfg.log.authApi; /*- show/hide console output*/
  var self = this;
  var pageToRedirect = 'home';



  /*--------------------------- SET PAGE FOR REDIRECT ---------------------*/
  /* in case if users need to be redirated to same page after authorization*/
  this.setPageForRedirect = function(path) {
    pageToRedirect = path;
  };



  /*--------------------------- GET USER BY ID ---------------------*/
  this.getUsersList = function(keyword) {
    return this.getToken().then(function(token) {
      return authApi.getUsersList(token, keyword);
    }, function(error) {
      return $q.reject();
    }).then(function(users) {
      return users;
    }, function() {
      return $q.reject();
    });
  };



  /*------------------------------ GET USER BY ID ---------------------*/
  this.getUserById = function( userId ) {
    var deferred = $q.defer();

    //-- Trying to get user's information from storage
    usersStorage.getUserFromStorage( userId ).then( function( userFromStorage ){
        deferred.resolve(userFromStorage);

    //-- If user was not found then we send request to server
    }, function(){
        authApi.getUserByIdApi( userId ).then(function(userData) {

          //-- after we received user's information from server then we save the user to storage
          usersStorage.saveUserToStorage( userData );
          deferred.resolve(userData);
        });
    });

    return deferred.promise;
  };



  /*------------------------------ GET TOKEN ------------------------------*/
  this.getToken = function() {
    var expiryDate = new Date(storage.getUser().expirydate);
    var curentDate = new Date();
    var deferred = $q.defer();

    /*-- checking if token is expiried*/
    if (expiryDate < curentDate) {
      /*-- If token is expiried we send request to server to update it*/
      authApi.updateToken(storage.getUser().auth_key).then(function(result) {
        storage.setUser(result.data);
        deferred.resolve(result.data.accesstoken);
      });
    } else {
      /*-- If token is good then we get it from storage*/
      var tokenFromStorage = storage.getUser().accesstoken;
      deferred.resolve(tokenFromStorage);
    }

    return deferred.promise;
  };



  /* ---------------------------- UPDATE USER ------------------------*/
  this.updateUser = function(userData) {
    var deferred = $q.defer();

    /* Get token*/
    this.getToken().then(function(token) {
      authApi.updateUser(token, userData).then(function(userData) {
        storage.setUser(userData.data);
        deferred.resolve();
      });
    });

    return deferred.promise;
  };



  /* -------------------------- UPDATE AVATAR ----------------------*/
  this.updateAvatar = function (imageData) {
    return this.getToken().then(function(token) {
      return authApi.avatarUploadApi(token, imageData);
    }, function() {
      return $q.reject();
    }).then(function(userData) {
      storage.setUser(userData);
      return $q.resolve();
    }, function() {
      return $q.reject();
    });
  };



  /* ---------------------------- UPDATE USER ------------------------*/
  this.getVersion = function() {
    return authApi.getVersionApi().then(function(version) {
      return version;
    }, function(error){
      return $q.reject(error);
    });
  };
  
}])






/*============================================================================================================*/
/*========================================     AUTHORIZATION API   ===========================================*/
/*============================================================================================================*/
/*-- here we send requests on server for authorization*/

.factory('authApi', [ '$http', '$q', 'cnfg', '$httpParamSerializerJQLike', function($http, $q, cnfg, $httpParamSerializerJQLike) {
  var log = cnfg.log.authApi; /*- show/hide console output*/
  var apiUrl = cnfg.server_url;

  return {

    //--------- LOGIN ------------//
    login: function(user) {
      return $http({
        method: 'POST',
        url: apiUrl + '/auth/login',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: $httpParamSerializerJQLike({
          password: user.password,
          username: user.username
        }),
      }).then(function(result) {
        return result;
      }, function(err) {
        return $q.reject(err);
      });
    },


    //--------- LOGOUT ------------//
    logout: function(accesstoken) {
      return $http({
        method: 'POST',
        url: apiUrl + '/auth/logout',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: $httpParamSerializerJQLike({
          token: accesstoken
        }),
      }).then(function(result) {
        return result;
      });
    },


    //--------- SIGNUP ------------//
    signup: function(user) {
      return $http({
        method: 'POST',
        url: apiUrl + '/auth/singup',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: $httpParamSerializerJQLike({
          user: angular.toJson(user)
        })
      }).then(function(response) {
        return response;
      });
    },


    //--------- GET USERS LIST ------------//
    getUsersList: function(token, keyword) {
      return $http({
        method: 'GET',
        url: apiUrl + '/auth/userlist',
        params: {
          token: token,
          keyword: keyword
        },
        paramSerializer: '$httpParamSerializerJQLike'
      });
    },


    //--------- UPDATE USER ------------//
    updateUser: function(token, userData) {
      return $http({
        method: 'POST',
        url: apiUrl + '/auth/updateuser',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data:  $httpParamSerializerJQLike({
          token: token,
          user: angular.toJson(userData)
        })
      }).then(function(result) {
        return result;
      }, function(err) {
        return $q.reject(err);
      });
    },


    //--------- UPDATE TOKEN ------------//
    updateToken: function(key) {
      return $http({
        method: 'POST',
        url: apiUrl + '/auth/login',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: $httpParamSerializerJQLike({
          auth_key: key
        })
      }).then(function(result) {
        return result;
      }, function(err) {
        return $q.reject(err);
      });
    },


    //--------- AVATAR UPLOAD API ------------//
    avatarUploadApi: function (token, imageData) {
      return $http({
        method: 'POST',
        url: apiUrl + '/auth/avatar-upload',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: $httpParamSerializerJQLike({
          token: token,
          image: imageData
        })
      }).then(function(result) {
        return result.data;
      }, function(err) {
        return $q.reject();
      });
    },


    //--------- GET USER BY ID API ------------//
    getUserByIdApi: function(id) {
      return $http({
        method: 'GET',
        url: apiUrl + '/auth/getuserbyid',
        params: {
          id: id
        },
        paramSerializer: '$httpParamSerializerJQLike'
      }).then(function(result) {
        return result.data;
      }, function(err) {
        return $q.reject();
      });
    },



    //--------- GET VERSION ------------//
    getVersionApi: function() {
      return $http({
        method: 'GET',
        url: apiUrl + '/version',
        paramSerializer: '$httpParamSerializerJQLike'
      }).then(function(result) {
        return result.data;
      }, function(err) {
        return $q.reject();
      });
    }



  };
}])





/*============================================================================================================*/
/*=================================   USERS UPDATE IN MAIN STORAGE   =========================================*/
/*============================================================================================================*/
.factory('usersStorage', [ '$q', 'cnfg', 'storage',  function($q, cnfg, storage ) {

  return {


      /*--------- GETING USER FROM STORAGE --------------*/
    	getUserFromStorage: function( userId ) {
    		var mainStorage = storage.getMainStorage();
    		var deferred = $q.defer();
    		var appWasFound = false;

    		/*-- Looking for user in the main storage --*/
    		angular.forEach( mainStorage.users_list, function(user, key) {
             	if ( user.user_id == userId ){
	             		deferred.resolve( user );
             	}
        });

    		 /*-- If User was not found in the Main Storage*/
    		 if (!appWasFound){
    			 deferred.reject( false );
    		 }

    		return deferred.promise;
    	},



      /*--------- SAVING USER TO STORAGE --------------*/
      saveUserToStorage: function( savedUser ) {
        var mainStorage = storage.getMainStorage();
        var userNotFound = true;

        /*-- Looking for user in the main storage --*/
    		angular.forEach( mainStorage.users_list, function(user, key) {
             	if ( user.user_id == savedUser.user_id )
                  userNotFound = false;
        });

        if( userNotFound )
            mainStorage.users_list.push( savedUser );

        return savedUser;
      }



  };
}]);
