require('./../gui/gh_auth_form.js');
require('../gh_elements/duration/gh_duration.scss');
require('./user_settings.scss');

angular.module('userSettingsMod', [
	'ghAuthFormModule'
])

/*--------------------- USER SETTINGS CONTROLLER --------------------                                */
.controller('userSettingsCtrl', ['$location', '$scope', 'cnfg', '$templateCache', '$routeParams', 'storage', '$mdSidenav', function($location, $scope, cnfg, $templateCache, $routeParams, storage, $mdSidenav) {
	$scope.settings_list = [
		{
			title: 'Account settings',
			name: 'account',
			icon: 'user_round'
		},{
			title: 'Email Address',
			name: 'email',
			icon: 'email'
		},{
			title: 'Change Password',
			name: 'password',
			icon: 'lock'
		}];

		$scope.toggleSidenav = function(){
			$mdSidenav('left').toggle();
		};

		$scope.current_settings = null;

		$scope.selectSetting = function(current_index){
			$scope.current_settings = $scope.settings_list[current_index];
		};

		$scope.selectSetting(0);


}])

/*--------------------- USER PASSWORD CONTROLLER --------------------                                */
.controller('userAccountCtrl', ['$location', '$scope', 'cnfg', '$templateCache', '$routeParams', 'storage', 'userSettingService', '$timeout', function($location, $scope, cnfg, $templateCache, $routeParams, storage, userSettingService, $timeout) {

	var change_avatar = false;

	$scope.storage_user = angular.copy(storage.getMainStorage().user);
console.log($scope.storage_user);
	$scope.account_form = {};

	$scope.avatar_field_model = {
			image_size: 512,
			avatar_size: 180,
			uploadEvents: true
	};

	if($scope.storage_user){
			$scope.account_form = {
					first_name: $scope.storage_user.fullname.split(' ')[0] || '',
					last_name: $scope.storage_user.fullname.split(' ')[1] || ''
			};
	}

	$scope.$watch('storage_user.avatar_512', function(newVal, oldVal){
		if(newVal !== oldVal){
			change_avatar = true;
		}
	});

	$scope.send = function() {
		var data = angular.copy($scope.account_form);
		if ($scope.accountForm.$valid) {

			var dataToSend = {
				user_id: $scope.storage_user.user_id,
				fullname: data.first_name + ' ' + data.last_name,
				username: $scope.storage_user.username
			};

			if(change_avatar){
				dataToSend.avatar = $scope.storage_user.avatar_512.replace(/^data:image\/(gif|jpg|jpeg|tiff|png);base64,/, "");
			}

			userSettingService.update(dataToSend).then(function(data) {

				return data;
			}, function(err) {
				return err;
			}).then(function(msg) {
				$scope.errMsg = msg;
				$timeout(function() {
					$scope.errMsg = "";
				}, 1500);
			});
		}
	};

	$scope.cancel = function(){
		$location.path('/apps');
	};


}])
/*--------------------- USER EMAIL CONTROLLER --------------------                                */
.controller('userEmailCtrl', ['$location', '$scope', 'cnfg', '$templateCache', '$routeParams', 'storage', 'userSettingService', '$timeout', function($location, $scope, cnfg, $templateCache, $routeParams, storage, userSettingService, $timeout) {

	$scope.storage_user = angular.copy(storage.getMainStorage().user);

	$scope.email_form = {};

	if($scope.storage_user){
			$scope.email_form = {
					email: angular.copy($scope.storage_user.username),
			};
	}

	$scope.send = function() {
		var data = angular.copy($scope.email_form);
		if ($scope.emailForm.$valid) {

			var dataToSend = {
				user_id: $scope.storage_user.user_id,
				username: data.email,
			};

			userSettingService.update(dataToSend).then(function(data) {
				return data;
			}, function(err) {
				return err;
			}).then(function(msg) {
				$scope.errMsg = msg;
				$timeout(function() {
					$scope.errMsg = "";
				}, 1500);
			});
		}
	};

	$scope.cancel = function(){
		$location.path('/apps');
	};


}])

/*--------------------- USER PASSWORD CONTROLLER --------------------                                */
.controller('userPassCtrl', ['$location', '$scope', 'cnfg', '$templateCache', '$routeParams', 'storage', 'userSettingService', '$timeout', function($location, $scope, cnfg, $templateCache, $routeParams, storage, userSettingService, $timeout) {

  $scope.pass_form = {};

  $scope.send = function() {
    var data = angular.copy($scope.pass_form);
    if ($scope.passForm.$valid) {

      var dataToSend = {
				user_id: storage.getMainStorage().user.user_id,
        password: data.password,
        old_password: data.old_password
      };

      userSettingService.update(dataToSend).then(function(data) {
        return data;
      }, function(err) {
        return err;
      }).then(function(msg) {
				$scope.pass_form = {};

        $scope.errMsg = msg;
        $timeout(function() {
          $scope.errMsg = "";
        }, 1500);
      });
    }
  };

	$scope.cancel = function(){
		$location.path('/apps');
	};

}])

.service('userSettingService', [ '$q', 'authService', function($q, authService){
	this.update = function(data){
		var deferred = $q.defer();
		authService.updateUser(data).then(function () {
				 deferred.resolve('Ok!');
		}, function(e) {
				deferred.reject(e.data + ". Please try again...");
		});
		return deferred.promise;
	};

	return this;
}]);
